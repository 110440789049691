import { Link } from "react-router-dom";
import styles from "./breadcrumbs.module.css";
import React from "react";

const PREVIOUS_LOCATIONS = [
  {
    text: "Home",
    href: "/",
  },
  {
    text: "Events",
    href: "/events",
  },
];

export const Breadcrumbs = ({ previousLocations, currentLocation }) => {
  previousLocations = PREVIOUS_LOCATIONS;

  return (
    <div className={styles.breadcrumbContainer}>
      {previousLocations.map((location) => (
        <React.Fragment key={location.text}>
          <Link className={styles.previousLocation} to={location.href}>
            {location.text}
          </Link>
          <span>/</span>
        </React.Fragment>
      ))}
      <Link
        className={styles.currentLocation}
        key={currentLocation.text}
        to="#"
      >
        {currentLocation.text}
      </Link>
    </div>
  );
};
