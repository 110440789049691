import {
  axiosService,
  DISABLE_CASE_CONVERSION,
} from "../../../api/axiosConfig";
import { useQuery } from "react-query";

const getUserVenues = async (userId) => {
  const response = await axiosService.post(
    "venuesofuser/",
    {
      user_id: userId,
    },
    {
      headers: {
        [DISABLE_CASE_CONVERSION]: true,
      },
    }
  );

  return response.data;
};

export const useGetUserVenues = (userId, enabled = true, options = {}) => {
  return useQuery({
    queryKey: "getUserVenues",
    queryFn: () => getUserVenues(userId),
    enabled: enabled,
    onError: (error) => {
      console.log(error);
    },
    ...options,
  });
};
