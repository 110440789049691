export const getModifiedFields = (action) => {
  let modifiedFields = {};

  switch (action) {
    case "PAUSE_TICKET_SALES":
      modifiedFields = {
        isPaused: true,
      };
      break;
    case "RESUME_TICKET_SALES":
      modifiedFields = {
        isPaused: false,
      };
      break;
    case "CANCEL_EVENT":
      modifiedFields = {
        isCancelled: true,
      };
      break;
    case "REINSTATE_EVENT":
      modifiedFields = {
        isCancelled: false,
      };
      break;
    case "ARCHIVE_EVENT":
      modifiedFields = {
        isArchived: true,
      };
      break;
    case "UNARCHIVE_EVENT":
      modifiedFields = {
        isArchived: false,
      };
      break;
    case "PUBLISH_EVENT":
      modifiedFields = {
        isPublished: true,
      };
      break;
    case "UNPUBLISH_EVENT":
      modifiedFields = {
        isPublished: false,
      };
      break;
    default:
      modifiedFields = {};
  }

  return modifiedFields;
};
