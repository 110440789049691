import {
  STRIPE_GATEWAY_FEE_PERCENTAGE,
  STRIPE_GATEWAY_FEE_CONSTANT_INCREMENT_IN_DOLLARS,
  PLATFORM_FEE_CONSTANT_INCREMENT_IN_DOLLARS,
  PLATFORM_FEE_PERCENTAGE,
} from "../../../constants/billing";

export const calculateSubtotal = (cartItems, isEventFree = false) => {
  if (isEventFree) {
    return 0;
  }

  let subtotal = 0;
  cartItems.forEach((item) => (subtotal += item.price * item.quantity));
  return subtotal;
};

export const calculateTaxes = (
  subtotal,
  eventTaxRate = 10,
  isEventFree = false
) => {
  if (isEventFree || subtotal == 0) {
    return 0;
  }

  let taxes = (subtotal * eventTaxRate) / 100;
  return taxes;
};

export const calculateServiceFee = (
  subtotal,
  taxes,
  isEventCustomerAbsorbFee = true,
  isEventFree = false
) => {
  if (!isEventCustomerAbsorbFee || subtotal === 0) {
    return 0;
  }

  console.log("Service fee: ", subtotal);
  console.log("Taxes:", taxes);
  const platformFee =
    (subtotal + taxes) * (PLATFORM_FEE_PERCENTAGE / 100) +
    PLATFORM_FEE_CONSTANT_INCREMENT_IN_DOLLARS;
  const gatewayFee =
    (subtotal + taxes) * (STRIPE_GATEWAY_FEE_PERCENTAGE / 100) +
    STRIPE_GATEWAY_FEE_CONSTANT_INCREMENT_IN_DOLLARS;

  console.log("Platform: ", platformFee);
  console.log("Gateway: ", gatewayFee);
  const serviceFee = platformFee + gatewayFee;
  return serviceFee;
};

export const calculateTotal = (subtotal, taxes, serviceFee) => {
  return subtotal + taxes + serviceFee;
};
