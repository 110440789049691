import {useMutation} from "react-query";
import { axiosService } from "../../../api/axiosConfig";

const updateData = async (data, userId) => {
    const response = await axiosService.patch(`users/${userId}/`, data);
    return response.data;
}

export const useSubmitOrganizerSetupForm = () => {
    return useMutation({
        mutationKey: "submitOrganizerSetupForm",
        mutationFn: ({pageData, userId}) => {
            const onboardingData = {...pageData.pageOne, ...pageData.pageTwo};
            const data = new FormData();
            const FIELDS = ["timezone", "isEmailDisplay", "isPhoneDisplay", "phone", "email"];
            
            for (const field of FIELDS) {
                if (onboardingData.hasOwnProperty(field)) {
                    data.append(field, onboardingData[field]);
                }
            }

            if (onboardingData.file) {
                data.append("logo_image", onboardingData.file);
            }
            
            // only complete onboarding if the user has updated their timezone
            if (data.get("timezone")) {
                data.append("is_on_board", true);
            }

            return updateData(data, userId);
        },
        onError: (error) => {
            console.log(error);
        }
    })
}