export const parseSeatObject = (seatObject) => {
  // The most relevant fields here are label, category and quantity, as that is
  // what the backend uses to idenfity the prices of the seats
  let price = 0;
  let categoryLabel = "";

  if (seatObject?.pricing?.hasOwnProperty("ticketTypes")) {
    // parse pricing and name data when seat has subcategories
    // These are basically parsing the object Seats.io returns
    // I encourage you to experiment to see the possible shapes of the data
    price = seatObject.pricing.ticketTypes.find(
      (pricing) => pricing?.ticketType === seatObject?.selectedTicketType
    )?.price;
    categoryLabel = `${seatObject?.category?.label} (${seatObject?.selectedTicketType})`;
  } else {
    price = seatObject?.pricing?.price;
    categoryLabel = seatObject?.category?.label;
  }

  return {
    id: seatObject?.id,
    price: price,
    label: seatObject?.label,
    objectType: seatObject?.objectType,
    ticketType: seatObject?.selectedTicketType,
    quantity: seatObject?.quantity || 1,
    seatQuantity: seatObject?.seats?.length,
    category:
      seatObject?.computedCategoryId || Number(seatObject?.category?.key),
    categoryLabel: categoryLabel,
    tableNumber:
      Number(seatObject?.labels?.parent) || Number(seatObject?.labels?.own),
    seatNumber: Number(seatObject?.labels?.own),
    holdToken: seatObject?.chart?.holdToken,
  };
};

// This function helps to reconcile the difference between
// seats.io keys (which are created in the TicketPrice.js component)
// and the backend VenueChartCategories and ChartCategoriesPricing models
// since there isn't enough time to redo the TicketPrice.js component properly
export const getCategoryIdsFromName = (data) => {
  if (!data) {
    return {};
  }
  const categoryNameToIdMap = {};
  for (let category of data) {
    categoryNameToIdMap[category?.categoryData?.name] =
      category?.categoryData?.id;
  }

  return categoryNameToIdMap;
};

export const parseSeatDataIntoCartItem = (seatData, onDelete) => {
  return {
    id: seatData.id,
    quantity: seatData?.quantity || 1,
    seatQuantity: seatData?.seatQuantity,
    price: seatData.price,
    objectType: seatData.objectType,
    itemName: `${seatData.categoryLabel}`,
    itemDescription: `${seatData.objectType} ${seatData.id}`,
    holdToken: seatData?.holdToken,
    onDelete: () => onDelete(),
  };
};

// export const processTablesInPurchaseItems = (itemsToPurchase) => {
//   const processedItems = [];

//   for (let item of itemsToPurchase) {
//     if (item?.objectType === "Table") {
//       const individualSeatPrice = item?.price / item?.seatQuantity;
//       for (let i = 0; i < item?.seatQuantity; i++) {
//         const extractedSeat = {
//           ...item,
//           quantity: 1,
//           objectType: "Seat",
//           label: `Table ${item?.label} - Seat ${i + 1}`,
//           price: individualSeatPrice,
//         };
//         delete extractedSeat["seatQuantity"];
//         processedItems.push(extractedSeat);
//       }
//     } else {
//       processedItems.push(item);
//     }
//   }

//   return processedItems;
// };
export const parseSeatItemForPurchase = (cartItem) => {
  return {
    label: cartItem.id,
  };
};

export const formatPricingDataFromCategories = (data) => {
  const formattedPricingData = data?.map((category) => {
    const formattedData = {
      category: category?.categoryData?.id,
    };

    if (category?.hasSubcategories) {
      formattedData.ticketTypes = category?.subcategoriesData?.map(
        (subcategory) => ({
          ticketType: subcategory?.name,
          id: subcategory?.id,
          price: subcategory?.price,
          label: subcategory?.name,
        })
      );
    } else {
      formattedData.price = category?.categoryData?.price;
    }

    return formattedData;
  });
  return formattedPricingData;
};
