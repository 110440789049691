import { useFormContext } from "react-hook-form";
import { DEFAULT_TIMEZONE, TIMEZONES } from "../../../constants/timezones";
import { ToggleSwitch } from "../../ToggleSwitch/ToggleSwitch";
import styles from "../organizerSetupWizard.module.css";
import clsx from "clsx";
import { Dropdown } from "../../Dropdown/Dropdown";

export const Page1 = () => {
  const {
    watch,
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <form className={styles.wizardForm}>
      <div className={styles.wizardFormSection}>
        <h3>Time zone</h3>

        <Dropdown
          defaultValue={DEFAULT_TIMEZONE}
          options={TIMEZONES}
          defaultOption="Select time zone"
          register={register("pageOne.timezone")}
        />
      </div>

      <div className={styles.wizardFormSection}>
        <h3>Display contact information</h3>

        <div
          className={clsx(
            styles.wizardFormInputRow,
            errors.pageOne &&
              errors.pageOne.email &&
              watch("pageOne.isEmailDisplay") &&
              styles.wizardFormInputRowError
          )}
        >
          <div className={styles.wizardFormSwitchContainer}>
            <ToggleSwitch register={register("pageOne.isEmailDisplay")} />
            <label htmlFor="email" className={styles.wizardFormInputLabel}>
              Display email
            </label>
          </div>
          <div className={styles.wizardFormInputContainer}>
            <input
              type="email"
              placeholder="Enter an email address"
              className={styles.wizardFormInput}
              disabled={!watch("pageOne.isEmailDisplay")}
              {...register("pageOne.email")}
            />
            {errors.pageOne &&
              errors.pageOne.email &&
              watch("pageOne.isEmailDisplay") && (
                <p className={styles.wizardFormInputError}>
                  {errors.pageOne.email.message}
                </p>
              )}
          </div>
        </div>

        <div
          className={clsx(
            styles.wizardFormInputRow,
            errors.pageOne &&
              errors.pageOne.phone &&
              watch("pageOne.isPhoneDisplay") &&
              styles.wizardFormInputRowError
          )}
        >
          <div className={styles.wizardFormSwitchContainer}>
            <ToggleSwitch register={register("pageOne.isPhoneDisplay")} />
            <label htmlFor="email" className={styles.wizardFormInputLabel}>
              Display phone
            </label>
          </div>
          <div className={styles.wizardFormInputContainer}>
            <input
              type="text"
              placeholder="Enter a valid phone number"
              className={styles.wizardFormInput}
              disabled={!watch("pageOne.isPhoneDisplay")}
              {...register("pageOne.phone")}
            />
            {errors.pageOne &&
              errors.pageOne.phone &&
              watch("pageOne.isPhoneDisplay") && (
                <p className={styles.wizardFormInputError}>
                  {errors.pageOne.phone.message}
                </p>
              )}
          </div>
        </div>
      </div>
    </form>
  );
};
