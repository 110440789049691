import styles from "./errorNotification.module.css";
import { FaTriangleExclamation } from "react-icons/fa6";

export const ErrorNotification = ({
  showIcon = true,
  width = "100%",
  height = "100%",
  text,
  children,
}) => {
  return (
    <div className={styles.container} style={{ width: width, height: height }}>
      {showIcon && <FaTriangleExclamation className={styles.icon} />}
      {children || (
        <>
          <p className={styles.text}>
            {text ||
              "Something went wrong. Please reload the page and try again"}
          </p>
        </>
      )}
    </div>
  );
};
