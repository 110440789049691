'use client'
import SubMenus, { Silder_icon } from '../../components/SubMenus/SubMenus'
import React, { useState, useEffect, useRef} from 'react'
import { CiCirclePlus } from 'react-icons/ci'
import { FaChevronDown, FaFacebookF, FaLinkedin, FaRegCalendarAlt, FaRegUser, FaTwitter } from 'react-icons/fa'
import { FaGear, FaRegMessage } from 'react-icons/fa6'
import {IoCopyOutline, IoSpeedometerOutline } from 'react-icons/io5'
import { RiMoneyDollarCircleLine } from 'react-icons/ri'
import { SlLocationPin } from 'react-icons/sl'
import './index.css'
import EventsCard from '../../components/EventsCard/EventsCard'
import { EventImg, One_img } from '../../public'
import { RootLayout } from "@/layouts/RootLayout/RootLayout";
import { convertTo12HourFormat, formatDate } from '../../utils/dateTimeHelpers'
import { FinishOnboardingButton } from "@/components/FinishOnboardingButton/FinishOnboardingButton";
import { EventCard } from "@/components/EventCard/EventCard";
import { getAuthenticationTokens } from "@/utils/tokenManager";

const Event = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [uniqureUrl, setUniqureUrl] = useState([]);
  const inputRef = useRef(null);

  const { userId } = getAuthenticationTokens();

  const [isMainOpen, setIsMainOpen] = useState(false);
  const [isEventOpen, setIsEventOpen] = useState(false);

  const toggleMain = () => {
    setIsMainOpen(!isMainOpen);
  };

  const toggleEvent = () => {
    setIsEventOpen(!isEventOpen);
  };

  const handleClick = () => {
    console.log("url is ", uniqureUrl);
    window.open(uniqureUrl, "_blank");
  };

  const handleInputClick = (e) => {
    e.stopPropagation();
    handleClick();
  };

  // handle copy to clipboard
  const handleCopyToClipboard = () => {
    const inputValue = inputRef.current.value;
    navigator.clipboard
      .writeText(inputValue)
      .then(() => {
        console.log("Text copied to clipboard:", inputValue);
        // Optionally, you can show a success message to the user
      })
      .catch((error) => {
        console.error("Failed to copy text to clipboard:", error);
        // Optionally, you can show an error message to the user
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const authToken = localStorage.getItem("authToken");

        if (!authToken) {
          console.error("Authentication token not found");
        }
        const authUserId = localStorage.getItem("authUserId");

        if (!authUserId) {
          console.error("Authentication user id   found");
        }

        // const token = 'e0d25a4a3fda989bf969bc5971a9e36878ece9f2';

        // Fetch user data
        const userResponse = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/users/${authUserId}/`,
          {
            headers: {
              Authorization: `Token ${authToken}`,
            },
          }
        );
        if (!userResponse.ok) {
          console.error("Failed to fetch event data");
        }
        const userData = await userResponse.json();
        const unique_token = userData.unique_token;
        const baseUrl = window.location.origin;
        const orgName = userData.business_name.toLowerCase().replace(/ /g, "-");
        const newUrl = `${baseUrl}/events/${orgName}`.toLowerCase();
        setUniqureUrl(newUrl);

        const requestBody = JSON.stringify({
          user_id: authUserId,
        });
        // fetching events details
        const eventResponse = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/eventsofuser/`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: requestBody,
          }
        );
        if (!eventResponse.ok) {
          console.error("Failed to fetch event data");
        }
        const eventData = await eventResponse.json();
        setEvents(eventData.events);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    // Call the fetchData function
    fetchData();
  }, []); // Include eventId in the dependency array to trigger the effect when it changes

  return (
    <RootLayout>
      <div className="main_container">
        <div className="dashboard_main">
          <div className="dashboard_section">
            <div className="sidebar ">
              <ul className="nav-links">
                <Silder_icon />
                <FinishOnboardingButton />
                <li>
                  <a href="/organizer/dashboard">
                    <IoSpeedometerOutline className=" menu_dash_i" />
                    <span className="link_name ">Dashboard</span>
                  </a>
                </li>
                <li>
                  <div className="iocn-link">
                    <a href="/organizer/dashboard/create-an-event-1">
                      <CiCirclePlus className=" menu_dash_i" />

                      <span className="link_name ">CREATE AN EVENT</span>
                    </a>
                  </div>
                </li>
                <li>
                  <SubMenus />
                </li>
                <li>
                  <a href="/organizer/dashboard/venues">
                    <SlLocationPin className=" menu_dash_i" />
                    <span className="link_name">VENUES</span>
                  </a>
                </li>
                <li>
                  <a href="/organizer/dashboard/reports">
                    <RiMoneyDollarCircleLine className=" menu_dash_i" />
                    <span className="link_name">REPORTS</span>
                  </a>
                </li>
                <li>
                  <a href="/organizer/dashboard/settings">
                    <FaGear className=" menu_dash_i" />
                    <span className="link_name">SETTINGS</span>
                  </a>
                </li>
                {/* <li>
                                <div className="iocn-link">
                                    <Link href="/SmsCampaigns">
                                        <FaRegMessage className=" menu_dash_i" />
                                        <span className="link_name">SMS CAMPAIGNS</span>
                                    </Link>
                                </div>
                            </li> */}
                <li>
                  <div className="iocn-link">
                    <a href="/organizer/dashboard/account">
                      <FaRegUser className=" menu_dash_i" />
                      <span className="link_name">MY ACCOUNT</span>
                    </a>
                  </div>
                </li>
              </ul>
            </div>
            <section className="home-section">
              <div className="home-content">
                <div
                  className="events_section "
                  data-aos="fade"
                  data-aos-delay="100"
                >
                  <div className="events_row_1">
                    <div className="para">
                      <p className="para_title">
                        Share a public version of this page.{" "}
                      </p>
                      <p className="para_p">
                        For example, you can add this to your bio link section
                        on your social media, or share on your website to lead
                        customers to your events.
                      </p>
                      <div className="copy_links">
                        <div
                          className="Input_a cursor_pointer"
                          onClick={handleClick}
                        >
                          <input
                            type="text"
                            value={uniqureUrl}
                            ref={inputRef}
                            className="cursor_pointer white_text_grey_BG"
                            onClick={handleInputClick}
                            readOnly
                          />
                        </div>
                        {/* <svg xmlns="http://www.w3.org/2000/svg" className='cursor_pointer' onClick={handleCopyToClipboard} width="21" height="20" viewBox="0 0 21 20" fill="none">
                                                <path d="M17.1667 7.5H9.66667C8.74619 7.5 8 8.24619 8 9.16667V16.6667C8 17.5871 8.74619 18.3333 9.66667 18.3333H17.1667C18.0871 18.3333 18.8333 17.5871 18.8333 16.6667V9.16667C18.8333 8.24619 18.0871 7.5 17.1667 7.5Z" stroke="#FAE100" stroke-width="2" strokeLinecap="round" stroke-linejoin="round" />
                                                <path d="M4.66699 12.5001H3.83366C3.39163 12.5001 2.96771 12.3245 2.65515 12.0119C2.34259 11.6994 2.16699 11.2754 2.16699 10.8334V3.33341C2.16699 2.89139 2.34259 2.46746 2.65515 2.1549C2.96771 1.84234 3.39163 1.66675 3.83366 1.66675H11.3337C11.7757 1.66675 12.1996 1.84234 12.5122 2.1549C12.8247 2.46746 13.0003 2.89139 13.0003 3.33341V4.16675" stroke="#FAE100" stroke-width="2" strokeLinecap="round" stroke-linejoin="round" />
                                            </svg> */}
                        <IoCopyOutline
                          className="share_link_i"
                          onClick={handleCopyToClipboard}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                      <div className="icons">
                        <a href="https://www.facebook.com/profile.php?id=61554004420226">
                          <FaFacebookF
                            className="share_icon_i"
                            target="_blank"
                          />
                        </a>
                        <a href="https://www.instagram.com/eventvisionary/?igshid=MzMyNGUyNmU2YQ%3D%3D">
                          <FaLinkedin
                            className="share_icon_i"
                            target="_blank"
                          />
                        </a>
                        <a
                          href="https://twitter.com/EventVisionary"
                          target="_blank"
                        >
                          <FaTwitter className="share_icon_i" />
                        </a>
                      </div>
                    </div>
                    <div className="new_event_list_wap">
                      <div className="new_event_list">
                        <h4>List a New Event</h4>
                        <a href="/organizer/dashboard/create-an-event-1">
                          {/* <i className='bx bx-plus-circle'  ></i> */}
                          <CiCirclePlus className="new_event_list_i" />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="events_row_2">
                    <div className="event_cards">
                      {events?.map((event) => (
                        <EventCard
                          key={event?.id}
                          eventId={event?.id}
                          mode="ORGANIZER"
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </RootLayout>
  );
};

export default Event
