import { useQuery } from "react-query"
import { axiosService } from "../axiosConfig"

const  fetchUserData = async (userId) => {
    const response = await axiosService.get(`users/${userId}/`);
    return response.data;
}

export const useUserHasOnboarded = (userId) => {
    return useQuery({
        queryKey: ["userHasOnboarded", userId],
        queryFn: () => fetchUserData(userId),
        onError: (error) => {
            console.log(error);
        }
    });
}