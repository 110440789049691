import { useQuery } from "react-query";
import { axiosService, DISABLE_CASE_CONVERSION } from "../../axiosConfig";

const getEventFromId = async (eventId, disableCaseConversion = true) => {
  const response = await axiosService.get(`events/${eventId}/`, {
    headers: {
      [DISABLE_CASE_CONVERSION]: disableCaseConversion,
    },
  });
  return response.data;
};

export const useGetEventFromId = (eventId, enabled = true, options = {}) => {
  return useQuery({
    queryKey: ["getEventFromId", eventId],
    queryFn: () =>
      getEventFromId(eventId, Boolean(options?.disableCaseConversion)),
    onError: (error) => {
      console.log(error);
    },
    enabled: enabled,
    ...options,
  });
};
