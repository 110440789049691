import styles from "./skeletonLoader.module.css";

export const SkeletonLoader = ({
  width = "12rem",
  height = "4rem",
  style = {},
}) => {
  return (
    <div
      className={styles.skeleton}
      style={{ width: width, height: height, ...styles }}
    ></div>
  );
};
