import "../../index.css";
import styles from "./rootLayout.module.css";
import { Footer } from "@/components/Footer/Footer";
import { Header } from "@/components/Header/Header";
import { useDocumentTitle } from "@uidotdev/usehooks";
import { ScrollRestoration } from "react-router-dom";

export const RootLayout = ({ children }) => {
  useDocumentTitle("Event Visionary");
  return (
    <div className="HOC_container">
      <ScrollRestoration />
      <div className="w_effect"></div>
      <Header />
      <main className={styles.main}>{children}</main>
      <Footer />
    </div>
  );
};
