import { useQuery } from "react-query";
import { axiosService } from "../axiosConfig";

const getUserFromId = async (userId) => {
  const response = await axiosService.get(`users/${userId}/`);
  return response.data;
};

export const useGetUserFromId = (userId, enabled = true, options = {}) => {
  return useQuery({
    queryKey: ["getUserFromId", userId],
    queryFn: () => getUserFromId(userId),
    onError: (error) => {
      console.log(error);
    },
    enabled: enabled,
    ...options,
  });
};
