export const generateTicketName = (categoryName, subcategoryName = "") => {
  if (subcategoryName !== "") {
    return `${categoryName} - ${subcategoryName}`;
  }
  return categoryName;
};

export const parseTicketData = (ticketData) => {
  // The most relevant fields here are label, category, ticketType and quantity, as that is
  // what the backend uses to idenfity the prices of the seats/tickets
  return {
    ...ticketData,
    label: ticketData?.id,
    category: ticketData?.category,
    quantity: ticketData?.quantity,
    ticketType: ticketData?.subcategoryName || "",
  };
};
export const parseTicketDataIntoCartItem = (
  ticketData,
  onDelete = () => {}
) => {
  return {
    id: ticketData?.id,
    quantity: ticketData?.quantity,
    price: ticketData?.price,
    itemName: "Ticket",
    itemDescription: ticketData?.id,
    onDelete: () => onDelete(),
    hideDelete: true,
  };
};
