import { RootLayout } from "@/layouts/RootLayout/RootLayout";
import { SkeletonLoader } from "../../components/SkeletonLoader/SkeletonLoader";
import styles from "./singleEvent.module.css";
import { useParams } from "react-router-dom";
import { useGetEventFromToken } from "@/api/hooks/events/useGetEventFromToken";
import { useGetUserFromId } from "@/api/hooks/useGetUserFromId";
import { formatDate, convertTo12HourFormat } from "../../utils/dateTimeHelpers";
import { useGetVenueFromId } from "@/api/hooks/useGetVenueFromId";
import { LuShare2 } from "react-icons/lu";
import { AgeRestrictionBadge } from "./components/AgeRestrictionBadge/AgeRestrictionBadge";
import { Button } from "@/components/Button/Button";
import { useGetEventPriceRange } from "@/api/hooks/events/useGetEventPriceRange";
import { formatCurrency } from "@/utils/formatCurrency";
import { useState } from "react";
import { Breadcrumbs } from "@/components/Breadcrumbs/Breadcrumbs";
import { TicketPurchaseWizardWrapper } from "@/components/TicketPurchaseWizard/TicketPurchaseWizardWrapper";

export const SingleEventPage = () => {
  const {
    organizer: eventOrganizer,
    title: eventTitle,
    date: eventStartDate,
    token: eventToken,
  } = useParams();

  const {
    data: eventData,
    isLoading: isGetEventDataLoading,
    isError: isGetEventDataError,
    isSuccess: isGetEventDataSuccess,
  } = useGetEventFromToken(eventToken, Boolean(eventToken));

  const {
    data: venueData,
    isLoading: isGetVenueDataLoading,
    isError: isGetVenueDataError,
    isSuccess: isGetVenueDataSuccess,
  } = useGetVenueFromId(eventData?.event?.venueName, isGetEventDataSuccess);

  const {
    data: userData,
    isLoading: isGetUserDataLoading,
    isError: isGetUserDataError,
    isSuccess: isGetUserDataSuccess,
  } = useGetUserFromId(eventData?.event?.user, isGetEventDataSuccess);

  const {
    data: priceRange,
    isLoading: isGetPriceRangeLoading,
    isError: isGetPriceRangeError,
    isSuccess: isGetPriceRangeSuccess,
  } = useGetEventPriceRange(eventData?.event?.id, isGetEventDataSuccess);

  const [isTicketPurchaseWizardOpen, setIsTicketPurchaseWizardOpen] =
    useState(false);

  const handleCloseTicketPurchaseWizard = () => {
    setIsTicketPurchaseWizardOpen(false);
  };

  const handleOpenTicketPurchaseWizard = () => {
    setIsTicketPurchaseWizardOpen(true);
  };

  if (isGetEventDataLoading) {
    return (
      <RootLayout>
        <div className={styles.container}>
          <SkeletonLoader width="100%" height="350px" />
          <div className={styles.eventInfoContainer}>
            <SkeletonLoader width="100%" height="auto" />
            <div className={styles.eventActionsContainer}>
              <SkeletonLoader width="100%" height="250px" />
              <SkeletonLoader width="100%" height="100px" />
            </div>
          </div>
        </div>
      </RootLayout>
    );
  }

  return (
    <RootLayout>
      {isTicketPurchaseWizardOpen && (
        <TicketPurchaseWizardWrapper
          onClose={handleCloseTicketPurchaseWizard}
          mode="CUSTOMER"
        />
      )}
      <div className={styles.container}>
        <Breadcrumbs currentLocation={{ text: eventData?.event?.eventName }} />
        <div className={styles.eventImageContainer}>
          {isGetEventDataSuccess ? (
            <img
              className={styles.eventImage}
              src={eventData.event.eventImage}
            />
          ) : (
            <span>The image could not be loaded</span>
          )}
        </div>
        <div className={styles.eventInfoContainer}>
          <div className={styles.eventInfo}>
            <div className={styles.eventInfoText}>
              <h2 className={styles.eventTitle}>
                {eventData?.event?.eventName}
              </h2>
              <div className={styles.eventDateAndTime}>
                <span>{formatDate(eventData?.event?.startDate)} </span>
                <span>•</span>
                <span>
                  {convertTo12HourFormat(eventData?.event?.startTime)}
                </span>
              </div>
              <h3 className={styles.eventSubtitle}>{venueData?.venueName}</h3>
              <p className={styles.eventSubtitleText}>
                <span>{venueData?.venueAddress}</span>
                <span>{venueData?.venueCity}</span>
                <span>{venueData?.venueState}</span>
                <span>{venueData?.venueCountry}</span>
              </p>

              <h3 className={styles.eventSubtitle}>Description</h3>
              <p
                className={styles.eventSubtitleText}
                dangerouslySetInnerHTML={{
                  __html: eventData?.event?.eventDescription,
                }}
              ></p>
            </div>
            <div className={styles.eventOrganizerInfo}>
              <span className={styles.eventOrganizerLabel}>Organized by</span>
              <h2 className={styles.eventOrganizerName}>
                {userData?.businessName}
              </h2>
              {userData?.isPhoneDisplay && <span>{userData?.phone}</span>}
              {userData?.isEmailDisplay && <span>{userData?.email}</span>}
              <img
                className={styles.eventOrganizerLogoImage}
                src={userData?.logoImage}
              />
            </div>
          </div>
          <div className={styles.eventActionsContainer}>
            <div className={styles.eventAction}>
              <h3 className={styles.eventActionTitle}>Age restriction</h3>
              {eventData?.event?.isEventForAll && (
                <AgeRestrictionBadge restriction="ALL_AGES" />
              )}
              {eventData?.event?.isEvent18 && (
                <AgeRestrictionBadge restriction="18" />
              )}
              {eventData?.event?.isEvent21 && (
                <AgeRestrictionBadge restriction="21" />
              )}

              <div className={styles.eventPriceContainer}>
                {priceRange &&
                  priceRange?.minimumPrice &&
                  priceRange?.maximumPrice && (
                    <>
                      {priceRange.minimumPrice === priceRange.maximumPrice ? (
                        <span>{formatCurrency(priceRange.minimumPrice)}</span>
                      ) : (
                        <>
                          <span>{formatCurrency(priceRange.minimumPrice)}</span>
                          <span>-</span>
                          <span>{formatCurrency(priceRange.maximumPrice)}</span>
                        </>
                      )}
                    </>
                  )}
              </div>
              <Button onClick={handleOpenTicketPurchaseWizard}>
                Get Tickets
              </Button>
            </div>
            <div className={styles.eventAction}>
              <h3 className={styles.eventActionTitle}>Share this event</h3>
              <button className={styles.eventShareButton}>
                <LuShare2 className={styles.shareIcon} />
              </button>
            </div>
          </div>
        </div>
      </div>
    </RootLayout>
  );
};
