import { EventRoutes } from "@/routes/EventRoutes";
import styles from "./header.module.css";
import { Link, NavLink } from "react-router-dom";
import { SiteRoutes } from "@/routes/SiteRoutes";
import logo from "@/public/event_visionary_text_logo.png";
import { DashboardRoutes } from "@/routes/DashboardRoutes";
import { Button } from "@/components/Button/Button";
import { DesktopDropdown } from "./components/DesktopDropdown";
import { MobileMenu } from "./components/MobileMenu";
import { useMediaQuery } from "@uidotdev/usehooks";

const LINKS = [
  {
    href: "https://eventvisionary.com",
    text: "Home",
    isExternal: true,
  },
  {
    href: "https://eventvisionary.com/pricing",
    text: "Pricing",
    isExternal: true,
  },
  {
    href: EventRoutes.VIEW_ALL_EVENTS,
    text: "Events",
  },
];

export const Header = () => {
  const isMobile = useMediaQuery("only screen and (max-width: 990px)");

  return (
    <nav className={styles.navbar}>
      <Link className={styles.logoContainer} to={SiteRoutes.HOME}>
        <img className={styles.logo} src={logo} />
      </Link>

      {!isMobile && (
        <div className={styles.navbarSection}>
          {LINKS.map((link) => (
            <NavLink key={link.text} className={styles.link} to={link.href}>
              {link.text}
            </NavLink>
          ))}

          <Button
            as={Link}
            href={DashboardRoutes.CREATE_EVENT}
            size="lg"
            variant="gradientWithText"
          >
            CREATE EVENT
          </Button>
        </div>
      )}

      {isMobile ? <MobileMenu /> : <DesktopDropdown />}
    </nav>
  );
};
