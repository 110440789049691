'use client'
import MainMenusEx from '../../components/MainMenusEx/MainMenusEx'
import SubMenus, { Silder_icon } from '../../components/SubMenus/SubMenus'
import React, { useState, useRef, useEffect } from 'react'
import { CiCirclePlus } from 'react-icons/ci'
import {FaChevronDown, FaRegCalendarAlt, } from 'react-icons/fa'
import { IoSpeedometerOutline } from 'react-icons/io5'
import './index.css'
import "./index.css"
import SellTicketsCom from '../../components/SellTickets/SellTickets'
import { RootLayout } from "@/layouts/RootLayout/RootLayout";
import { useParams } from 'react-router-dom';
import { TicketPurchaseWizardWrapper } from '@/components/TicketPurchaseWizard/TicketPurchaseWizardWrapper'
import { FinishOnboardingButton } from "@/components/FinishOnboardingButton/FinishOnboardingButton";

const SellTickets = () => {
  const { token } = useParams();
  const [paymentOption, setPaymentOption] = useState("Card"); // 'Card' or 'Cash'
  const prevElement = useRef();
  const [event, setEvent] = useState("");
  const [isMainOpen, setIsMainOpen] = useState(true);
  const [isEventOpen, setIsEventOpen] = useState(true);
  let base_api_url = `${process.env.REACT_APP_BASE_URL}`;

  const toggleMain = () => {
    setIsMainOpen(!isMainOpen);
  };

  const toggleEvent = () => {
    setIsEventOpen(!isEventOpen);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        // base_api_url = "${process.env.REACT_APP_BASE_URL}";

        // const token = 'e0d25a4a3fda989bf969bc5971a9e36878ece9f2';
        const authToken = localStorage.getItem("authToken");

        if (!authToken) {
          console.error("Authentication token not found");
        }

        const requestBody = JSON.stringify({
          unique_token: token,
        });
        // Fetch event data
        console.log("Before event api call and token :", token);
        console.log(
          "url is : ",
          `${process.env.REACT_APP_BASE_URL}/api/eventofuniquetoken/`
        );
        console.log(
          "static url is : ",
          `${base_api_url}/api/eventofuniquetoken/`
        );

        // const eventResponse = await fetch(`${process.env.REACT_APP_BASE_URL}/api/eventofuniquetoken/`, {
        const eventResponse = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/eventofuniquetoken/`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Token ${authToken}`,
            },
            body: requestBody,
          }
        );
        console.log("after api call");
        if (!eventResponse.ok) {
          console.log("inside error api call: ", eventResponse);
          console.error("Failed to fetch event data");
        }
        const eventData = await eventResponse.json();
        console.log("eventData api call", eventData);
        setEvent(eventData.event);
      } catch (error) {
        console.error(error);
      }
    };

    // Call the fetchData function
    if (prevElement.current !== token) {
      fetchData();

      prevElement.current = token;
    }
    console.log("1st token is ", token);
  }, [token]); // Include eventId in the dependency array to trigger the effect when it changes

  return (
    <>
      <RootLayout>
        <div className="main_container">
          <div className="dashboard_main">
            <div className="dashboard_section">
              <div className="sidebar ">
                <ul className="nav-links">
                  <Silder_icon />
                  <FinishOnboardingButton />
                  <li>
                    <a href="/organizer/dashboard">
                      <IoSpeedometerOutline className=" menu_dash_i" />
                      <span className="link_name ">Dashboard</span>
                    </a>
                  </li>
                  <li>
                    <div className="iocn-link">
                      <a href="/organizer/dashboard/create-an-event-1">
                        <CiCirclePlus className=" menu_dash_i" />

                        <span className="link_name ">CREATE AN EVENT</span>
                      </a>
                    </div>
                  </li>
                  <li>
                    <SubMenus />
                  </li>
                  <MainMenusEx />
                </ul>
              </div>
              <section className="home-section">
                <div className="home-content">
                  {/* <SellTicketsCom
                      title="Event Title"
                      eventId={event.id}
                      token={token}
                    /> */}
                  <TicketPurchaseWizardWrapper mode="ORGANIZER" />
                </div>
              </section>
            </div>
          </div>
        </div>
      </RootLayout>
    </>
  );
};

export default SellTickets
