import { useQuery } from "react-query";
import {
  axiosService,
  DISABLE_CASE_CONVERSION,
} from "../../../api/axiosConfig";
const getEventTypes = async () => {
  const response = await axiosService.get("eventType/", {
    headers: {
      [DISABLE_CASE_CONVERSION]: true,
    },
  });
  return response.data;
};

export const useGetEventTypes = (enabled = true, options = {}) => {
  return useQuery({
    queryKey: "getEventTypes",
    queryFn: () => getEventTypes(),
    onError: (error) => {
      console.log(error);
    },
    enabled: enabled,
    ...options,
  });
};
