import { FaTrash } from "react-icons/fa6";
import { formatCurrency } from "@/utils/formatCurrency";
import styles from "../ticketPurchaseWizard.module.css";

/*  Item is an object of the type
{
  quantity: number,
  unitPrice: number,
  itemName: string,
  itemDescription: string,
  onDelete: () => void,
  onAddItem: () => void,
  onSubtractItem: () => void
}

*/
export const CartItem = ({ item, isAddSubtractEnabled = false }) => {
  return (
    <div className={styles.cartItem}>
      <div className={styles.cartItemSection}>
        <span className={styles.cartItemFigure}>&times;{item?.quantity}</span>
        <div className={styles.cartItemText}>
          <span className={styles.cartItemTitle}>{item?.itemName}</span>
          <span className={styles.cartItemDescription}>
            {item?.itemDescription}
          </span>
        </div>
      </div>

      <div className={styles.cartItemSection}>
        <span className={styles.cartItemFigure}>
          {formatCurrency(
            item?.objectType === "Table"
              ? item?.price
              : item?.price * item?.quantity
          )}
        </span>
        {
          <div className={styles.cartItemPriceActionContainer}>
            {!item?.hideDelete && (
              <button
                className={styles.cartItemDeleteButton}
                onClick={() => item?.onDelete()}
              >
                <FaTrash />
              </button>
            )}
            {isAddSubtractEnabled && (
              <div className={styles.cartItemActionButtonContainer}>
                <button
                  className={styles.cartItemActionButton}
                  onClick={item?.onAddItem}
                >
                  +
                </button>
                <button
                  className={styles.cartItemActionButton}
                  onClick={
                    item?.quantity > 1 ? item?.onSubtractItem : item?.onDelete
                  }
                >
                  &minus;
                </button>
              </div>
            )}
          </div>
        }
      </div>
    </div>
  );
};
