import clsx from "clsx";
import styles from "./button.module.css";
import { Link } from "react-router-dom";

export const Button = ({
  variant = "gradient",
  size = "md",
  type = "button",
  style = {},
  onClick,
  children,
  disabled,
  className = "",
  as = "button",
  href,
}) => {
  const Component = as || "button";

  return (
    <Component
      disabled={disabled}
      to={as === Link ? href : "#"}
      className={clsx(
        styles.button,
        variant === "gradient" && styles.gradient,
        variant === "transparent" && styles.transparent,
        variant === "gradientWithText" && styles.gradientWithText,
        size === "md" && styles.md,
        size === "sm" && styles.sm,
        size === "lg" && styles.lg,
        disabled && styles.disabled,
        className
      )}
      type={type || "button"}
      onClick={onClick}
      style={style}
    >
      {children}
    </Component>
  );
};
