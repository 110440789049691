import { SearchPlaceIndexForSuggestionsCommand } from "@aws-sdk/client-location";
import { useQuery } from "react-query";
import { camelizeKeys } from "humps";
import { useAWSLocationClient } from "@/Hooks/useAWSLocationClient";
import {
  AWS_LOCATION_API_KEY,
  AWS_PLACE_INDEX_NAME,
} from "@/constants/environment";

const getAddressSuggestions = async (query, client) => {
  const input = {
    IndexName: AWS_PLACE_INDEX_NAME,
    Text: query,
    Key: AWS_LOCATION_API_KEY,
    MaxResults: 5,
  };
  const command = new SearchPlaceIndexForSuggestionsCommand(input);
  const response = camelizeKeys(await client.send(command));
  return response;
};

export const useGetAddressSuggestions = (
  query,
  enabled = false,
  options = {}
) => {
  const client = useAWSLocationClient();
  return useQuery({
    queryKey: ["getAddressSuggestions", query],
    queryFn: () => getAddressSuggestions(query, client),
    onError: (error) => {
      console.log(error);
    },
    enabled: enabled,
    ...options,
  });
};
