import { useQuery } from "react-query";
import { axiosService, DISABLE_CASE_CONVERSION } from "../../axiosConfig";
const getTimezones = async () => {
  const response = await axiosService.get("timezones/", {
    headers: {
      [DISABLE_CASE_CONVERSION]: true,
    },
  });
  return response.data;
};

export const useGetTimezones = (enabled = true, options = {}) => {
  return useQuery({
    queryKey: "getTimezones",
    queryFn: () => getTimezones(),
    onError: (error) => {
      console.log(error);
    },
    staleTime: Infinity,
    enabled: enabled,
    ...options,
  });
};
