'use client'
// import { EventImg, Logo, Mine_logo,  } from '../../public'
import {  Mine_logo, Logo,EventImg, Render } from '../../public'
// import Image from 'next/image'
import React, {createContext, useContext, useState, useEffect, useMemo , useRef, useCallback   } from 'react'
import { EventContext } from '../../context/EventContext';
import './index.css'
// import Link from 'next/link'
import { FaChevronLeft, FaFacebook, FaFacebookF, FaLinkedin, FaTwitter } from "react-icons/fa";
import { SeatsioSeatingChart, SeatsioEventManager  } from '@seatsio/seatsio-react';
import { RiCloseFill } from "react-icons/ri";
import SellTicketwarp from '../../components/SellTicketwarp/SellTicketwarp';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';

// import CheckoutForm from '../CheckoutForm';
// import '../../../public/Renderer Desktop - Stadium 2.png'
import { IoIosArrowBack } from 'react-icons/io'
import SellTicketPop from '../../components/SellTicketPop/SellTicketPop'
import TicketCounter from '../../components/TicketCounter/TicketCounter'
import { RootLayout } from "@/layouts/RootLayout/RootLayout";
import { useParams } from 'react-router-dom';
import { MainProvider } from './MainContext';

const REACT_APP_STRIPE_PUBLISHABLE_KEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
const stripePromise = loadStripe(REACT_APP_STRIPE_PUBLISHABLE_KEY);

    const SingleEvent = () => {
        

    const inputRef = useRef(null);
    const [uniqueUrl, setUniqueUrl] = useState([]);
    const [pricing, setPricing] = useState([]);
    const [boxOfficeCategories, setBoxOfficeCategories] = useState([]);
    let base_api_url=`${process.env.REACT_APP_BASE_URL}`;
    let base_api_url_images=`${process.env.IMAGES_REACT_APP_BASE_URL}`;
    const prevElement = useRef();
    const prevLabel = useRef(); 
    const [orderId, setOrderId] = useState(''); 
    const [ticketViewUrl, setTicketViewUrl] = useState(''); 
    const [orderPrice, setOrderPrice] = useState('0'); 


    const [clientSecret, setClientSecret] = useState('');
    const [intentId, setIntentId] = useState('');

    


    const [paymentOption, setPaymentOption] = useState('Card'); // 'Card' or 'Cash'
    const [counts, setCounts] = useState({});
    const [isOverlayOpen, setIsOverlayOpen] = useState(false);
    // const { token } = useParams(); 
    const { organizer, title, date, token } = useParams();

    const [holdToken, setHoldToken] = useState('');
    const [mainLevels, setMainLevels] = useState([]);
    const [nrMainLevels, setNrMainLevels] = useState([]);
    const memoizedMainLevels = useMemo(() => mainLevels, [mainLevels]);
    const [event, setEvent] = useState([]);
    const [eventImageUrl, setEventImageUrl] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [venueId, setVenueId] = useState([]);
    const [venue, setVenue] = useState([]);
    const [org, setOrg] = useState([]);
    const [orgLogoUrl, setOrgLogoUrl] = useState([]);
    const [eventPriceRange, setEventPriceRange] = useState([]);
    const [popup14Class, setPopup14Class] = useState('hide_popup');
    const [popup15Class, setPopup15Class] = useState('hide_popup');
    const [popup16Class, setPopup16Class] = useState('hide_popup');
    const [popup17Class, setPopup17Class] = useState('hide_popup');
    // const [popup14Class, setPopup14Classe] = useState('hide_popup');

    const [subTotalCart, setSubTotalCart] = useState(0);
    const [serviceFeeCart, setServiceFeeCart] = useState(0);
    const [P, setP] = useState(0);
    const [G, setG] = useState(0);
    const [taxesCart, setTaxesCart] = useState(0);
    const [totalBillCart, setTotalBillCart] = useState(0);
    const [is_event_free, setIs_event_free] = useState(false);
    const [is_absorb_fee, setIs_absorb_fee] = useState(false);
    const [selectedSeats, setSelectedSeats] = useState([]);
    const [nRSelectedSeats, setNrSelectedSeats] = useState([]);


    //  booking information states
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [address1, setAddress1] = useState('');
    const [address2, setAddress2] = useState('');
    const [email, setEmail] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [postal, setPostal] = useState('');
    const [creditCardNumber, setCreditCardNumber] = useState('');
    const [creditCardExpiry, setCreditCardExpiry] = useState('');
    const [creditCardCVV, setCreditCardCVV] = useState('');

    const stripHtmlTags = (html) => {
        const tempElement = document.createElement('div');
        tempElement.innerHTML = html.replace(/\n/g, '<br>');
        return tempElement.innerText; // or tempElement.textContent
    };

    function convertTo12HourFormat(time24) {
        // Split the input time string into hours, minutes, and seconds
        if(time24){

            let [hours, minutes] = time24.split(':').map(Number);
    
        // Determine the period (AM/PM) and adjust hours for 12-hour format
        let period = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12 || 12; // Convert 0 to 12 for 12-hour format
    
        // Format the hours and minutes to ensure two-digit representation
        let formattedHours = String(hours).padStart(2, '0');
        let formattedMinutes = String(minutes).padStart(2, '0');
    
        // Return the formatted time string
        return `${formattedHours}:${formattedMinutes} ${period}`;
        }
        else{
            return "";
        }
        
    }

    function formatDate (dateTimeString) {
        if (!dateTimeString) {
            return ""; // or handle the undefined case appropriately
        }
        console.log("date is : ", dateTimeString);

        const [year, month, day] = (dateTimeString).split('-').map(Number);
        const date = new Date(year, month - 1, day); // month is zero-based
      
        if (isNaN(date.getTime())) {
          throw new Error("Invalid date format");
        }
        // Define options for the desired format
        const options = {
          weekday: 'short', 
          year: 'numeric',
          month: 'long',
          day: 'numeric'
        };
        // Format the date
        return new Intl.DateTimeFormat('en-US', options).format(date);
      }


    const formatTextForHtml = (text) => {
        // console.log("text is ", text);
        if (text) {
            return text.replace(/\n/g, '<br>');
        }
        return '';
    };
      
    const handleFirstNameChange = (event) => {
        setFirstName(event.target.value);
      };
      
      const handleLastNameChange = (event) => {
        setLastName(event.target.value);
      };
      
      const handleAddress1Change = (event) => {
        setAddress1(event.target.value);
      };
      
      const handleAddress2Change = (event) => {
        setAddress2(event.target.value);
      };
      
      const handleEmailChange = (event) => {
        setEmail(event.target.value);
      };
      const handleCityChange = (event) => {
        setCity(event.target.value);
      };
      
      const handleStateChange = (event) => {
        setState(event.target.value);
      };
      
      const handlePostalChange = (event) => {
        setPostal(event.target.value);
      };
      
      const handleCreditCardNumberChange = (event) => {
        setCreditCardNumber(event.target.value);
      };
      
      const handleCreditCardExpiryChange = (event) => {
        setCreditCardExpiry(event.target.value);
      };
      
      const handleCreditCardCVVChange = (event) => {
        setCreditCardCVV(event.target.value);
      };


    const handleHoldTokenChange = (newToken) => {
        console.log('token is : ', newToken )
        setHoldToken(newToken);
    };

    const openPopup14 = () =>{
        const isEventOver = new Date(event.event_sale_end_date) < new Date();
        const isEventNotStarted = new Date(event.event_sale_start_date) > new Date();

        if (isEventOver){
            alert("Event ticket sales has been closed.  ");
            return
        }
        if (isEventNotStarted){
            alert("Event ticket sales has not been started yet.  ");
            return
        }
        console.log("in popup opening funcitons ");
        getHoldToken();
        setPopup14Class('show_popup');
    } 


    const closePopup14 = () =>{
        console.log("in popup opening funcitons ")
        setPopup14Class('hide_popup');
    };
    const openPopup17 = () =>{
        console.log("in popup opening funcitons ")
        setPopup17Class('show_popup');
    };
    const closePopup17 = () =>{
        console.log("in popup opening funcitons ")
        setPopup17Class('hide_popup');
    };
    let timerInterval;

    const openPopup15 = () =>{
        console.log("at 1")
        
        if (event.is_seating_reserved && selectedSeats <1){
            alert("you have not selected any seat. ")
            return
        }
        if (!event.is_seating_reserved && nRSelectedSeats <1){
            alert("you have not selected any seat. ")
            return
        }
        const targetTime = new Date();
        targetTime.setMinutes(targetTime.getMinutes() + 15);

            // Update the timer every second
        
        timerInterval = setInterval(() => {
            updateTimer(targetTime, timerInterval);
        }, 1000);
        console.log("at 2")

        const createPaymentIntent = async (amount, subAmount) => {
            try {
                var User_Id = ""
                const authToken = localStorage.getItem('authToken');

                if (!authToken) {
                console.error('Authentication token not found');
                User_Id = ""
                }
                else{
                    User_Id = localStorage.getItem('authUserId');
                }

                console.log("at 3")
                // Step 1: Call the createcustomer API to create a new customer
                // const customerResponse = await fetch(`${process.env.REACT_APP_BASE_URL}/api/createcustomer/`, {
                //     method: 'POST',
                //     headers: {
                //         'Content-Type': 'application/json',
                //     },
                //     body: JSON.stringify({
                //         email: 'ali@admin.com', // Replace with actual customer email
                //     }),
                // });

                // if (!customerResponse.ok) {
                //     const errorData = await customerResponse.json();
                //     throw new Error(`Failed to create customer: ${errorData.detail}`);
                // }

                // const customerData = await customerResponse.json();
                // const customerID = customerData['Customer ID : ']; 
                console.log(" org is : ", org)
                const api_body = JSON.stringify({
                    amount: totalBillCart,
                    currency: 'USD',
                    user_id: User_Id,
                    sub_bill: subTotalCart,
                    P:P,
                    G:G,
                    taxes:taxesCart,
                    email:email,
                    serviceFee:serviceFeeCart,
                    event_id: (event.id),
                });
                console.log(" api_body ,", api_body);

                const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/create-payment-intent/`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: api_body,
                });
    
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
    
                const data = await response.json();
                if (data.error) {
                    console.error('Error creating payment intent:', data.error);
                    return;
                }
    
                setClientSecret(data.clientSecret);
                setIntentId(data.intent_id);
            } catch (error) {
                console.error('There was an error!', error);
            }
        };
        
        createPaymentIntent(totalBillCart, subTotalCart);


            setPopup15Class('show_popup');
            getHoldTokenExtended();
            closePopup14();
    } 


    // show timer on final booking information page 
    function updateTimer( targetTime, timerInterval) {
        // Get the current date and time
        const currentTime = new Date();
      
        // Calculate the remaining time
        const remainingTime = targetTime - currentTime;
      
        // Check if the target time has passed
        if (remainingTime <= 0) {
          clearInterval(timerInterval);
          closePopup15();
          return;
        }
      
        // Calculate hours, minutes, and seconds
        const hours = Math.floor(remainingTime / (1000 * 60 * 60));
        const minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);
      
        // Display the remaining time in the div
        document.getElementById('timer').textContent = `${hours}:${minutes}:${seconds}`;
      }
    const closePopup15 = () =>{
        console.log("in popup opening funcitons ");
        clearInterval(timerInterval);
        setPopup15Class('hide_popup');
    };
    const backToPopup14 = () =>{
        console.log("in popup opening funcitons ");
        clearInterval(timerInterval);
        setPopup15Class('hide_popup');
        setPopup14Class('show_popup');
    };
    const openPopup16 = () =>{
        console.log("in popup opening funcitons ");
        setPopup16Class('show_popup');
    } 
    const closePopup16 = () =>{
        console.log("in popup opening funcitons ");
        setPopup16Class('hide_popup');
        setPopup15Class('hide_popup');
        setPopup14Class('hide_popup');
    
    };
  
    const openTicketview = (ticketUrl) =>{
        window.open(ticketUrl, '_blank');
    }


    const getTicketFinalButton = async () => {
        
     // 
        setLoading(true)
        console.log("1")


        let message = ""
        if (event.is_event_free){
            if (
                !firstName ||
                !lastName ||
                !address1 ||
                !email ||
                !city ||
                !state ||
                !postal
              ) {
                message = message+("\n * fill all required fields first");// At least one field is empty
              } 
    
        }
        else{

            if (
                !firstName ||
                !lastName ||
                !address1 ||
                !email ||
                !city ||
                !state ||
                !postal) {
                message = message+("\n * fill all required fields first");// At least one field is empty
              } 
    
        }
           
           // Check if email is in proper format
          // Check if email is in proper format
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            message = message+("\n *Email is not in proper format");
            // return ; // Email is not in proper format
        }

        // Check if credit card number has proper length
        // if ( (!event.is_event_free) && creditCardNumber.toString().length !== 16) {
        //     message = message+("\n *Enter correct and complete card number details");
        //     // return ; // Credit card number does not have proper length
        // }

        // Check if CVV is a three-digit number
        // if ((!event.is_event_free) && creditCardCVV.toString().length !== 3 || isNaN(creditCardCVV)) {
        //     message = message+("\n *CVV is not correct, it should be three digit positive number");
        //     // return ; // CVV is not a three-digit number
        // }

        if (message){
            setLoading(false)
            alert(message);

        }
        else{
            console.log("in popup opening funcitons ");
            // setPopup15Class('hide_popup');
            //pgd
            const response = await bookingAPICall();
            if (response){
                setLoading(false)
                setPopup16Class('show_popup');
                console.log("in first function :", response)
                return response
            }
            else{
                setLoading(false)
                alert("Booking failed please reload the page!")
            }
            
        } 
    }


    const handleTicketViewUrl = async (intentId) => {
        try {
            // Await the result from the async function
            const url = await createViewUrl(intentId);
            setTicketViewUrl(url);
        } catch (error) {
            console.error("Error creating view URL:", error);
        }
    };

    const bookingAPICall = async () =>{
        // const token = 'e0d25a4a3fda989bf969bc5971a9e36878ece9f2';
        const authToken = localStorage.getItem('authToken');

        if (!authToken) {
        console.error('Authentication token not found');
        }
        const authUserId = localStorage.getItem('authUserId');

        if (!authUserId) {
        console.error('Authentication token not found');
        }

        if(event.is_seating_reserved){
            const requestBody = JSON.stringify({
                "first_name": firstName,
                "last_name": lastName,
                "billing_address_line1": address1,
                "billing_address_line2": address2,
                "billing_state": state,
                "billing_city": city,
                "billing_email": email,
                "billing_zip_code": parseInt(postal),
                "user_id": parseInt(authUserId),
                "event_id": parseInt(event.id),
                "selected_seats": selectedSeats,
                "total_bill": totalBillCart,
                "sub_bill": subTotalCart,
                "taxes": taxesCart,
                "service_fees": serviceFeeCart,
                "P": P,
                "G": G,
                "holdtoken": holdToken,
                "intent_id": intentId
        });
        console.log("body is :", requestBody)
    // const eventResponse = await fetch(`${process.env.REACT_APP_BASE_URL}/api/eventofuniquetoken/`, {
        const paymentResponse = await fetch(`${process.env.REACT_APP_BASE_URL}/api/reservebooktickets/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: requestBody
         });
         console.log("after api call");
         if (!paymentResponse.ok) {
             console.log("inside error api call: ", paymentResponse);
            //  alert('Failed to paymentResponse');
             return false
         }
         else{
            
            console.log('succcesful to paymentResponse');
            const paymentResponseData = await paymentResponse.json();
            // const user = paymentResponseData.myUser;
            console.log("paymentResponseData: ", paymentResponseData);
            console.log("user: ", paymentResponseData.user);
            setOrderPrice(parseFloat((paymentResponseData.total_price)/100).toFixed(2));
            setOrderId(paymentResponseData.order);
            handleTicketViewUrl((paymentResponseData.booking_intent));
            return true
        }

        }
        else{
             
            const requestBody = JSON.stringify({
                    "first_name": firstName,
                    "last_name": lastName,
                    "billing_address_line1": address1,
                    "billing_address_line2": address2,
                    "billing_state": state,
                    "billing_city": city,
                    "billing_email": email,
                    "billing_zip_code": parseInt(postal),
                    // "card_number": parseInt(creditCardNumber),
                    // "card_expiry": creditCardExpiry,
                    // "card_cvc": parseInt(creditCardCVV),
                    "user_id": parseInt(authUserId),
                    "event_id": parseInt(event.id),
                    "selected_seats": nRSelectedSeats,
                    "total_bill": totalBillCart,
                    "sub_bill": subTotalCart,
                    "taxes": taxesCart,
                    "service_fees": serviceFeeCart,
                    "P": P,
                    "G": G,
                    "intent_id": intentId
            });
            console.log("body is :", requestBody)
        // const eventResponse = await fetch(`${process.env.REACT_APP_BASE_URL}/api/eventofuniquetoken/`, {
            const paymentResponse = await fetch(`${process.env.REACT_APP_BASE_URL}/api/booktickets/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${authToken}`
            },
            body: requestBody
             });
             console.log("after api call");
             if (!paymentResponse.ok) {
                 console.log("inside error api call: ", paymentResponse);
                 alert('Failed to paymentResponse');
                 return  false
             }
             else{
                
                console.log('succcesful to paymentResponse');
                const paymentResponseData = await paymentResponse.json();
                // const user = paymentResponseData.myUser;
                console.log("paymentResponseData: ", paymentResponseData);
                // console.log("details: ", paymentResponseData.details);
                setOrderId(paymentResponseData?.order);
                setOrderPrice(parseFloat((paymentResponseData.total_price)/100).toFixed(2));
                return true

             }

        }

    }
    
    // useEffect(() => {
    //     if(orderId){
    //         setPopup16Class('show_popup');
    //     }
    // }, [orderId]);

    const handleAddMainLevel = (label,category, sub_category, seat, table, price, full_label ) => {
        // console.log("in add main level , mainLevels are", (this.mainLevels));
        console.log("in add main level , mainLevels are", (mainLevels));
        console.log("in add main level , selectedSeats are", (selectedSeats));
        setMainLevels(prevMainLevels => {
            const newMainLevel = { label, seat, table, price, full_label  }; // Correctly construct the new object
            console.log("Updated mainLevels:", [...prevMainLevels, newMainLevel]);
            return [...prevMainLevels, newMainLevel];
        });
        setSelectedSeats(prevSelectedSeats => {
            const newSelectedSeats = { label, category, sub_category, seat, table, price, full_label };
            console.log("Updated newSelectedSeats:", [...prevSelectedSeats, newSelectedSeats]);
            return [...prevSelectedSeats, newSelectedSeats];
        });
        };

    useEffect(() => {
        console.log("MainLevels after update:", mainLevels);
    }, [mainLevels]);

    const handleRemoveMainLevelByLabel  = (full_label, check_release) => {
        console.log("Removing main level  ::", full_label);
        console.log("mainLevels:", mainLevels);
    
        const isLabelFound = mainLevels.some(level => level.full_label === full_label);
    
        // if (isLabelFound) {
            console.log("inside if statement ");
    
            setMainLevels(prevMainLevels => {
                const updatedMainLevels = prevMainLevels.filter(level => level.full_label !== full_label);
                console.log("Updated mainLevels:", updatedMainLevels);
                return updatedMainLevels;
            });
            setSelectedSeats(prevSelectedSeats => {
                const updatedSelectedSeats = prevSelectedSeats.filter(level => level.full_label !== full_label);
                console.log("Updated updatedSelectedSeats:", updatedSelectedSeats);
                return updatedSelectedSeats;
            });
    
            console.log("After updating mainLevels:", mainLevels);
            console.log("Before calling releaseSeatsObject mainLevels :", mainLevels);
            
            
            if(check_release){
                releaseSeatsObject(full_label);
                prevElement.current = full_label;
            }
        // } else {
        //     console.log("Label not found in mainLevels");
        // }   
    };


    const handleAddNrMainLevel = async (index, status, category, sub_category, price, bookedId) => {
        
        if (event.is_event_free){
            price=0
        }
        console.log("already selected NR seats are ", price);

        // check category quantity
        const authToken = localStorage.getItem('authToken');

        if (!authToken) {
        console.error('Authentication token not found');
        }
        
        console.log("body of category quantity check bookedId : ", bookedId)

        const requestBody = JSON.stringify({
            event_id : event.id,
            category_id: bookedId,
            status_check: status
        });
        console.log("body of category quantity check api is : ", requestBody)
    // const eventResponse = await fetch(`${process.env.REACT_APP_BASE_URL}/api/eventofuniquetoken/`, {
        const checkCategoryQuantityResponse = await fetch(`${process.env.REACT_APP_BASE_URL}/api/getcategoryquantity/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: requestBody
        });
        console.log("after category quantity  api call");
        if (!checkCategoryQuantityResponse.ok) {
            console.log("223344: ", checkCategoryQuantityResponse);
            console.error('Failed to fetch check quantity api');
        }
        const checkCategoryQuantityData = await checkCategoryQuantityResponse.json();
        console.log("eventData api call", checkCategoryQuantityData);
        const quantity = (checkCategoryQuantityData.category_quantity);
        console.error('quantity ', quantity);
        console.error('counts[bookedId] ', counts[bookedId]);
        let requiredQuantity=0
        if(counts[bookedId] !== undefined){
             requiredQuantity = counts[bookedId]+1
        }
        else{
            requiredQuantity= 1
        }
        
        console.error('quantity > requiredQuantity ', (quantity > requiredQuantity ));
        console.error('requiredQuantity ', requiredQuantity);

        let is_seat_available=false  
        if (quantity > requiredQuantity || quantity === requiredQuantity){
            is_seat_available=true
        }
        
        if(!is_seat_available){
            alert("Seats quantity exceeds the available quantity limit. ");

            return
        }
        else{

        const existingRow = nrMainLevels.find(row => row.bookedId === bookedId);
      
        if (existingRow) {
          // If an existing row is found, update its price and quantity
          setNrMainLevels(prevMainLevels => {
            return prevMainLevels.map(row => {
              if (row.category === category && row.sub_category === sub_category) {
                return {
                  ...row,
                  price: row.price + price,
                  quantity: row.quantity + 1 // Assuming there's a quantity property in the row
                };
              }
              return row;
            });
          });

          // Update selectedSeats to reflect the changes
        setNrSelectedSeats(prevSelectedSeats => {
            return prevSelectedSeats.map(seat => {
            if (seat.category === category && seat.sub_category === sub_category) {
                return {
                ...seat,
                price: seat.price + price,
                quantity: seat.quantity + 1 
                // Add any other properties you need to update here
                };
            }
            return seat;
            });
        });

        } else {
            console.log("inside else statement");
          // If no existing row is found, add a new row
          setNrMainLevels(prevMainLevels => {
            const newMainLevel = { status, category, sub_category, price, quantity: 1, bookedId:bookedId }; // Assuming there's a quantity property
            return [...prevMainLevels, newMainLevel];
          });
                // Update selectedSeats to add the new seat
        setNrSelectedSeats(prevSelectedSeats => {
        const newSeat = {status, category, sub_category, price, quantity: 1 , bookedId };
        return [...prevSelectedSeats, newSeat];
            });
        }
        
        setCounts((prevCounts) => ({
            ...prevCounts,
            [bookedId]: (prevCounts[bookedId] || 0) + 1, // Increment count for the specified category id
          }));


        }
        // Update selectedSeats if needed
        // Assuming you need to add selected seats here
      };
      
      const handleRemoveNrMainLevelByLabel = (index, price, bookedId) => {
        console.log("Removing main level ::", bookedId);
        console.log("mainLevels:", nrMainLevels);
    
        // Find the row with the specified bookedId
        const targetRow = nrMainLevels.find(row => row.bookedId === bookedId);
    
        if (targetRow) {
            console.log("Row found:", targetRow);
    
            if (targetRow.quantity > 1) {
                // If quantity is greater than 1, decrement the quantity and update the price
                setNrMainLevels(prevMainLevels => {
                    return prevMainLevels.map(row => {
                        if (row.bookedId === bookedId) {
                            return {
                                ...row,
                                quantity: row.quantity - 1,
                                price: row.price - price // Subtract the price
                            };
                        }
                        return row;
                    });
                });
    
                // Update the corresponding seat in selectedSeats
                setNrSelectedSeats(prevSelectedSeats => {
                    return prevSelectedSeats.map(seat => {
                        if (seat.bookedId === bookedId) {
                            return {
                                ...seat,
                                quantity: seat.quantity - 1,
                                price: seat.price - price // Subtract the price
                            };
                        }
                        return seat;
                    });
                });
                setCounts[index]=0
            } 
            else {
                // If quantity is 1, remove the entire row
                setNrMainLevels(prevMainLevels => prevMainLevels.filter(row => row.bookedId !== bookedId));
    
                // Remove the corresponding seat from selectedSeats
                setNrSelectedSeats(prevSelectedSeats => prevSelectedSeats.filter(seat => seat.bookedId !== bookedId));
            }
            
            setCounts((prevCounts) => ({
                ...prevCounts,
                [bookedId]: Math.max((prevCounts[bookedId] || 0) - 1, 0), // Decrement count, but ensure it doesn't go below 0
              }));
        } 
        else {
            console.log("Row not found with bookedId:", bookedId);
        }
    };
    
    
    const calculateSubtotal = (selectedSeats) => {
        // Logic to calculate subtotal
        if(event.is_event_free){
            // setSubTotalCart(0);
            return 0;
        }
        else{
        let subtotal = 0;
        selectedSeats.forEach(seat => {
          subtotal += seat.price;
        });
        
        // setSubTotalCart(subtotal);
        return parseFloat(subtotal.toFixed(2));

       }
      };
    
      // Function to calculate taxes based on subtotal
      const calculateTaxes = (subtotal) => {
        // Logic to calculate taxes
        console.log("check free event status ", (event.is_event_free));
        if(event.is_event_free){
            // setTaxesCart(0);
            return 0;
        }
        else{
            if (event.is_event_tax_enable){

                console.log("in free event else side tax enable: ", (event.event_tax));
                const taxRate = event.event_tax; // Example tax rate (10%)
                // setTaxesCart(taxRate);
                return parseFloat(((subtotal * taxRate)/100).toFixed(2));
            }
            else{
                console.log("in free event else side tax not  enable: ");

                return 0;
            }
        }
      };
    
      // Function to calculate service fee based on subtotal
      const calculateServiceFee = (subtotal) => {
        // Logic to calculate service fee
        if(event.is_event_free){
            return 0;
        }
        else{
            console.log("in else service fee subtotal \n", (subtotal));
            console.log("in else service fee subTotalCart \n", (subTotalCart));
            console.log("taxesCart \n", (taxesCart));
            
            const temp_P = (((subtotal + taxesCart)*0.0429)+(1.0)).toFixed(2);
            const temp_G = (((subtotal + taxesCart)*0.029)+(0.3)).toFixed(2);
            console.log("temp_P \n", (temp_P));
            console.log("temp_G \n", (temp_G));
            const P = parseFloat(temp_P);
            const G = parseFloat(temp_G);
            
            console.log("P \n", (P));
            console.log("G \n", (G));
            setP(P);
            setG(G);
            if (!event.absorb_fee){
                console.log("if is_absorb_fee serviceFeeRate \n", (P+G));
                return  0;

            }
            else{
                console.log(" else is_absorb_feeP \n", P);
                console.log("else is_absorb_feeG \n", G);
                const serviceFeeRate = parseFloat((P+G).toFixed(2));
                // Example service fee rate (5%)
                // setTotalBillCart((subTotalCart+P+G));
                return serviceFeeRate;
            }
        }
      };
    
      useEffect(() => {
        if(selectedSeats.length>0){
            console.log("selected seats are: ", selectedSeats)

        
        // Calculate subtotal based on selected seats
        const subtotal = calculateSubtotal(selectedSeats);
        setSubTotalCart(subtotal);
    
        // Calculate taxes based on subtotal
        const calculatedTaxes = calculateTaxes(subtotal);
        setTaxesCart(calculatedTaxes);
    
        // Calculate service fee based on subtotal
        const calculatedServiceFee = calculateServiceFee(subtotal);
        setServiceFeeCart(calculatedServiceFee);
    
        // Calculate total
        const calculatedTotal = subtotal + calculatedTaxes + calculatedServiceFee;
        setTotalBillCart(calculatedTotal);
       }
       else{
        prevElement.current=""
        setSubTotalCart(0);
        setTaxesCart(0);
        setServiceFeeCart(0);
        setTotalBillCart(0);
       }

      }, [selectedSeats]);

      useEffect(() => {
        if(nRSelectedSeats.length>0){
            console.log("nRSelectedSeats seats are: ", nRSelectedSeats)

        
        // Calculate subtotal based on selected seats
        const subtotal = calculateSubtotal(nRSelectedSeats);
        setSubTotalCart(subtotal);
    
        // Calculate taxes based on subtotal
        const calculatedTaxes = calculateTaxes(subtotal);
        setTaxesCart(calculatedTaxes);
    
        // Calculate service fee based on subtotal
        const calculatedServiceFee = calculateServiceFee(subtotal);
        setServiceFeeCart(calculatedServiceFee);
    
        // Calculate total
        const calculatedTotal = subtotal + calculatedTaxes + calculatedServiceFee;
        setTotalBillCart(calculatedTotal);
       }
       else{
        prevElement.current=""
        setSubTotalCart(0);
        setTaxesCart(0);
        setServiceFeeCart(0);
        setTotalBillCart(0);
       }

      }, [nRSelectedSeats]);

    const handlePaymentChange = (event) => {
        setPaymentOption(event.target.value);
    };

    const decrement = () => {
        // if (count > 0) {
        //     setCount(count - 1);
        // }
    };

    const increment = () => {
        // setCount(count + 1);
    };

    const openOverlay = () => {
        setIsOverlayOpen(true);
    };

    const closeOverlay = () => {
        setIsOverlayOpen(false);
    };

    // 


    useEffect(() => {
        const fetchData = async () => {
            try {
                const authToken = localStorage.getItem('authToken');

                if (!authToken) {
                console.error('Authentication token not found');
                }

                const requestBody = JSON.stringify({
                    unique_token : token,
                });
                // Fetch event data
                console.log("Before event api call and token :", token);
                console.log("url is : ", (`${process.env.REACT_APP_BASE_URL}/api/eventofuniquetoken/`));
                console.log("static url is : ", (`${base_api_url}/api/eventofuniquetoken/`));

                // const eventResponse = await fetch(`${process.env.REACT_APP_BASE_URL}/api/eventofuniquetoken/`, {
                const eventResponse = await fetch(`${process.env.REACT_APP_BASE_URL}/api/eventofuniquetoken/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: requestBody
                });
                console.log("after api call");
                if (!eventResponse.ok) {
                    console.log("inside error api call: ", eventResponse);
                    console.error('Failed to fetch event data');
                }
                const eventData = await eventResponse.json();
                console.log("eventData api call", eventData);

                const newEvent = eventData.event;
                const unique_token = newEvent.unique_token;
                var baseUrl = window.location.href;
                // const newUrl = `${baseUrl}s/token=${unique_token}`;
                setUniqueUrl(baseUrl);


                // baseUrl = base_api_url_images;
                baseUrl = `${base_api_url}`;
                
                console.log("1 : baseUrl: ", baseUrl);
                setEventImageUrl(eventData.event.Event_image);

                setEvent(eventData.event);
    
                // Extract venueId from event data
                const venueId = newEvent.Venue_name;
    
                // Fetch venue data using the extracted venueId
                const venueResponse = await fetch(`${process.env.REACT_APP_BASE_URL}/api/venues/${venueId}/`, {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });
                if (!venueResponse.ok) {
                    console.error('Failed to fetch venue data');
                }
                const venueData = await venueResponse.json();
                setVenue(venueData);


                 // Extract venueId from event data
                //  const orgId = eventData.user;
    
                 // Fetch venue data using the extracted venueId
                const eventPriceResponse = await fetch(`${process.env.REACT_APP_BASE_URL}/api/eventpricerange/`, {
                    method: 'POST',  // Assuming you are making a POST request
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ event_id: (newEvent.id) })
                 });
                 if (!eventPriceResponse.ok) {
                     console.error('Failed to fetch venue data');
                 }
                 const eventPrice_data = await eventPriceResponse.json();
                 setEventPriceRange(eventPrice_data);


                 // Extract venueId from event data
                 const orgId = newEvent.user;
                 console.log("org id is", orgId);
    
                 // Fetch venue data using the extracted venueId
                 const orgResponse = await fetch(`${process.env.REACT_APP_BASE_URL}/api/users/${orgId}/`, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                 });
                 if (!orgResponse.ok) {

                     console.error('Failed to fetch venue data');
                 }
                 const orgData = await orgResponse.json();
                 console.log("orgData  is", orgData);
                 setOrg(orgData);
                 var orgLogo = orgData.logo_image;
                setOrgLogoUrl(orgLogo);

                
                // fetching pricing 
                const requestBody2 = JSON.stringify({
                    token : (token)
                });
                // fetch event with eventId
                const PricingResponse = await fetch(`${process.env.REACT_APP_BASE_URL}/api/pricingofevent/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: requestBody2
                });
        
                // Check if the request was successful (status code 2xx)
                if (PricingResponse.ok) {
                    const PricingResponseData = await PricingResponse.json(); // Parse the response JSON
                    console.log('event PricingResponseData successfully:', PricingResponseData); 
                    setPricing(PricingResponseData.pricing); 
                    setBoxOfficeCategories(PricingResponseData.boxOfficeCategories); 
                    // Optionally, you can return the response data or perform other actions here
                } else {
                    console.error(`Error: ${PricingResponse.status}`);
                    const pricingResponseBody = await PricingResponse.text();
                    // event.target.submit();
                    console.log('authToken', authToken);
                    console.log('Form submitted with data:', requestBody2);
                    console.error(`Response body: ${pricingResponseBody}`);
                    return;
                }
                // pricing api call ends here

                /////////////////
                // //////////////////
                //////////////////


            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };
    
        // Call the fetchData function
        if (prevElement.current !== token) {
            fetchData();
            prevElement.current = token;
        }
    }, [token]); // Include eventId in the dependency array to trigger the effect when it changes
    

    const releaseSeatsObject = async (label) => {
      console.log("this is release event full label is ");
      try {
        const authToken = localStorage.getItem("authToken");

        if (!authToken) {
          console.error("Authentication token not found");
        }

        const requestBody = JSON.stringify({
          seat_label: label,
          event_key: event.id,
          hold_token: holdToken,
        });
        // Fetch event data
        console.log("check releaseeventseat Before api call : ", holdToken);
        console.log("body is ", requestBody);
        let eventResponse = "";
        // if (prevElement.current !== label) {
        //     prevElement.current = label;

        console.log("this is label ", label);

        eventResponse = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/releaseeventseat/`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Token ${authToken}`,
            },
            body: requestBody,
          }
        );
        // }// if for prevElement check ends here
        // else{
        //     console.log("this is the issue ");
        // }
        console.log("after api call");
        if (!eventResponse.ok) {
          console.log("eventResponse : ", eventResponse);
          console.error("Failed to fetch event data");
        } else {
          const eventData = await eventResponse.json();
          console.log("eventData api call", eventData);
          return true;
        }
      } catch (error) {
        // Handle network errors or other exceptions
        console.error("Error:", error.message);
      }
    };

    const getHoldToken = async () => {
      try {
        const authToken = localStorage.getItem("authToken");

        if (!authToken) {
          console.error("Authentication token not found");
        }

        // Fetch event data
        // Extract venueId from event data
        // Fetch venue data using the extracted venueId
        const tokenResponse = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/seatioholdtoken/`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (!tokenResponse.ok) {
          console.error("Failed to fetch venue data");
        }
        const tokenData = await tokenResponse.json();
        console.log("hold token is   is", tokenData);
        //  console.log("hold token is   is", tokenData.token );

        console.log("token generated and it is, ", tokenData.token);
        setHoldToken(tokenData.token);
      } catch (error) {
        // Handle network errors or other exceptions
        console.error("Error:", error.message);
      }
    };

    const getHoldTokenExtended = async () => {
      try {
        const authToken = localStorage.getItem("authToken");

        if (!authToken) {
          console.error("Authentication token not found");
        }

        // Fetch event data
        // Extract venueId from event data
        // Fetch venue data using the extracted venueId
        const requestBody = JSON.stringify({
          token: holdToken,
        });
        const tokenResponse = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/extendholdtoken/`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: requestBody,
          }
        );
        if (!tokenResponse.ok) {
          console.error("Failed to fetch venue data");
        }
        const tokenData = await tokenResponse.json();
        console.log("hold token is extended  is", tokenData);
        console.log("hold token is   is", tokenData.token);
        //  setHoldToken(tokenData.token);
      } catch (error) {
        // Handle network errors or other exceptions
        console.error("Error:", error.message);
      }
    };
    // handle copy to clipboard
    const handleCopyToClipboard = () => {
      const inputValue = inputRef.current.value;
      navigator.clipboard
        .writeText(inputValue)
        .then(() => {
          console.log("Text copied to clipboard:", inputValue);
          // Optionally, you can show a success message to the user
        })
        .catch((error) => {
          console.error("Failed to copy text to clipboard:", error);
          // Optionally, you can show an error message to the user
        });
    };
    const createViewUrl = async (intent_id) => {
      const newUrl = `${process.env.REACT_APP_BASE_URL}/e-tickets/${intent_id}/`;
      return newUrl;
    };

    return (
      <>
        <RootLayout>
          {loading && (
            <div id="preloader" className="preloader">
              <div className="loader"></div>
            </div>
          )}

          <div className="main_container">
            <div className="single_event_sec">
              <div className="single_event_upper">
                <img
                  src={eventImageUrl}
                  alt="EventImg"
                  className="single_event_img"
                />
              </div>
              <div className="single_event_lower">
                <div className="single_event_info">
                  <div className="single_event_infoo">
                    <div className="single_eventt">
                      <h2>{event.Event_Name}</h2>
                      <p>
                        {formatDate(event.start_date)} •{" "}
                        {convertTo12HourFormat(event.start_time)}
                      </p>
                    </div>
                    <div className="venue_n">
                      <h3>{venue.venue_name}</h3>
                      <p>
                        {venue.venue_address} {venue.venue_city},{" "}
                        {venue.venue_state}, {venue.venue_country}.
                      </p>
                    </div>
                    <div className="Description_event overflow_hidden">
                      <h3>Description</h3>
                      {/* <p>{stripHtmlTags(event.Event_description)}</p> */}
                      <p
                        dangerouslySetInnerHTML={{
                          __html: formatTextForHtml(event.Event_description),
                        }}
                      ></p>
                    </div>
                  </div>

                  <div className="organized_by">
                    <h2>Organized by</h2>
                    <p>{org.business_name} </p>
                    {org.is_phone_display ? <p>{org.phone}</p> : <p></p>}
                    {org.is_email_display ? <p>{org.email}</p> : <p></p>}
                    <img src={orgLogoUrl} alt="" className="img_fake" />
                  </div>
                </div>
                <div className="single_event_CTR">
                  <div className="single_event_age">
                    <p>Age Restriction</p>
                    {event.is_event_18 ? (
                      <p className="plus">18+</p>
                    ) : event.is_event_21 ? (
                      <p className="plus">21+</p>
                    ) : (
                      <p className="plus">All Ages</p>
                    )}
                    {/* <p className="plus">18+</p> */}
                    <p className="after_none">
                      ${eventPriceRange.minimum_price} - $
                      {eventPriceRange.maximum_price}
                    </p>
                    <div className="box after_none">
                      {/* <button className="btn" onClick={handlePopup14}>Get Tickets</button> */}
                      <button className="btn" onClick={openPopup14}>
                        Get Tickets
                      </button>
                    </div>
                    <div id="popup14" className={`overlay_14 ${popup14Class}`}>
                      <div className="get_ticketp">
                        <button className="jclose" onClick={closePopup14}>
                          <RiCloseFill color="#FAE100" className="Xmarks" />
                        </button>

                        {/* ///////////////////////// */}
                        {/* //////////////////////// */}

                        <div className="get_ticket_over">
                          <div className="get_ticket_overi">
                            <h1>{event.Event_Name}</h1>
                            <p className="res_yellow">
                              {event.start_date} at{" "}
                              {convertTo12HourFormat(event.start_time)}
                            </p>
                            <p className="res_p">
                              Select your seat(s) or table(s) below to add it to
                              your cart
                            </p>
                          </div>
                          <div className="res_triangle res_none">
                            {/* <img alt='' src={Render} className="res_triangle res_none" />
                                            <img alt='' src={Render_mobile_only} className="res_triangle pc_none" /> */}
                            {!event.is_seating_reserved ? (
                              <SellTicketwarp
                                eventId={event.id}
                                counts={counts}
                                setCounts={setCounts}
                                handleRemoveNrMainLevelByLabel={
                                  handleRemoveNrMainLevelByLabel
                                }
                                handleAddNrMainLevel={handleAddNrMainLevel}
                                status={"singleEventPage"}
                              />
                            ) : (
                              <SeatsioSeatingChart
                                workspaceKey={
                                  process.env.REACT_APP_SEATS_IO_WORKSPACE_KEY
                                }
                                event={event.event_id}
                                pricing={pricing}
                                unavailableCategories={boxOfficeCategories}
                                showFullScreenButton={true}
                                showSectionPricingOverlay={true}
                                session="manual"
                                holdToken={holdToken}
                                inputDevice="auto"
                                confirmSelectionOnMobile="auto"
                                onObjectSelected={function (object) {
                                  console.log("object is ", object);
                                  console.log("inside select ", mainLevels);
                                  let ticketTypes = "";
                                  if (
                                    object.pricing?.ticketTypes !== undefined
                                  ) {
                                    ticketTypes = object.pricing.ticketTypes;
                                  }
                                  console.log("object is ", object);
                                  let seat_sub_category = "";
                                  let seat_category = "";
                                  let seat_label = "";
                                  let seat_type = "";
                                  let price = 0;
                                  seat_type = object.objectType;
                                  console.log("seat type is ", seat_type);

                                  seat_label = object.label;
                                  const [table, seatNumber] = seat_label
                                    ? seat_label.split("-")
                                    : ["", ""];
                                  seat_category = object?.category?.label;

                                  if (seat_type.includes("Seat")) {
                                    if (ticketTypes) {
                                      seat_sub_category =
                                        object.selectedTicketType;

                                      for (const ticketVar of ticketTypes) {
                                        if (
                                          seat_sub_category.includes(
                                            ticketVar.ticketType
                                          )
                                        ) {
                                          price = ticketVar.price;
                                        }
                                      }
                                    } else {
                                      if (object.pricing?.price !== undefined) {
                                        price = object.pricing.price;
                                      }
                                      // price = object.pricing.price;
                                    }
                                  } else {
                                    price = object.pricing.price;
                                  }
                                  const label = seat_sub_category
                                    ? seat_sub_category
                                    : seat_category;

                                  handleAddMainLevel(
                                    label,
                                    seat_category,
                                    seat_sub_category,
                                    seatNumber,
                                    table,
                                    price,
                                    seat_label
                                  );
                                }}
                                onObjectDeselected={function (object) {
                                  // alert("it is in deselect")
                                  console.log("inside deselect ", mainLevels);
                                  handleRemoveMainLevelByLabel(
                                    object.label,
                                    false
                                  );
                                }}
                                region="na"
                              />
                            )}
                          </div>
                          <div className="promo_ticket res_none_share">
                            <form action="" className="promo_cl res_none">
                              <label htmlFor="promo">Promo Code</label>
                              <div className="promo_ticket_input">
                                <input type="text" />
                                <button type="button">Apply</button>
                              </div>
                            </form>
                            <div className="box">
                              <button
                                className="btn res_none"
                                type="button"
                                onClick={openPopup15}
                              >
                                Get Tickets
                              </button>
                            </div>
                          </div>
                        </div>
                        {/* //////////////////////// */}
                        {/* //////////////////////// */}

                        <div className="get_ticket_cart res_none_share ">
                          {/* <div className="get_ticket_carti"></div> */}
                          <img
                            src={eventImageUrl}
                            alt=""
                            className="get_ticket_carti"
                          />
                          <div className="cart_ticker">
                            <h2>Cart</h2>

                            {event.is_seating_reserved ? (
                              <div className="order_sum_text">
                                {mainLevels.map((mainLev, index) => {
                                  return (
                                    <div
                                      key={index}
                                      className="order_summary_data"
                                    >
                                      <p className="no">x1</p>
                                      <div className="order_tinfo">
                                        <p className="type_name">
                                          Ticket Type Name: {mainLev.label}{" "}
                                        </p>
                                        <p className="type_id">
                                          Seat ID: {mainLev.seat} and Table ID:{" "}
                                          {mainLev.table}
                                        </p>
                                      </div>
                                      <div className="order_pinfo">
                                        ${mainLev.price}
                                      </div>
                                      <div
                                        className="order_trash"
                                        onClick={() =>
                                          handleRemoveMainLevelByLabel(
                                            mainLev.full_label,
                                            true
                                          )
                                        }
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="15"
                                          height="15"
                                          viewBox="0 0 15 15"
                                          fill="none"
                                        >
                                          <path
                                            d="M3.04688 15H11.9531V2.34375H12.6562V1.875H9.375V0H5.625V1.875H2.34375V2.34375H3.04688V15ZM6.09375 0.46875H8.90625V1.875H6.09375V0.46875ZM11.4844 2.34375V14.5312H3.51562V2.34375H11.4844Z"
                                            fill="#FF0000"
                                          />
                                          <path
                                            d="M6.32812 4.6875H5.85938V12.6562H6.32812V4.6875Z"
                                            fill="#FF0000"
                                          />
                                          <path
                                            d="M9.14062 4.6875H8.67188V12.6562H9.14062V4.6875Z"
                                            fill="#FF0000"
                                          />
                                        </svg>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            ) : (
                              <div className="order_sum_text">
                                {nrMainLevels.map((mainLev, index) => {
                                  return (
                                    <div
                                      key={index}
                                      className="order_summary_data"
                                    >
                                      <p className="no">x{mainLev.quantity}</p>
                                      <div className="order_tinfo">
                                        <p className="type_name">
                                          Ticket Type Name:{" "}
                                          {mainLev.status
                                            ? mainLev.status
                                            : mainLev.sub_category}{" "}
                                          {mainLev.category}
                                        </p>
                                      </div>
                                      <div className="order_pinfo">
                                        ${mainLev.price}
                                      </div>
                                      {/* <div className="order_trash" disabled onClick={() => handleRemoveNrMainLevelByLabel(index, mainLev.price, mainLev.bookedId)}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                                                                            <path d="M3.04688 15H11.9531V2.34375H12.6562V1.875H9.375V0H5.625V1.875H2.34375V2.34375H3.04688V15ZM6.09375 0.46875H8.90625V1.875H6.09375V0.46875ZM11.4844 2.34375V14.5312H3.51562V2.34375H11.4844Z" fill="#FF0000" />
                                                                            <path d="M6.32812 4.6875H5.85938V12.6562H6.32812V4.6875Z" fill="#FF0000" />
                                                                            <path d="M9.14062 4.6875H8.67188V12.6562H9.14062V4.6875Z" fill="#FF0000" />
                                                                        </svg>
                                                                    </div> */}
                                    </div>
                                  );
                                })}
                              </div>
                            )}

                            <div className="order_main_list">
                              <div className="order_list_items">
                                <div className="order_list_item">
                                  <p>Subtotal</p>
                                  <p>${subTotalCart}</p>
                                </div>
                                <div className="order_list_item">
                                  <p>Taxes</p>
                                  <p>${taxesCart}</p>
                                </div>
                                <div className="order_list_item">
                                  <p>Service Fees</p>
                                  <p>${serviceFeeCart}</p>
                                </div>
                                <div className="order_list_item">
                                  <p>Total</p>
                                  <p>${totalBillCart}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="single_sticky_bar2 pc_none2">
                        <div className="sticky_inner2">
                          <a href="#popup19">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="40"
                              height="40"
                              viewBox="0 0 40 40"
                              fill="none"
                            >
                              <path
                                d="M19.9266 25.4131C17.8146 25.4131 16.125 27.1027 16.125 29.2147C16.125 31.3267 17.8146 33.0164 19.9266 33.0164C22.0387 33.0164 23.7283 31.3267 23.7283 29.2147C23.7283 27.1555 21.9858 25.4131 19.9266 25.4131ZM19.9266 30.6931C19.1346 30.6931 18.501 30.0595 18.501 29.2675C18.501 28.4755 19.1346 27.8419 19.9266 27.8419C20.7186 27.8419 21.3522 28.4755 21.3522 29.2675C21.3522 30.0067 20.6658 30.6931 19.9266 30.6931Z"
                                fill="#FAE100"
                              />
                              <path
                                d="M9.89441 25.4131C7.78239 25.4131 6.09277 27.1027 6.09277 29.2147C6.09277 31.3267 7.78239 33.0164 9.89441 33.0164C12.0064 33.0164 13.696 31.3267 13.696 29.2147C13.696 27.1555 11.9536 25.4131 9.89441 25.4131ZM9.89441 30.6931C9.1024 30.6931 8.46879 30.0595 8.46879 29.2675C8.46879 28.4755 9.1024 27.8419 9.89441 27.8419C10.6864 27.8419 11.32 28.4755 11.32 29.2675C11.32 30.0067 10.6864 30.6931 9.89441 30.6931Z"
                                fill="#FAE100"
                              />
                              <path
                                d="M31.0679 1.125H28.2167C26.9495 1.125 25.8407 2.07541 25.6823 3.34262L24.8375 9.41467H22.6199V5.56024C22.5671 4.60983 21.8279 3.87062 20.9303 3.87062H17.023C16.8646 3.07862 16.1782 2.49781 15.3862 2.49781H10.7926C9.89496 2.49781 9.10296 3.28982 9.10296 4.29303V5.29624H5.24853C4.35092 5.29624 3.55891 6.03544 3.55891 6.88025V9.46747C3.13651 9.57307 2.8197 9.78427 2.5557 10.1011C2.2389 10.5235 2.0805 11.1043 2.2389 11.6323C2.2389 11.6851 2.2389 11.6851 2.2389 11.7379L5.51253 21.6116C5.72373 22.3508 6.41014 22.8788 7.20214 22.8788H21.7751C23.7287 22.8788 25.4183 21.4004 25.6823 19.4468L27.8999 3.65942C27.8999 3.55382 28.0055 3.50102 28.1111 3.50102H30.9623C31.596 3.50102 32.1768 2.97302 32.1768 2.28661C32.1768 1.6002 31.7016 1.125 31.0679 1.125ZM20.1911 9.41467H17.023V6.24664H20.1911V9.41467ZM11.5318 4.87383H14.6998V5.56024V9.41467H11.5318V6.93305V4.87383ZM5.98773 7.61946H9.15576V9.41467H5.98773V7.61946ZM23.4119 19.0772C23.3063 19.8692 22.6199 20.45 21.8279 20.45H7.67735L4.82612 11.7907H24.4679L23.4119 19.0772Z"
                                fill="#FAE100"
                              />
                            </svg>
                          </a>
                          <div className="price_lable">
                            <label htmlFor="">Total:</label>
                            <p>${totalBillCart}</p>
                          </div>
                          <div className="box">
                            <button className="btn sm" onClick={openPopup15}>
                              Checkout
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div id="popup15" className={`overlay_15 ${popup15Class}`}>
                      <div className="get_ticketp2">
                        <div className="get_ticket_over">
                          <div className="get_ticket_overi res_none">
                            <h1>{event.Event_Name}</h1>
                            <p className="res_yellow">
                              {event.start_date} at{" "}
                              {convertTo12HourFormat(event.start_time)}
                            </p>
                            <p>
                              Time left: <span id="timer"></span>
                            </p>
                          </div>

                          <div className="main_buy_form">
                            <div className="promo_ticket pc_none">
                              <form action="">
                                <label htmlFor="promo">Promo Code</label>
                                <div className="promo_ticket_input">
                                  <input type="text" />
                                  <button type="submit">Apply</button>
                                </div>
                              </form>
                            </div>
                            {/* <form action="" className="buy_ticket"> */}
                            <label htmlFor="" className="yellow_cr">
                              Billing Information
                            </label>
                            <div className="buy_ticket_row">
                              <input
                                type="text"
                                value={firstName}
                                onChange={handleFirstNameChange}
                                placeholder="First Name *"
                              />
                              <input
                                type="text"
                                value={lastName}
                                onChange={handleLastNameChange}
                                placeholder="Last Name *"
                              />
                              <input
                                type="email"
                                value={email}
                                onChange={handleEmailChange}
                                className="long_inp"
                                placeholder="Email *"
                              />
                            </div>

                            <div className="buy_ticket_row">
                              <input
                                type="text"
                                value={address1}
                                onChange={handleAddress1Change}
                                placeholder="Address Line 1 *"
                              />
                              <input
                                type="text"
                                value={address2}
                                onChange={handleAddress2Change}
                                placeholder="Address Line 2"
                              />
                              <input
                                type="text"
                                value={city}
                                onChange={handleCityChange}
                                placeholder="City *"
                                className="long_inp"
                              />
                            </div>
                            <div className="buy_ticket_row">
                              <input
                                type="text"
                                value={state}
                                onChange={handleStateChange}
                                placeholder="State *"
                              />
                              <input
                                type="number"
                                value={postal}
                                onChange={handlePostalChange}
                                placeholder="Postal *"
                              />
                            </div>
                            {!event.is_event_free && (
                              <div className="pay_opt">
                                {/* <p className='yellow_cr'>Payment Options</p>
                                                        <div>
                                                            <input type="radio" id="hue" name="pay_2" value="hue" disabled />
                                                            <label htmlFor="hue">Apple Pay</label>
                                                        </div>

                                                        <div>
                                                            <input type="radio" id="dewe" name="pay_2" value="dewe"  checked/>
                                                            <label htmlFor="dewe">Credit/Debit Card</label>
                                                        </div> */}
                                <div className="card_info2">
                                  {clientSecret && (
                                    <Elements
                                      stripe={stripePromise}
                                      options={{ clientSecret }}
                                    >
                                      <CheckoutForm
                                        getTicketFinalButton={
                                          getTicketFinalButton
                                        }
                                      />
                                    </Elements>
                                  )}
                                  {/* <input type="number" value={creditCardNumber} onChange={handleCreditCardNumberChange} className="card_no" placeholder="Credit/Debit Card Number" />
                                                        <input type="date" value={creditCardExpiry} onChange={handleCreditCardExpiryChange} className="mm" placeholder="Exp" />
                                                        <input type="number" value={creditCardCVV} onChange={handleCreditCardCVVChange}  className="mm" placeholder="CVV" /> */}
                                </div>
                                <p className="card_infoend">
                                  By selecting “Buy Now” I agree to Event
                                  Visionary’s Terms and Conditions
                                </p>
                              </div>
                            )}

                            {/* </form> */}
                          </div>
                          <div className="promo_ticket res_none">
                            <form action="">
                              <label htmlFor="promo">Promo Code</label>
                              <div className="promo_ticket_input">
                                <input type="text" />
                                <button type="submit">Apply</button>
                              </div>
                            </form>
                            <div className="box">
                              <div className="back_form">
                                <div className="back_btns">
                                  <a href="#popup14">
                                    <FaChevronLeft className="back_icon" />
                                  </a>
                                  <button
                                    className="btn"
                                    onClick={backToPopup14}
                                  >
                                    Back
                                  </button>
                                </div>
                                {/* <button className="btn" >Get Tickets</button> */}
                              </div>
                            </div>
                          </div>
                        </div>

                        <button className="jclose" onClick={closePopup15}>
                          <RiCloseFill color="#FAE100" className="Xmarks" />
                        </button>
                        <a className="fclose pc_none" href="#popup14">
                          <IoIosArrowBack className="fclose_i" />
                        </a>
                        <div className="get_ticket_cart">
                          <div className="get_ticket_overi pc_none">
                            <h1>{event.Event_Name}</h1>
                            <p className="res_yellow">
                              {event.start_date} at {event.end_date}
                            </p>
                            <h1>Order Summary</h1>
                            <p className="res_m">Ticket Type Name</p>
                          </div>
                          {/* <div className="get_ticket_carti res_none"></div> */}
                          <img
                            src={eventImageUrl}
                            alt=""
                            className="get_ticket_carti"
                          />

                          <div className="cart_ticker">
                            <h2 className="res_none">Cart</h2>
                            {event.is_seating_reserved ? (
                              <div className="order_sum_text">
                                {mainLevels.map((mainLev, index) => {
                                  return (
                                    <div
                                      key={index}
                                      className="order_summary_data"
                                    >
                                      <p className="no">x1</p>
                                      <div className="order_tinfo">
                                        <p className="type_name">
                                          Ticket Type Name: {mainLev.label}{" "}
                                        </p>
                                        <p className="type_id">
                                          Seat ID: {mainLev.seat} and Table ID:{" "}
                                          {mainLev.table}
                                        </p>
                                      </div>
                                      <div className="order_pinfo">
                                        ${mainLev.price}
                                      </div>
                                      {/* <div className="order_trash" onClick={() => handleRemoveMainLevelByLabel(mainLev.full_label)}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                                                                        <path d="M3.04688 15H11.9531V2.34375H12.6562V1.875H9.375V0H5.625V1.875H2.34375V2.34375H3.04688V15ZM6.09375 0.46875H8.90625V1.875H6.09375V0.46875ZM11.4844 2.34375V14.5312H3.51562V2.34375H11.4844Z" fill="#FF0000" />
                                                                        <path d="M6.32812 4.6875H5.85938V12.6562H6.32812V4.6875Z" fill="#FF0000" />
                                                                        <path d="M9.14062 4.6875H8.67188V12.6562H9.14062V4.6875Z" fill="#FF0000" />
                                                                    </svg>
                                                                </div> */}
                                    </div>
                                  );
                                })}
                              </div>
                            ) : (
                              <div className="order_sum_text">
                                {nrMainLevels.map((mainLev, index) => {
                                  return (
                                    <div
                                      key={index}
                                      className="order_summary_data"
                                    >
                                      <p className="no">x{mainLev.quantity}</p>
                                      <div className="order_tinfo">
                                        <p className="type_name">
                                          Ticket Type Name:{" "}
                                          {mainLev.status
                                            ? mainLev.status
                                            : mainLev.sub_category}{" "}
                                          {mainLev.category}
                                        </p>
                                      </div>
                                      <div className="order_pinfo">
                                        ${mainLev.price}
                                      </div>
                                      {/* <div className="order_trash" onClick={() => handleRemoveNrMainLevelByLabel(mainLev.bookedId)}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                                                                        <path d="M3.04688 15H11.9531V2.34375H12.6562V1.875H9.375V0H5.625V1.875H2.34375V2.34375H3.04688V15ZM6.09375 0.46875H8.90625V1.875H6.09375V0.46875ZM11.4844 2.34375V14.5312H3.51562V2.34375H11.4844Z" fill="#FF0000" />
                                                                        <path d="M6.32812 4.6875H5.85938V12.6562H6.32812V4.6875Z" fill="#FF0000" />
                                                                        <path d="M9.14062 4.6875H8.67188V12.6562H9.14062V4.6875Z" fill="#FF0000" />
                                                                    </svg>
                                                                </div> */}
                                    </div>
                                  );
                                })}
                              </div>
                            )}

                            <div className="order_main_list">
                              <div className="order_list_items">
                                <div className="order_list_item">
                                  <p>Subtotal</p>
                                  <p>${subTotalCart}</p>
                                </div>
                                <div className="order_list_item">
                                  <p>Taxes</p>
                                  <p>${taxesCart}</p>
                                </div>
                                <div className="order_list_item">
                                  <p>Service Fees</p>
                                  <p>${serviceFeeCart}</p>
                                </div>
                                <div className="order_list_item">
                                  <p>Total</p>
                                  <p>${totalBillCart}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="single_sticky_bar2 pc_none2">
                          <div className="sticky_inner2">
                            <a href="./about.html">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="40"
                                height="40"
                                viewBox="0 0 40 40"
                                fill="none"
                              >
                                <path
                                  d="M19.9266 25.4131C17.8146 25.4131 16.125 27.1027 16.125 29.2147C16.125 31.3267 17.8146 33.0164 19.9266 33.0164C22.0387 33.0164 23.7283 31.3267 23.7283 29.2147C23.7283 27.1555 21.9858 25.4131 19.9266 25.4131ZM19.9266 30.6931C19.1346 30.6931 18.501 30.0595 18.501 29.2675C18.501 28.4755 19.1346 27.8419 19.9266 27.8419C20.7186 27.8419 21.3522 28.4755 21.3522 29.2675C21.3522 30.0067 20.6658 30.6931 19.9266 30.6931Z"
                                  fill="#FAE100"
                                />
                                <path
                                  d="M9.89441 25.4131C7.78239 25.4131 6.09277 27.1027 6.09277 29.2147C6.09277 31.3267 7.78239 33.0164 9.89441 33.0164C12.0064 33.0164 13.696 31.3267 13.696 29.2147C13.696 27.1555 11.9536 25.4131 9.89441 25.4131ZM9.89441 30.6931C9.1024 30.6931 8.46879 30.0595 8.46879 29.2675C8.46879 28.4755 9.1024 27.8419 9.89441 27.8419C10.6864 27.8419 11.32 28.4755 11.32 29.2675C11.32 30.0067 10.6864 30.6931 9.89441 30.6931Z"
                                  fill="#FAE100"
                                />
                                <path
                                  d="M31.0679 1.125H28.2167C26.9495 1.125 25.8407 2.07541 25.6823 3.34262L24.8375 9.41467H22.6199V5.56024C22.5671 4.60983 21.8279 3.87062 20.9303 3.87062H17.023C16.8646 3.07862 16.1782 2.49781 15.3862 2.49781H10.7926C9.89496 2.49781 9.10296 3.28982 9.10296 4.29303V5.29624H5.24853C4.35092 5.29624 3.55891 6.03544 3.55891 6.88025V9.46747C3.13651 9.57307 2.8197 9.78427 2.5557 10.1011C2.2389 10.5235 2.0805 11.1043 2.2389 11.6323C2.2389 11.6851 2.2389 11.6851 2.2389 11.7379L5.51253 21.6116C5.72373 22.3508 6.41014 22.8788 7.20214 22.8788H21.7751C23.7287 22.8788 25.4183 21.4004 25.6823 19.4468L27.8999 3.65942C27.8999 3.55382 28.0055 3.50102 28.1111 3.50102H30.9623C31.596 3.50102 32.1768 2.97302 32.1768 2.28661C32.1768 1.6002 31.7016 1.125 31.0679 1.125ZM20.1911 9.41467H17.023V6.24664H20.1911V9.41467ZM11.5318 4.87383H14.6998V5.56024V9.41467H11.5318V6.93305V4.87383ZM5.98773 7.61946H9.15576V9.41467H5.98773V7.61946ZM23.4119 19.0772C23.3063 19.8692 22.6199 20.45 21.8279 20.45H7.67735L4.82612 11.7907H24.4679L23.4119 19.0772Z"
                                  fill="#FAE100"
                                />
                              </svg>
                            </a>
                            <div className="price_lable">
                              <label htmlFor="">Total:</label>
                              <p>${totalBillCart}</p>
                            </div>
                            <div className="box">
                              {/* <a className="btn sm" href="#popup16">Buy Now</a> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div id="popup16" className={`overlay_14 ${popup16Class}`}>
                      <div className="get_ticketp">
                        <div className="get_ticket_over">
                          <button className="jclose" onClick={closePopup16}>
                            <RiCloseFill color="#FAE100" className="Xmarks" />
                          </button>
                          <a className="fclose pc_none" href="#popup15">
                            <IoIosArrowBack className="fclose_i" />
                          </a>

                          <div className="get_ticket_overi res_m">
                            <h1>{event.Event_Name}</h1>
                            <p className="res_yellow">
                              {event.start_date} at{" "}
                              {convertTo12HourFormat(event.start_time)}
                            </p>
                            <h1>Order Confirmation</h1>
                          </div>

                          <div className="main_buy_form">
                            <form action="" className="main_heading_Confirma">
                              <div className="buy_ticket_row">
                                <p>Your order has been placed successfully.</p>
                              </div>

                              <div className="buy_ticket_row">
                                {orderId && <p>Order # {orderId}</p>}
                              </div>
                              <div className="buy_ticket_row">
                                <p>Total Paid: ${orderPrice}</p>
                              </div>
                              <div className="buy_ticket_row res_none_share">
                                <p>
                                  Don’t forget to add this event to your
                                  calendar!
                                </p>
                              </div>
                              <div className="order_con_flex">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="45"
                                  height="46"
                                  viewBox="0 0 45 46"
                                  fill="none"
                                >
                                  <path
                                    d="M41.9062 19.0625C42.9609 18.9922 43.8047 18.1484 43.8047 17.0234V13.1563C43.8047 10.7656 41.9062 8.86719 39.5156 8.86719H5.55469C3.16406 8.86719 1.26562 10.7656 1.26562 13.1563V17.0938C1.26562 18.1484 2.10938 19.0625 3.16406 19.1328C5.13281 19.3438 6.60937 21.0312 6.60937 23.0703C6.60937 25.1094 5.13281 26.7969 3.16406 26.9375C2.10938 27.0078 1.26562 27.9219 1.26562 28.9766V32.8437C1.26562 35.2344 3.16406 37.1328 5.55469 37.1328H39.5156C41.9062 37.1328 43.8047 35.2344 43.8047 32.8437V28.9766C43.8047 27.9219 42.9609 27.0078 41.9062 26.9375C39.9375 26.7266 38.4609 25.0391 38.4609 23C38.4609 20.8906 39.9375 19.2031 41.9062 19.0625ZM40.6406 29.8906V32.8437C40.6406 33.4766 40.1484 33.9687 39.5156 33.9687H22.2891V30.1719C22.2891 29.3281 21.5859 28.5547 20.6719 28.5547C19.7578 28.5547 19.0547 29.2578 19.0547 30.1719V33.9687H5.55469C4.92188 33.9687 4.42969 33.4766 4.42969 32.8437V30.0312C7.52344 29.3281 9.77344 26.5156 9.77344 23.1406C9.77344 19.8359 7.52344 16.9531 4.42969 16.1797V13.1563C4.42969 12.5234 4.92188 12.0312 5.55469 12.0312H19.125V15.8281C19.125 16.6719 19.8281 17.4453 20.7422 17.4453C21.6562 17.4453 22.3594 16.7422 22.3594 15.8281V12.0312H39.5156C40.1484 12.0312 40.6406 12.5234 40.6406 13.1563V16.0391C37.5469 16.8125 35.2969 19.625 35.2969 23C35.2969 26.3047 37.5469 29.1172 40.6406 29.8906Z"
                                    fill="#FAE100"
                                  />
                                  <path
                                    d="M20.7422 19.625C19.8984 19.625 19.125 20.3281 19.125 21.2422V24.8281C19.125 25.6719 19.8281 26.4453 20.7422 26.4453C21.6562 26.4453 22.3594 25.7422 22.3594 24.8281V21.2422C22.2891 20.3281 21.5859 19.625 20.7422 19.625Z"
                                    fill="#FAE100"
                                  />
                                </svg>
                                <p>
                                  Your e-tickets have been emailed to you but
                                  can also be viewed and printed here:
                                </p>
                                <br></br>
                                <p
                                  onClick={() => openTicketview(ticketViewUrl)}
                                  className="cursor_pointer"
                                >
                                  {ticketViewUrl}
                                </p>
                              </div>
                            </form>
                          </div>
                          <div className="share_g res_none_share">
                            <p>Share this event</p>
                            <button
                              type="button"
                              className="bg_transparent_border_none"
                              onClick={openPopup17}
                            >
                              {/* <BsShare className="share_btn" /> */}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="25"
                                height="24"
                                viewBox="0 0 25 24"
                                fill="none"
                              >
                                <path
                                  d="M18.5 8C20.1569 8 21.5 6.65685 21.5 5C21.5 3.34315 20.1569 2 18.5 2C16.8431 2 15.5 3.34315 15.5 5C15.5 6.65685 16.8431 8 18.5 8Z"
                                  stroke="#FAE100"
                                  stroke-width="2"
                                  strokeLinecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M6.5 15C8.15685 15 9.5 13.6569 9.5 12C9.5 10.3431 8.15685 9 6.5 9C4.84315 9 3.5 10.3431 3.5 12C3.5 13.6569 4.84315 15 6.5 15Z"
                                  stroke="#FAE100"
                                  stroke-width="2"
                                  strokeLinecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M18.5 22C20.1569 22 21.5 20.6569 21.5 19C21.5 17.3431 20.1569 16 18.5 16C16.8431 16 15.5 17.3431 15.5 19C15.5 20.6569 16.8431 22 18.5 22Z"
                                  stroke="#FAE100"
                                  stroke-width="2"
                                  strokeLinecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M9.08984 13.5098L15.9198 17.4898"
                                  stroke="#FAE100"
                                  stroke-width="2"
                                  strokeLinecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M15.9098 6.50977L9.08984 10.4898"
                                  stroke="#FAE100"
                                  stroke-width="2"
                                  strokeLinecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </button>
                          </div>
                          <div className="logo_form res_none_share">
                            {/* <p>Powered by Event Visionary. </p> */}
                            {/* <img src="./imgs/4736150369408c50504a5b1df6f6db67.png" alt=""/> */}
                            <p>Powered by</p>
                            <img src={Logo} alt="" className="popup_logo" />
                          </div>
                        </div>

                        <div className="get_ticket_cart res_none_share">
                          {/* <div className="get_ticket_carti"></div> */}
                          <img
                            src={EventImg}
                            alt=""
                            className="get_ticket_carti"
                          />

                          <div className="cart_btns">
                            <a href="/events" className="btn">
                              Browse More Events
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="single_sticky_bar2 res_tm pc_none">
                        <div className="sticky_inner2">
                          <a href="/events">
                            <p className="res_yellow">
                              Browse More Events <br />
                              by this Organizer
                            </p>
                          </a>
                          {/* <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45" fill="none">
                                            <path d="M16.7347 43.8046C15.891 43.8046 15.1175 43.453 14.555 42.8202C13.5004 41.6249 13.8519 40.2186 13.9925 39.5858L17.7191 23.6249H10.4769C9.98473 23.6249 9.00036 23.6249 8.15661 22.9921C6.75036 21.8671 7.17223 19.9686 7.24254 19.3358L10.1957 4.92175C10.3363 4.14831 10.6175 2.953 11.6722 2.10925C12.8675 1.19519 14.0629 1.19519 14.766 1.2655H24.3988C25.2425 1.2655 27.0004 1.2655 28.055 2.60144C29.1097 3.93738 28.7582 5.69519 28.6175 6.53894L27.7738 10.5468L33.9613 10.6171C36.4222 10.6171 37.3363 11.7421 37.6175 12.6561C38.1097 14.0624 37.266 15.328 36.9144 15.8202L19.6879 41.7655C19.3363 42.2577 18.8441 43.0311 17.93 43.5233C17.5785 43.6639 17.2269 43.8046 16.8754 43.8046C16.8754 43.7342 16.805 43.8046 16.7347 43.8046ZM10.4066 20.3905C10.4769 20.3905 10.5472 20.3905 10.5472 20.3905H19.7582C20.2504 20.3905 20.6722 20.6014 21.0238 21.0233C21.305 21.3749 21.4457 21.8671 21.305 22.3593L17.2269 39.7967L34.2425 14.0624C34.3129 13.9921 34.3832 13.8514 34.3832 13.7811C34.2425 13.7811 34.1019 13.7811 33.8207 13.7811L25.7347 13.7108C25.2425 13.7108 24.8207 13.4999 24.5394 13.1483C24.2582 12.7968 24.1175 12.3046 24.2582 11.8124L25.5238 5.83581C25.7347 4.85144 25.6644 4.6405 25.5941 4.57019C25.5238 4.49988 25.3129 4.42956 24.3285 4.42956H14.766C14.2035 4.42956 13.8519 4.42956 13.7816 4.49988C13.7113 4.57019 13.5707 4.92175 13.43 5.48425L10.4769 19.8983C10.4769 20.1093 10.4066 20.2499 10.4066 20.3905Z" fill="#FAE100" />
                                        </svg> */}
                        </div>
                      </div>
                    </div>

                    <div id="popup29" className="overlay">
                      <div className="get_ticketp2">
                        <div className="get_ticket_over">
                          <div className="get_ticket_overi res_none">
                            <h1>Event Title</h1>
                            <p className="res_yellow">
                              Saturday, July 5, 2025 at 7:30 pm
                            </p>
                            <p>Select your ticket(s) below.</p>
                          </div>
                          <div className="sell_tickets_warp2">
                            <TicketCounter Addclass="sell_tickets_box" />
                            <TicketCounter Addclass="sell_tickets_box" />
                            <p className="Ticket_p">
                              Ticket Type Name (Multi-Level)
                            </p>
                            <TicketCounter Addclass="sell_tickets_box_in" />
                            <TicketCounter Addclass="sell_tickets_box_in" />
                          </div>

                          <div className="promo_ticket res_none">
                            <form action="">
                              <label htmlFor="promo">Promo Code</label>
                              <div className="promo_ticket_input">
                                <input type="text" />
                                <button type="submit">Apply</button>
                              </div>
                            </form>
                            <div className="box">
                              <div className="back_form">
                                <div className="back_btns">
                                  <a href="#popup14">
                                    <FaChevronLeft className="back_icon" />
                                  </a>
                                  <button className="" onClick={closePopup15}>
                                    Back
                                  </button>
                                </div>
                                <a className="btn" href="#popup16">
                                  Get Tickets
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>

                        <a className="jclose" href="#">
                          <RiCloseFill color="#FAE100" className="Xmarks" />
                        </a>
                        <a className="fclose pc_none" href="#popup14">
                          <IoIosArrowBack />
                        </a>
                        <div className="get_ticket_cart">
                          <div className="get_ticket_overi pc_none">
                            <h1>Event Title</h1>
                            <p className="res_yellow">
                              Saturday, July 5, 2025 at 7:30 pm 
                            </p>
                            <h1>Order Summary</h1>
                            <p className="res_m">Ticket Type Name</p>
                          </div>
                          {/* <div className="get_ticket_carti res_none"></div> */}
                          <img
                            src={EventImg}
                            alt=""
                            className="get_ticket_carti"
                          />

                          <div className="cart_ticker">
                            <h2 className="res_none">Cart</h2>
                            <div className="order_sum_text">
                              <div className="order_summary_data">
                                <p className="no">x1</p>
                                <div className="order_tinfo">
                                  <p className="type_name">Ticket Type Name </p>
                                  <p className="type_id">
                                    Seat ID or Table ID or Both
                                  </p>
                                </div>
                                <div className="order_pinfo">$25.00</div>
                                <div className="order_trash">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="15"
                                    height="15"
                                    viewBox="0 0 15 15"
                                    fill="none"
                                  >
                                    <path
                                      d="M3.04688 15H11.9531V2.34375H12.6562V1.875H9.375V0H5.625V1.875H2.34375V2.34375H3.04688V15ZM6.09375 0.46875H8.90625V1.875H6.09375V0.46875ZM11.4844 2.34375V14.5312H3.51562V2.34375H11.4844Z"
                                      fill="#FF0000"
                                    />
                                    <path
                                      d="M6.32812 4.6875H5.85938V12.6562H6.32812V4.6875Z"
                                      fill="#FF0000"
                                    />
                                    <path
                                      d="M9.14062 4.6875H8.67188V12.6562H9.14062V4.6875Z"
                                      fill="#FF0000"
                                    />
                                  </svg>
                                </div>
                              </div>
                              <div className="order_summary_data">
                                <p className="no">x1</p>
                                <div className="order_tinfo">
                                  <p className="type_name">Ticket Type Name </p>
                                  <p className="type_id">
                                    Seat ID or Table ID or Both
                                  </p>
                                </div>
                                <div className="order_pinfo">$25.00</div>
                                <div className="order_trash">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="15"
                                    height="15"
                                    viewBox="0 0 15 15"
                                    fill="none"
                                  >
                                    <path
                                      d="M3.04688 15H11.9531V2.34375H12.6562V1.875H9.375V0H5.625V1.875H2.34375V2.34375H3.04688V15ZM6.09375 0.46875H8.90625V1.875H6.09375V0.46875ZM11.4844 2.34375V14.5312H3.51562V2.34375H11.4844Z"
                                      fill="#FF0000"
                                    />
                                    <path
                                      d="M6.32812 4.6875H5.85938V12.6562H6.32812V4.6875Z"
                                      fill="#FF0000"
                                    />
                                    <path
                                      d="M9.14062 4.6875H8.67188V12.6562H9.14062V4.6875Z"
                                      fill="#FF0000"
                                    />
                                  </svg>
                                </div>
                              </div>
                            </div>
                            <div className="order_main_list">
                              <div className="order_list_items">
                                <div className="order_list_item">
                                  <p>Subtotal</p>
                                  <p>$50.00</p>
                                </div>
                                <div className="order_list_item">
                                  <p>Taxes</p>
                                  <p>$50.00</p>
                                </div>
                                <div className="order_list_item">
                                  <p>Service Fees</p>
                                  <p>$50.00</p>
                                </div>
                                <div className="order_list_item">
                                  <p>Total</p>
                                  <p>$50.00</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="single_sticky_bar2 pc_none2">
                          <div className="sticky_inner2">
                            <a href="./about.html">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="40"
                                height="40"
                                viewBox="0 0 40 40"
                                fill="none"
                              >
                                <path
                                  d="M19.9266 25.4131C17.8146 25.4131 16.125 27.1027 16.125 29.2147C16.125 31.3267 17.8146 33.0164 19.9266 33.0164C22.0387 33.0164 23.7283 31.3267 23.7283 29.2147C23.7283 27.1555 21.9858 25.4131 19.9266 25.4131ZM19.9266 30.6931C19.1346 30.6931 18.501 30.0595 18.501 29.2675C18.501 28.4755 19.1346 27.8419 19.9266 27.8419C20.7186 27.8419 21.3522 28.4755 21.3522 29.2675C21.3522 30.0067 20.6658 30.6931 19.9266 30.6931Z"
                                  fill="#FAE100"
                                />
                                <path
                                  d="M9.89441 25.4131C7.78239 25.4131 6.09277 27.1027 6.09277 29.2147C6.09277 31.3267 7.78239 33.0164 9.89441 33.0164C12.0064 33.0164 13.696 31.3267 13.696 29.2147C13.696 27.1555 11.9536 25.4131 9.89441 25.4131ZM9.89441 30.6931C9.1024 30.6931 8.46879 30.0595 8.46879 29.2675C8.46879 28.4755 9.1024 27.8419 9.89441 27.8419C10.6864 27.8419 11.32 28.4755 11.32 29.2675C11.32 30.0067 10.6864 30.6931 9.89441 30.6931Z"
                                  fill="#FAE100"
                                />
                                <path
                                  d="M31.0679 1.125H28.2167C26.9495 1.125 25.8407 2.07541 25.6823 3.34262L24.8375 9.41467H22.6199V5.56024C22.5671 4.60983 21.8279 3.87062 20.9303 3.87062H17.023C16.8646 3.07862 16.1782 2.49781 15.3862 2.49781H10.7926C9.89496 2.49781 9.10296 3.28982 9.10296 4.29303V5.29624H5.24853C4.35092 5.29624 3.55891 6.03544 3.55891 6.88025V9.46747C3.13651 9.57307 2.8197 9.78427 2.5557 10.1011C2.2389 10.5235 2.0805 11.1043 2.2389 11.6323C2.2389 11.6851 2.2389 11.6851 2.2389 11.7379L5.51253 21.6116C5.72373 22.3508 6.41014 22.8788 7.20214 22.8788H21.7751C23.7287 22.8788 25.4183 21.4004 25.6823 19.4468L27.8999 3.65942C27.8999 3.55382 28.0055 3.50102 28.1111 3.50102H30.9623C31.596 3.50102 32.1768 2.97302 32.1768 2.28661C32.1768 1.6002 31.7016 1.125 31.0679 1.125ZM20.1911 9.41467H17.023V6.24664H20.1911V9.41467ZM11.5318 4.87383H14.6998V5.56024V9.41467H11.5318V6.93305V4.87383ZM5.98773 7.61946H9.15576V9.41467H5.98773V7.61946ZM23.4119 19.0772C23.3063 19.8692 22.6199 20.45 21.8279 20.45H7.67735L4.82612 11.7907H24.4679L23.4119 19.0772Z"
                                  fill="#FAE100"
                                />
                              </svg>
                            </a>
                            <div className="price_lable">
                              <label htmlFor="">Total:</label>
                              <p>$25.00</p>
                            </div>
                            <div className="box">
                              <a className="btn sm" href="#popup16">
                                Buy Now
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div id="popup17" className={`overlay_14 ${popup17Class}`}>
                      <div className="popupmain">
                        <div className="order_done">
                          <button className="jclose" onClick={closePopup17}>
                            <RiCloseFill color="#FAE100" className="Xmarks" />
                          </button>
                          <p>Share this event with friends</p>
                          <div className="copy_links">
                            <input
                              type="text"
                              disabled
                              placeholder="sefwfnwefbwuiub"
                              value={uniqueUrl}
                              ref={inputRef}
                              className="cursor_pointer white_txt"
                            />
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              onClick={handleCopyToClipboard}
                              width="25"
                              height="24"
                              viewBox="0 0 25 24"
                              fill="none"
                            >
                              <path
                                d="M20.5 9H11.5C10.3954 9 9.5 9.89543 9.5 11V20C9.5 21.1046 10.3954 22 11.5 22H20.5C21.6046 22 22.5 21.1046 22.5 20V11C22.5 9.89543 21.6046 9 20.5 9Z"
                                stroke="white"
                                stroke-width="2"
                                strokeLinecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M5.5 15H4.5C3.96957 15 3.46086 14.7893 3.08579 14.4142C2.71071 14.0391 2.5 13.5304 2.5 13V4C2.5 3.46957 2.71071 2.96086 3.08579 2.58579C3.46086 2.21071 3.96957 2 4.5 2H13.5C14.0304 2 14.5391 2.21071 14.9142 2.58579C15.2893 2.96086 15.5 3.46957 15.5 4V5"
                                stroke="white"
                                stroke-width="2"
                                strokeLinecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </div>
                          <div className="sharep_icons">
                            <a href="https://www.facebook.com/" target="_blank">
                              <FaFacebookF className="share_i" />
                            </a>
                            <a href="https://twitter.com/home" target="_blank">
                              <i className="bx bxl-twitter"></i>
                              <FaTwitter className="share_i" />
                            </a>
                            <a href="https://www.linkedin.com/" target="_blank">
                              <i className="bx bxl-linkedin"></i>
                              <FaLinkedin className="share_i" />
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="samll_container"></div>
                    </div>
                  </div>
                  <div className="single_event_share">
                    <p>Share this event</p>
                    <a className="" href="#popup18">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="24"
                        viewBox="0 0 25 24"
                        fill="none"
                      >
                        <path
                          d="M18.5 8C20.1569 8 21.5 6.65685 21.5 5C21.5 3.34315 20.1569 2 18.5 2C16.8431 2 15.5 3.34315 15.5 5C15.5 6.65685 16.8431 8 18.5 8Z"
                          stroke="#FAE100"
                          stroke-width="2"
                          strokeLinecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M6.5 15C8.15685 15 9.5 13.6569 9.5 12C9.5 10.3431 8.15685 9 6.5 9C4.84315 9 3.5 10.3431 3.5 12C3.5 13.6569 4.84315 15 6.5 15Z"
                          stroke="#FAE100"
                          stroke-width="2"
                          strokeLinecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M18.5 22C20.1569 22 21.5 20.6569 21.5 19C21.5 17.3431 20.1569 16 18.5 16C16.8431 16 15.5 17.3431 15.5 19C15.5 20.6569 16.8431 22 18.5 22Z"
                          stroke="#FAE100"
                          stroke-width="2"
                          strokeLinecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M9.08984 13.5098L15.9198 17.4898"
                          stroke="#FAE100"
                          stroke-width="2"
                          strokeLinecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M15.9098 6.50977L9.08984 10.4898"
                          stroke="#FAE100"
                          stroke-width="2"
                          strokeLinecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </a>
                  </div>
                  <div id="popup18" className="overlay">
                    <div className="popupmain">
                      <div className="order_done">
                        <a className="jclose" href="#">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                              stroke="#FAE100"
                              stroke-width="2"
                              strokeLinecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M15 9L9 15"
                              stroke="#FAE100"
                              stroke-width="2"
                              strokeLinecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M9 9L15 15"
                              stroke="#FAE100"
                              stroke-width="2"
                              strokeLinecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </a>
                        <p>Share this event with friends</p>
                        <div className="copy_links">
                          <input
                            type="text"
                            disabled
                            placeholder="sefwfnwefbwuiub"
                            value={uniqueUrl}
                            ref={inputRef}
                            className="cursor_pointer white_txt"
                          />
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            onClick={handleCopyToClipboard}
                            width="25"
                            height="24"
                            viewBox="0 0 25 24"
                            fill="none"
                          >
                            <path
                              d="M20.5 9H11.5C10.3954 9 9.5 9.89543 9.5 11V20C9.5 21.1046 10.3954 22 11.5 22H20.5C21.6046 22 22.5 21.1046 22.5 20V11C22.5 9.89543 21.6046 9 20.5 9Z"
                              stroke="white"
                              stroke-width="2"
                              strokeLinecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M5.5 15H4.5C3.96957 15 3.46086 14.7893 3.08579 14.4142C2.71071 14.0391 2.5 13.5304 2.5 13V4C2.5 3.46957 2.71071 2.96086 3.08579 2.58579C3.46086 2.21071 3.96957 2 4.5 2H13.5C14.0304 2 14.5391 2.21071 14.9142 2.58579C15.2893 2.96086 15.5 3.46957 15.5 4V5"
                              stroke="white"
                              stroke-width="2"
                              strokeLinecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </div>
                        <div className="sharep_icons">
                          <a href="https://www.facebook.com/" target="_blank">
                            <FaFacebookF className="share_i" />
                          </a>
                          <a href="https://twitter.com/home" target="_blank">
                            <i className="bx bxl-twitter"></i>
                            <FaTwitter className="share_i" />
                          </a>
                          <a href="https://www.linkedin.com/" target="_blank">
                            <i className="bx bxl-linkedin"></i>
                            <FaLinkedin className="share_i" />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="samll_container"></div>
                  </div>
                </div>

                {/* <PopupBox/> */}
              </div>
            </div>
          </div>
          <div className="single_sticky_bar pc_none2">
            <div className="sticky_inner">
              <p>
                <span>${eventPriceRange.minimum_price}</span> - <span></span>$
                {eventPriceRange.maximum_price}
              </p>
              <div className="box">
                <button className="btn sm" onClick={openPopup14}>
                  Get Tickets
                </button>
              </div>
            </div>
          </div>
        </RootLayout>
      </>
    );
}
// Define your CheckoutForm component
const CheckoutForm = ({ getTicketFinalButton }) => {
    // console.log("inside Checkout Form ")
    // const REACT_APP_STRIPE_PUBLISHABLE_KEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY

    // const stripe = useStripe(String(REACT_APP_STRIPE_PUBLISHABLE_KEY));
    // const elements = useElements();
    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async (event) => {
        event.preventDefault();

        const response = await getTicketFinalButton();
        console.log(" response : ", response)
        if (!response){
            alert("failed to book reserve tickets, pleaser book tickets again.")
            return
        }
        if (!stripe || !elements) {
            return;
        }
        

      const result = await stripe.confirmPayment({
            elements,
            confirmParams: {
                // You can optionally provide other confirmParams here
            },
            redirect: "if_required"  // Use "if_required" to avoid redirection unless necessary
        });

        if (result.error) {
            console.error(result.error.message);
        } else {
            console.log('Payment successful!');
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <PaymentElement />
            {/* <button type="submit" style={{ marginTop: '20px', width: '100%', padding: '10px', fontSize: '18px', backgroundColor: '#000', color: '#FFD700', border: 'none', borderRadius: '5px', cursor: 'pointer', textTransform: 'uppercase' }}> */}
            <button type="submit" className='btn'  style={{ marginTop: '20px', width: '100%', padding: '10px', fontSize: '18px' }}>Pay</button>
        </form>
    );
};

export default SingleEvent
