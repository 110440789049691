import { FaBars, FaXmark } from "react-icons/fa6";
import styles from "../header.module.css";
import { useState } from "react";
import clsx from "clsx";
import { ModalLayout } from "@/layouts/ModalLayout/ModalLayout";
import { EventRoutes } from "@/routes/EventRoutes";
import { Link } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { Button } from "@/components/Button/Button";
import { DashboardRoutes } from "@/routes/DashboardRoutes";

const LINKS = [
  {
    href: "https://eventvisionary.com",
    text: "Home",
    isExternal: true,
  },
  {
    href: "https://eventvisionary.com/pricing",
    text: "Pricing",
    isExternal: true,
  },
  {
    href: EventRoutes.VIEW_ALL_EVENTS,
    text: "Events",
  },
  {
    href: DashboardRoutes.CREATE_EVENT,
    text: "Create event",
    className: styles.createEventLink,
  },
];

export const MobileMenu = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleOpenMenu = () => {
    setIsMenuOpen(true);
  };

  const handleCloseMenu = () => {
    setIsMenuOpen(false);
  };

  const variants = {
    open: { width: "80%" },
    close: { width: "0%" },
  };

  return (
    <AnimatePresence mode="wait">
      {isMenuOpen && (
        <ModalLayout>
          <motion.div
            className={styles.mobileMenuContainer}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <button className={styles.closeButton} onClick={handleCloseMenu}>
              <FaXmark />
            </button>
            <motion.div
              className={styles.mobileMenu}
              initial="close"
              animate="open"
              exit="close"
              variants={variants}
            >
              {LINKS.map((link) => (
                <Link
                  className={clsx(styles.mobileLink, link?.className)}
                  to={link.href}
                  key={link.text}
                >
                  {link.text}
                </Link>
              ))}
            </motion.div>
          </motion.div>
        </ModalLayout>
      )}
      <div className={clsx(styles.dropdownContainer, styles.mobile)}>
        <button className={styles.hamburgerButton} onClick={handleOpenMenu}>
          <FaBars />
        </button>
      </div>
    </AnimatePresence>
  );
};
