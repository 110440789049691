import "./index.css";

export const ToggleSwitch = ({ register }) => {
  return (
    <label className="custom-switch">
      <input type="checkbox" {...register} />
      <span className="custom-slider round"></span>
    </label>
  );
};
