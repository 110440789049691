import { useParams } from "react-router-dom";
import styles from "../ticketPurchaseWizard.module.css";
import { useGetEventFromToken } from "../../../api/hooks/events/useGetEventFromToken";
import { EventInfoHeader } from "../components/EventInfoHeader";
import { useGetEventPricingDataFromToken } from "../../../api/hooks/events/useGetEventPricingDataFromToken";
import { useGetEventSeatCategoryData } from "../hooks/useGetEventSeatCategoryData";
import { SkeletonLoader } from "../../SkeletonLoader/SkeletonLoader";
import { TicketPurchaseWidget } from "../components/TicketPurchaseWidget";
import { generateTicketName } from "../helpers/ticketHelpers";

export const SelectTickets = ({ onUpdateTickets }) => {
  const { token: eventToken } = useParams();

  const {
    data: eventData,
    isLoading: isGetEventDataLoading,
    isError: isGetEventDataError,
    isSuccess: isGetEventDataSuccess,
  } = useGetEventFromToken(eventToken, Boolean(eventToken));

  const {
    data: eventPricingData,
    isLoading: isGetEventPricingDataLoading,
    isError: isGetEventPricingDataError,
    isSuccess: isGetEventPricingDataSuccess,
  } = useGetEventPricingDataFromToken(eventToken, Boolean(eventToken));

  const {
    data: eventSeatCategoryData,
    isLoading: isGetEventSeatCategoryDataLoading,
    isError: isGetEventSeatCategoryDataError,
    isSuccess: isGetEventSeatCategoryDataSuccess,
  } = useGetEventSeatCategoryData(
    eventData?.event?.id,
    Boolean(eventData?.event?.id)
  );

  if (
    isGetEventDataLoading ||
    isGetEventPricingDataLoading ||
    isGetEventSeatCategoryDataLoading
  ) {
    return (
      <div className={styles.modalMainContent}>
        <SkeletonLoader width="100%" height="100%" />
      </div>
    );
  }

  const handleUpdateQuantity = (ticketData) => {
    onUpdateTickets(ticketData);
  };

  const mapSubcategories = ({ subcategories, categoryId, categoryName }) => {
    return (
      <div className={styles.selectTicketsSubcategoryContainer}>
        {subcategories.map((subcategory) => {
          return (
            <div
              className={styles.selectTicketsSubcategory}
              key={subcategory?.name}
            >
              <h5>
                {subcategory?.name}
                {subcategory?.quantity === 0 ? (
                  <span className={styles.soldOutText}>(Sold out!)</span>
                ) : (
                  ""
                )}
              </h5>
              <TicketPurchaseWidget
                maxQuantity={Number(subcategory?.quantity)}
                onUpdateQuantity={(newQuantity) =>
                  handleUpdateQuantity({
                    id: generateTicketName(categoryName, subcategory?.name),
                    quantity: newQuantity,
                    price: subcategory?.price,
                    category: categoryId,
                    categoryName: categoryName,
                    subcategoryName: subcategory?.name,
                    isSubcategory: true,
                  })
                }
              />
            </div>
          );
        })}
      </div>
    );
  };

  const mapCategory = (category) => {
    return (
      <div
        className={styles.selectTicketsCategoryContainer}
        key={category?.categoryData?.name}
      >
        <h4 className={styles.selectTicketsCategoryName}>
          {category.categoryName}
          {category?.categoryData?.quantity === 0 &&
          !category?.hasSubcategories ? (
            <span className={styles.soldOutText}>(Sold out!)</span>
          ) : (
            ""
          )}
        </h4>
        {category?.hasSubcategories ? (
          <>
            {mapSubcategories({
              subcategories: category?.subcategoriesData,
              categoryName: category?.categoryData?.name,
              categoryId: category?.categoryData?.id,
            })}
          </>
        ) : (
          <TicketPurchaseWidget
            maxQuantity={Number(category?.categoryData?.quantity)}
            onUpdateQuantity={(newQuantity) =>
              handleUpdateQuantity({
                id: generateTicketName(category?.categoryData?.name),
                quantity: newQuantity,
                price: category?.categoryData?.price,
                category: category?.categoryData?.id,
                categoryName: category?.categoryData?.name,
                isSubcategory: false,
              })
            }
          />
        )}
      </div>
    );
  };

  if (
    isGetEventDataSuccess ||
    isGetEventPricingDataSuccess ||
    isGetEventSeatCategoryDataSuccess
  ) {
    return (
      <div className={styles.modalMainContent}>
        <EventInfoHeader />
        <h1 className={styles.selectTicketsTitle}>
          Choose the tickets you'd like to buy{" "}
        </h1>
        <div className={styles.selectTicketContainer}>
          {eventSeatCategoryData?.categories?.map((category) =>
            mapCategory(category)
          )}
        </div>
      </div>
    );
  }
};
