import React, {useState, useEffect} from 'react'
import './index.css'
// import Image from 'next/image'
import { Rectangle } from '../../public'
// import Link from 'next/link'
import { RootLayout } from "@/layouts/RootLayout/RootLayout";
import { useParams } from "react-router-dom";
import { EventCard } from "@/components/EventCard/EventCard";
import { useQueryClient } from "react-query";
import { camelizeKeys } from "humps";

const Events = () => {
  const baseUrl = window.location.href;
  // const token = baseUrl.match(/token=([^&]+)/)[1];
  const { organizer } = useParams();
  const queryClient = useQueryClient();

  // const tokenParam = useParam('token'); // Get the value of the 'token' parameter from the URL
  // const token = tokenParam.split('=')[1];
  const [organizers, setOrganizers] = useState({});
  const [events, setEvents] = useState([]);
  const [venueNames, setVenueNames] = useState({});
  const [user, setUser] = useState([]);
  const [eventImageUrl, setEventImageUrl] = useState("");
  let base_api_url = `${process.env.REACT_APP_BASE_URL}`;

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // function buildCompleteImageUrl(relativeUrl) {
  const buildCompleteImageUrl = (relativeUrl) => {
    try {
      // Get base URL from window location
      // const baseUrl = window.location.href.replace(/\/[^\/]+$/, '/');
      const baseUrl = `${process.env.REACT_APP_BASE_URL}`;

      // Construct complete image URL
      const completeUrl = new URL(relativeUrl, baseUrl).href;
      console.log("url is ", completeUrl);

      return completeUrl;
    } catch (error) {
      console.error("Error building complete image URL:", error);
      console.error("Failed to build complete image URL");
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    // Format the date as desired
    const formattedDate = date.toLocaleDateString(); // Format: MM/DD/YYYY
    return formattedDate;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const authToken = localStorage.getItem("authToken");

        if (!authToken) {
          console.error("Authentication token not found");
        }
        let org_name = organizer.toLowerCase().replace(/-/g, " ");
        const body = JSON.stringify({
          org: org_name,
          // Toggle the status value
        });
        // Fetch event data
        const eventResponse = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/eventsoforg/`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: body,
          }
        );
        if (!eventResponse.ok) {
          console.error("Failed to fetch event data");
        }
        const eventData = await eventResponse.json();

        for (let event of eventData?.events) {
          queryClient.setQueryData(
            ["getEventFromId", event?.id],
            camelizeKeys(event)
          );
        }

        setEvents(eventData.events);
        setUser(eventData.user);

        const baseURL = `${base_api_url}`;
        let imageURL = eventData.user.logo_image;
        console.log("1 : imageURL: ", imageURL);
        if (imageURL.startsWith("/images")) {
          console.log("2 ");

          imageURL = `${baseURL}${imageURL}`;
          console.log("3 updated imageURL : ", imageURL);
          setEventImageUrl(imageURL);
        } else {
          console.log("in else");
          setEventImageUrl(eventData.user.logo_image);
        }
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    // Call the fetchData function
    fetchData();
  }, [organizer]); // Include eventId in the dependency array to trigger the effect when it changes

  useEffect(() => {
    const fetchData = async (venueId, eventId) => {
      try {
        const authToken = localStorage.getItem("authToken");
        if (!authToken) {
          console.error("Authentication token not found");
        }

        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/venues/${venueId}/`,
          {
            headers: {
              Authorization: `Token ${authToken}`,
            },
          }
        );

        if (!response.ok) {
          console.error("Failed to fetch venue data");
        }

        const venueData = await response.json();

        // Update venueNames state with the fetched venue name
        setVenueNames((prevVenueNames) => ({
          ...prevVenueNames,
          [eventId]: venueData.venue_name,
        }));
      } catch (error) {
        console.error("Error fetching venue data:", error);
      }
    };

    // Fetch venue data for each event
    events.forEach((event) => {
      fetchData(event.Venue_name, event.id);
    });
  }, [events]); // Empty dependency array to ensure useEffect runs only once when the component mounts

  useEffect(() => {
    const fetchOrganizers = async () => {
      const newOrganizers = {};
      for (let event of events) {
        if (!organizers[event.user]) {
          const orgName = await getOrganizer(event.user);
          newOrganizers[event.user] = orgName;
          console.log("inside if: ", newOrganizers);
        } else {
          console.log("inside else: ", event);
        }
      }
      setOrganizers((prev) => ({ ...prev, ...newOrganizers }));
    };

    if (events.length > 0) {
      fetchOrganizers();
    }
  }, [events]);

  const getOrganizer = async (userId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/users/${userId}/`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        console.error("Failed to fetch user data");
        return "";
      }

      const userData = await response.json();
      let orgName = userData.business_name || "";
      orgName = orgName.toLowerCase().replace(/ /g, "-");
      console.log("new name is ", orgName);
      return orgName;
    } catch (error) {
      console.error("Error fetching organizer data:", error);
      return "";
    }
  };

  const createSlug = (name) => {
    return name.toLowerCase().replace(/ /g, "-");
  };

  return (
    <>
      <RootLayout>
        <div className="main_container">
          <div className="event_container">
            <div className="orgainer_card">
              <div className="orgainer_card_info">
                <p>{user.business_name}</p>
                {user.is_phone_display ? <p>{user.phone}</p> : <p></p>}
                {user.is_email_display ? <p>{user.email}</p> : <p></p>}
              </div>
              <div className="organiner_img">
                <img
                  className="organiner_img"
                  src={eventImageUrl}
                  alt="Organizer Image"
                />
              </div>
            </div>
            <h2>Upcoming Events</h2>
            <div className="main_events">
              <div className="events_row_2">
                <div className="event_cards">
                  {events &&
                    Array.isArray(events) &&
                    events.map((event) => (
                      <EventCard key={event?.id} eventId={event?.id} />
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </RootLayout>
    </>
  );
};

export default Events
