import { FormProvider, useForm } from "react-hook-form";
import { ModalLayout } from "@/layouts/ModalLayout/ModalLayout";
import { BillingInfoForm } from "./pages/BillingInfoForm";
import styles from "./ticketPurchaseWizard.module.css";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { FaArrowLeft, FaXmark } from "react-icons/fa6";
import { SeatPicker } from "./pages/SeatPicker";
import { useState } from "react";
import { useGetEventFromToken } from "@/api/hooks/events/useGetEventFromToken";
import { useParams } from "react-router-dom";
import { Cart } from "./components/Cart";
import { Button } from "../Button/Button";
import {
  parseSeatObject,
  parseSeatDataIntoCartItem,
} from "./helpers/seatHelpers";
import { SelectTickets } from "./pages/SelectTickets";
import { useReleaseSeat } from "./hooks/useReleaseSeat";
import { StripeCheckoutWrapper } from "./components/StripeCheckoutWrapper";
import { validateCartNotEmpty } from "./helpers/validationHelpers";
import { useSubmitBillingInfo } from "./hooks/useSubmitBillingInfo";
import { StripeCardDetailsForm } from "./pages/StripeCardDetailsForm";
import { Loader } from "../Loader/Loader";
import { PaymentSuccessful } from "./pages/PaymentSuccessful";
import { SkeletonLoader } from "../SkeletonLoader/SkeletonLoader";
import {
  parseTicketData,
  parseTicketDataIntoCartItem,
} from "./helpers/ticketHelpers";
import { useQueryClient } from "react-query";
import clsx from "clsx";

const billingInfo = z.object({
  firstName: z.string().min(2, { message: "Invalid first name" }),
  lastName: z.string().min(2, { message: "Invalid last name" }),
  email: z.string().email({ message: "Invalid email" }),
  orderNote: z
    .string()
    .max(512, { message: "Order note cannot be more than 512 characters" })
    .optional()
    .default(""),
  // phone: z
  //   .string()
  //   .min(11, { message: "Invalid phone number!" })
  //   .max(14, { message: "Invalid phone number!" })
  //   .optional(),
  isTextTicketRequired: z.boolean().default(false),
  addressLineOne: z.string().min(5, { message: "Invalid address" }),
  addressLineTwo: z.string().min(5, { message: "Invalid address" }),
  city: z.string().min(2, { message: "Invalid city name" }),
  state: z.string().min(2, { message: "Invalid state name" }),
  zipCode: z.string().min(2, { message: "Invalid ZIP code" }),
  country: z.string().min(2, { message: "Invalid country name" }),
});

const schema = z.object({
  payment: billingInfo,
});

// This component can either be in "CUSTOMER" mode or "ORGANIZER" mode
export const TicketPurchaseWizard = ({ mode, onClose }) => {
  if (mode === "ORGANIZER") {
    console.log(billingInfo);

    billingInfo.extend({
      phone: z
        .string()
        .min(11, { message: "Invalid phone number!" })
        .max(14, { message: "Invalid phone number!" })
        .optional(),
      addressLineOne: z
        .string()
        .min(5, { message: "Invalid address" })
        .optional(),
      addressLineTwo: z
        .string()
        .min(5, { message: "Invalid address" })
        .optional(),
      city: z.string().min(2, { message: "Invalid city name" }).optional(),
      state: z.string().min(2, { message: "Invalid state name" }).optional(),
      zipCode: z.string().min(2, { message: "Invalid ZIP code" }).optional(),
      country: z
        .string()
        .min(2, { message: "Invalid country name" })
        .optional(),
    });
  }

  const queryClient = useQueryClient();
  const { token: eventToken } = useParams();
  // In situations where there is no reserved seating, INITIAL_PAGES[0] will be changed to
  // "SELECT_TICKETS"

  const INITIAL_PAGES = [
    "SEAT_PICKER",
    "BILLING_INFO",
    "CARD_DETAILS",
    "PAYMENT_SUCCESSFUL",
  ];

  const PAYMENT_PAGES = ["BILLING_INFO", "CARD_DETAILS"];

  const [pages, setPages] = useState([...INITIAL_PAGES]);
  const [currentPage, setCurrentPage] = useState(pages[0]);
  // selectedSeats is an array of SeatData objects, i.e the formatted output of parseSeatObject
  const [selectedSeats, setSelectedSeats] = useState([]);
  const [selectedTickets, setSelectedTickets] = useState([]);

  const getCurrentPageIndex = () => {
    return pages.indexOf(currentPage);
  };

  const {
    data: eventData,
    isLoading: isGetEventDataLoading,
    isError: isGetEventDataError,
    isSuccess: isGetEventDataSuccess,
  } = useGetEventFromToken(eventToken, Boolean(eventToken));

  // Checks if the event has reserved seating, and changes the first page accordingly
  if (
    eventData?.event?.isSeatingReserved !== undefined &&
    !eventData?.event?.isSeatingReserved &&
    pages[0] != "SELECT_TICKETS"
  ) {
    const newPages = [...pages];
    newPages[0] = "SELECT_TICKETS";
    setPages(newPages);

    if (currentPage === "SEAT_PICKER") {
      setCurrentPage("SELECT_TICKETS");
    }
  }

  const methods = useForm({
    mode: "onChange",
    shouldUnregister: false,
    resolver: zodResolver(schema),
  });

  const releaseSeat = useReleaseSeat();
  const submitBillingInfo = useSubmitBillingInfo({
    onSuccess: (data) => {
      // This shares the billing information data with the StripeCheckoutWrapper component
      queryClient.setQueryData("submitBillingInformation", data);
      setCurrentPage(pages[getCurrentPageIndex() + 1]);
    },
  });

  const handleSelectSeat = (seatObject) => {
    const seatData = parseSeatObject(seatObject);
    setSelectedSeats((selectedSeats) => [...selectedSeats, seatData]);
  };

  const handleDeselectSeat = (seatObject) => {
    const seatData = parseSeatObject(seatObject);
    setSelectedSeats((selectedSeats) => [
      ...selectedSeats.filter((seat) => seat.id !== seatData.id),
    ]);
  };

  const handleDeleteSeatFromCart = (seatData) => {
    setSelectedSeats((selectedSeats) => [
      ...selectedSeats.filter((seat) => seat?.id !== seatData?.id),
    ]);
    releaseSeat.mutate({
      eventKey: eventData?.event?.eventId,
      holdToken: seatData?.holdToken,
      seatLabel: seatData?.label,
    });
  };

  const handleUpdateTickets = (ticketData) => {
    const newTicketData = parseTicketData(ticketData);

    const ticketIndex = selectedTickets.findIndex(
      (ticket) => ticket.id === newTicketData.id
    );

    if (ticketIndex === -1) {
      // we are adding a new ticket
      setSelectedTickets([...selectedTickets, newTicketData]);
    } else {
      // we are just updating the quantity of an already selected ticket
      let newTickets = [...selectedTickets];

      if (newTicketData.quantity === 0) {
        newTickets = newTickets.filter(
          (ticket) => ticket.id !== newTicketData.id
        );
      } else {
        newTickets[ticketIndex].quantity = newTicketData.quantity;
      }

      setSelectedTickets([...newTickets]);
    }
  };

  const doSubmitBillingInfo = () => {
    submitBillingInfo.mutate({
      ...methods.getValues().payment.billingInfo,
      isTextTicketRequired: true,
    });
  };

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  const handlePrevious = () => {
    if (currentPage != pages[0]) {
      setCurrentPage(pages[getCurrentPageIndex() - 1]);
    }
  };

  const handleNext = async (e) => {
    let isValid = false;

    if (currentPage === "SEAT_PICKER") {
      isValid = validateCartNotEmpty(selectedSeats);
    }

    if (currentPage === "SELECT_TICKETS") {
      isValid = validateCartNotEmpty(selectedTickets);
    }

    if (currentPage === "BILLING_INFO") {
      // trigger validation of the entered billing information
      isValid = await methods.trigger("payment.billingInfo");
    }

    if (currentPage === "PAYMENT_SUCCESSFUL") {
      onClose();
    }

    if (isValid) {
      if (currentPage === "SEAT_PICKER" || currentPage === "SELECT_TICKETS") {
        setCurrentPage(pages[getCurrentPageIndex() + 1]);
      }

      if (currentPage === "BILLING_INFO") {
        doSubmitBillingInfo();
      }
    }
  };

  const handlePaymentSuccessful = () => {
    setCurrentPage("PAYMENT_SUCCESSFUL");
  };

  if (isGetEventDataLoading) {
    return (
      <div
        className={clsx(mode === "CUSTOMER" ? styles.modal : styles.container)}
      >
        {mode === "CUSTOMER" && (
          <button className={styles.closeButton} onClick={handleClose}>
            <FaXmark />
          </button>
        )}
        <SkeletonLoader width="100%" height="100%" />
      </div>
    );
  }

  return (
    <FormProvider {...methods}>
      <div
        className={clsx(mode === "CUSTOMER" ? styles.modal : styles.container)}
      >
        {mode === "CUSTOMER" && (
          <button className={styles.closeButton} onClick={handleClose}>
            <FaXmark />
          </button>
        )}

        {currentPage === "SEAT_PICKER" && (
          <SeatPicker
            onSelectSeat={handleSelectSeat}
            onDeselectSeat={handleDeselectSeat}
            mode={mode}
          />
        )}

        {currentPage === "SELECT_TICKETS" && (
          <SelectTickets onUpdateTickets={handleUpdateTickets} />
        )}

        {currentPage === "BILLING_INFO" && <BillingInfoForm mode={mode} />}

        {currentPage === "CARD_DETAILS" && (
          <StripeCheckoutWrapper
            itemsToPurchase={
              eventData?.event?.isSeatingReserved
                ? selectedSeats
                : selectedTickets
            }
            currency={"USD"}
            purchaseType={
              eventData?.event?.isSeatingReserved ? "SEATS" : "TICKETS"
            }
          >
            <StripeCardDetailsForm
              endPaymentProcess={handlePaymentSuccessful}
              itemsToPurchase={
                eventData?.event?.isSeatingReserved
                  ? selectedSeats
                  : selectedTickets
              }
            />
          </StripeCheckoutWrapper>
        )}

        {currentPage === "PAYMENT_SUCCESSFUL" && (
          <PaymentSuccessful
            mode={mode}
            itemsPurchased={
              eventData?.event?.isSeatingReserved
                ? selectedSeats
                : selectedTickets
            }
          />
        )}

        <div className={styles.modalSideContent}>
          <img
            className={styles.modalEventImage}
            src={eventData?.event?.eventImage}
          />

          {currentPage !== "PAYMENT_SUCCESSFUL" && (
            <Cart
              items={
                eventData?.event?.isSeatingReserved
                  ? selectedSeats.map((item) =>
                      parseSeatDataIntoCartItem(item, () =>
                        handleDeleteSeatFromCart(item)
                      )
                    )
                  : selectedTickets.map((ticket) =>
                      parseTicketDataIntoCartItem(ticket)
                    )
              }
              isEventFree={eventData?.event?.isEventFree}
            />
          )}

          <div className={styles.modalSideContentButtonContainer}>
            {currentPage !== "PAYMENT_SUCCESSFUL" && (
              <Button
                variant="transparent"
                size="sm"
                disabled={currentPage === pages[0]}
                onClick={handlePrevious}
              >
                <FaArrowLeft className={styles.backIcon} />
                <span>Back</span>
              </Button>
            )}

            <Button size="sm" onClick={handleNext}>
              {submitBillingInfo.isLoading || releaseSeat.isLoading ? (
                <Loader />
              ) : (
                <span>
                  {currentPage !== "PAYMENT_SUCCESSFUL" && (
                    <>
                      {currentPage !== "PAYMENT_SUCCESSFUL" &&
                      PAYMENT_PAGES.includes(currentPage)
                        ? "Get Tickets"
                        : "Next"}
                    </>
                  )}
                  {currentPage === "PAYMENT_SUCCESSFUL" && "Finish"}
                </span>
              )}
            </Button>
          </div>
        </div>
      </div>
    </FormProvider>
  );
};
