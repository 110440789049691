import React, { useState, useRef, useEffect, useCallback } from 'react';
import { FaXmark } from 'react-icons/fa6';
import ArchivedBack from '../ArchivedBack/ArchivedBack';
import "./index.css";
import SellTicketwarp from '../SellTicketwarp/SellTicketwarp';
import { SeatsioSeatingChart, SeatsioEventManager  } from '@seatsio/seatsio-react';
import { useNavigate } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';

const REACT_APP_STRIPE_PUBLISHABLE_KEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
const stripePromise = loadStripe(REACT_APP_STRIPE_PUBLISHABLE_KEY);


const SellTicketsCom = ({ title = "", showBackButton , eventId, token}) => {

    const navigate = useNavigate();
    const [counts, setCounts] = useState({});
    const [pricing, setPricing] = useState([]);
    const [boxOfficeCategories, setBoxOfficeCategories] = useState([]);
    let base_api_url=`${process.env.REACT_APP_BASE_URL}`;
    const prevElement = useRef();
    const prevLabel = useRef();
    const [loading, setLoading] = useState(true);
    
    const [clientSecret, setClientSecret] = useState('');
    const [intentId, setIntentId] = useState('');
    

    const [mainLevels, setMainLevels] = useState([]);
    const [nrMainLevels, setNrMainLevels] = useState([]);
    const [event, setEvent] = useState([]);
    const [holdToken, setHoldToken] = useState('');
    const [eventImageUrl, setEventImageUrl] = useState('');
    const [venue, setVenue] = useState([]);
    const [org, setOrg] = useState([]);
    const [eventPriceRange, setEventPriceRange] = useState([]);


    const [subTotalCart, setSubTotalCart] = useState(0);
    const [serviceFeeCart, setServiceFeeCart] = useState(0);
    const [P, setP] = useState(0);
    const [G, setG] = useState(0);
    const [taxesCart, setTaxesCart] = useState(0);
    const [totalBillCart, setTotalBillCart] = useState(0);
    const [is_event_free, setIs_event_free] = useState(false);
    const [is_absorb_fee, setIs_absorb_fee] = useState(false);
    const [selectedSeats, setSelectedSeats] = useState([]);
    const [nRSelectedSeats, setNrSelectedSeats] = useState([]);
    const [popup1Class, setPopup1Class] = useState('hide_popup');
    const [popup2Class, setPopup2Class] = useState('hide_popup');
    const [popup3Class, setPopup3Class] = useState('hide_popup');

    //  booking information states
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [address1, setAddress1] = useState('');
    const [isTextTicket, setIsTextTicket] = useState(true);
    const [email, setEmail] = useState('');
    const [city, setCity] = useState('');
    const [country, setCountry] = useState('');
    const [state, setState] = useState('');
    const [postal, setPostal] = useState('');
    const [creditCardNumber, setCreditCardNumber] = useState('');
    const [creditCardExpiry, setCreditCardExpiry] = useState('');
    const [creditCardCVV, setCreditCardCVV] = useState('');
    const [billingNote, setBillingNote] = useState('');
    const [number, setNumber] = useState('');
    const [orderId, setOrderId] = useState('');     
    const [orderPrice, setOrderPrice] = useState(''); 


    function convertTo12HourFormat(time24) {
        // Split the input time string into hours, minutes, and seconds
        if(time24){

            let [hours, minutes] = time24.split(':').map(Number);
    
        // Determine the period (AM/PM) and adjust hours for 12-hour format
        let period = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12 || 12; // Convert 0 to 12 for 12-hour format
    
        // Format the hours and minutes to ensure two-digit representation
        let formattedHours = String(hours).padStart(2, '0');
        let formattedMinutes = String(minutes).padStart(2, '0');
    
        // Return the formatted time string
        return `${formattedHours}:${formattedMinutes} ${period}`;
        }
        else{
            return "";
        }
        
    }


    const stripHtmlTags = (html) => {
        const tempElement = document.createElement('div');
        tempElement.innerHTML = html;
        return tempElement.innerText; // or tempElement.textContent
      };
      const openPopup1 = () =>{


        if (event.is_seating_reserved && selectedSeats <1){
            alert("you have not selected any seat. ")
            return
        }
        if (!event.is_seating_reserved && nRSelectedSeats <1){
            alert("you have not selected any seat. ")
            return
        }

        console.log("in popup opening funcitons ");
        const createPaymentIntent = async (amount, subAmount) => {
            try {
                var User_Id = ""
                const authToken = localStorage.getItem('authToken');

                if (!authToken) {
                console.error('Authentication token not found');
                User_Id = ""
                }
                else{
                    User_Id = localStorage.getItem('authUserId');
                }

                console.log("at 3")
                // Step 1: Call the createcustomer API to create a new customer
                // const customerResponse = await fetch(`${process.env.REACT_APP_BASE_URL}/api/createcustomer/`, {
                //     method: 'POST',
                //     headers: {
                //         'Content-Type': 'application/json',
                //     },
                //     body: JSON.stringify({
                //         email: 'ali@admin.com', // Replace with actual customer email
                //     }),
                // });

                // if (!customerResponse.ok) {
                //     const errorData = await customerResponse.json();
                //     throw new Error(`Failed to create customer: ${errorData.detail}`);
                // }

                // const customerData = await customerResponse.json();
                // const customerID = customerData['Customer ID : ']; 
                console.log(" org is : ", org)
                const api_body = JSON.stringify({
                    amount: totalBillCart,
                    currency: 'USD',
                    user_id: User_Id,
                    sub_bill: subTotalCart,
                    P:P,
                    G:G,
                    taxes:taxesCart,
                    email:email,
                    serviceFee:serviceFeeCart,
                    event_id: (event.id),
                });
                console.log(" api_body ,", api_body);

                const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/create-payment-intent/`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: api_body,
                });
    
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
    
                const data = await response.json();
                if (data.error) {
                    console.error('Error creating payment intent:', data.error);
                    return;
                }
    
                console.log("intent is ");
                console.log(data);
                setClientSecret(data.clientSecret);
                console.log(data);
                setIntentId(data.intent_id);
                console.log("intent is ",(data.intent_id));

            } catch (error) {
                console.error('There was an error!', error);
            }
        };
        
        createPaymentIntent(totalBillCart, subTotalCart);
        
        getHoldToken();
        setPopup1Class('show_popup');
    } ;
    const openPopup2 = () =>{
        setPopup2Class('show_popup');
    } ;
    const openPopup3 = () =>{
        setPopup3Class('show_popup');
    } ;

    const closePopup1 = () =>{
        console.log("in popup opening funcitons ")
        setPopup1Class('hide_popup');
    };
    const closePopup2 = () =>{
        console.log("in popup opening funcitons ")
        setPopup2Class('hide_popup');
        window.location.reload();
    };
    const closePopup3 = () =>{
        console.log("in popup opening funcitons ")
        setPopup3Class('hide_popup');
        window.location.reload();
    };

      const handleFirstNameChange = (event) => {
        setFirstName(event.target.value);
      };
      
      const handleLastNameChange = (event) => {
        setLastName(event.target.value);
      };
      
      const handleAddress1Change = (event) => {
        setAddress1(event.target.value);
      };
      
      const handleEmailChange = (event) => {
        setEmail(event.target.value);
      };
      const handleCityChange = (event) => {
        setCity(event.target.value);
      };
      const handleCountryChange = (event) => {
        setCountry(event.target.value);
      };
      
      const handleStateChange = (event) => {
        setState(event.target.value);
      };
      
      const handlePostalChange = (event) => {
        setPostal(event.target.value);
      };
      
      const handleCreditCardNumberChange = (event) => {
        setCreditCardNumber(event.target.value);
      };
      
      const handleCreditCardExpiryChange = (event) => {
        setCreditCardExpiry(event.target.value);
      };
      
      const handleCreditCardCVVChange = (event) => {
        setCreditCardCVV(event.target.value);
      };
      const handleIsTextTicketChange = (event) => {
        setIsTextTicket(event.target.checked);
      };
      const handleBillingNoteChange = (event) => {
        setBillingNote(event.target.value);
      };
      const handleNumberChange = (event) => {
        setNumber(event.target.value);
      };


      const releaseSeatsObject= async (label) => {
        console.log("this is release event full label is ");
        try{
            const authToken = localStorage.getItem('authToken');

                if (!authToken) {
                console.error('Authentication token not found');
                }

                const requestBody = JSON.stringify({
                    seat_label : label,
                    event_key : event.event_id,
                    hold_token: holdToken
                });
                // Fetch event data
                console.log("check releaseeventseat Before api call : ", holdToken);
                console.log("body is ",requestBody);
                console.log("prevElement.current: ", (prevElement.current))
                let eventResponse=""
                // if (prevElement.current !== label) {
                //     prevElement.current = label;
                
                    console.log("this is label ", label);

                eventResponse = await fetch(`${process.env.REACT_APP_BASE_URL}/api/releaseeventseat/`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Token ${authToken}`
                    },
                    body: requestBody
                });
                // }// if for prevElement check ends here
                // else{
                //     console.log("this is the issue ");
                // }
                console.log("after api call");
                if (!eventResponse.ok) {
                    console.log("eventResponse : ",eventResponse );
                    console.error('Failed to fetch event data');
                }
                else{

                    const eventData = await  eventResponse.json();
                    console.log("eventData api call", eventData);
                    return true;
                }
                
        } catch (error) {
            // Handle network errors or other exceptions
            console.error('Error:', error.message);
        }
    }

    const getHoldToken= async () => {
        console.log("this is release event full label is ");
        try{
            const authToken = localStorage.getItem('authToken');

                if (!authToken) {
                console.error('Authentication token not found');
                }

                
                // Fetch event data
                // Extract venueId from event data
                 // Fetch venue data using the extracted venueId
                 const tokenResponse = await fetch(`${process.env.REACT_APP_BASE_URL}/api/seatioholdtoken/`, {
                     headers: {
                         Authorization: `Token ${authToken}`
                     }
                 });
                 if (!tokenResponse.ok) {
                     console.error('Failed to fetch venue data');
                 }
                 const tokenData = await tokenResponse.json();
                 console.log("hold token is   is", tokenData);
                 console.log("hold token is   is", tokenData.token );
                 setHoldToken(tokenData.token);

        } catch (error) {
            // Handle network errors or other exceptions
            console.error('Error:', error.message);
        }
    }

    const getHoldTokenExtended= async () => {
        try{
            const authToken = localStorage.getItem('authToken');

                if (!authToken) {
                console.error('Authentication token not found');
                }

                
                // Fetch event data
                // Extract venueId from event data
                 // Fetch venue data using the extracted venueId
                 const requestBody = JSON.stringify({
                    token : holdToken,
                });
                 const tokenResponse = await fetch(`${process.env.REACT_APP_BASE_URL}/api/extendholdtoken/`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                         Authorization: `Token ${authToken}`
                     },
                     body:requestBody
                 });
                 if (!tokenResponse.ok) {
                     console.error('Failed to fetch venue data');
                 }
                 const tokenData = await tokenResponse.json();
                 console.log("hold token is extended  is", tokenData);
                 console.log("hold token is   is", tokenData.token );
                //  setHoldToken(tokenData.token);

        } catch (error) {
            // Handle network errors or other exceptions
            console.error('Error:', error.message);
        }
    }

    
    const handlePaymentChange = (event) => {
        setPaymentOption(event.target.value);
    };

    const [paymentOption, setPaymentOption] = useState('Card'); // 'Card' or 'Cash'
    const [count, setCount] = useState(0);

    const decrement = () => {
        if (count > 0) {
            setCount(count - 1);
        }
    };

    const increment = () => {
        setCount(count + 1);
    };

    

    const handleAddMainLevel = useCallback((label, category, sub_category, seat, table, price, full_label ) => {
        
        
        setMainLevels(prevMainLevels => {
            const newMainLevel = { label, seat, table, price, full_label  }; // Correctly construct the new object
            console.log("Updated mainLevels:", [...prevMainLevels, newMainLevel]);
            return [...prevMainLevels, newMainLevel];
        });

        setSelectedSeats(prevSelectedSeats => {
            const newSelectedSeats = { label, category, sub_category,  seat, table, price, full_label };
            console.log("Updated newSelectedSeats:", [...prevSelectedSeats, newSelectedSeats]);
            return [...prevSelectedSeats, newSelectedSeats];
        });

        }, [mainLevels]);
    
        useEffect(() => {
            console.log("MainLevels after update:", mainLevels);
            if((mainLevels.length)<1){
                prevElement.current=""
            }
        }, [mainLevels]);
    
        const handleRemoveMainLevelByLabel  = (full_label, check_release) => {
            console.log("Removing main level  ::", full_label);
            console.log("mainLevels:", mainLevels);
        
            const isLabelFound = mainLevels.some(level => level.full_label === full_label);
        
            // if (isLabelFound) {
                console.log("inside if statement ");
        
                setMainLevels(prevMainLevels => {
                    const updatedMainLevels = prevMainLevels.filter(level => level.full_label !== full_label);
                    console.log("Updated mainLevels:", updatedMainLevels);
                    return updatedMainLevels;
                });
                setSelectedSeats(prevSelectedSeats => {
                    const updatedSelectedSeats = prevSelectedSeats.filter(level => level.full_label !== full_label);
                    console.log("Updated updatedSelectedSeats:", updatedSelectedSeats);
                    return updatedSelectedSeats;
                });
        
                console.log("After updating mainLevels:", mainLevels);
                console.log("Before calling releaseSeatsObject mainLevels :", mainLevels);
                if(check_release)
                { releaseSeatsObject(full_label);
                  prevElement.current = full_label;
                }
            // } else {
            //     console.log("Label not found in mainLevels");
            // }   
        };

        
    const handleAddNrMainLevel = async (index, status, category, sub_category, price, bookedId) => {
        
        console.log("already selected NR seats are ", nRSelectedSeats);
        // check category quantity
        const authToken = localStorage.getItem('authToken');

        if (!authToken) {
        console.error('Authentication token not found');
        }
        
        console.log("body of category quantity check bookedId : ", bookedId)

        const requestBody = JSON.stringify({
            event_id : event.id,
            category_id: bookedId,
            status_check: status
        });
        console.log("body of category quantity check api is : ", requestBody)
    // const eventResponse = await fetch(`${process.env.REACT_APP_BASE_URL}/api/eventofuniquetoken/`, {
        const checkCategoryQuantityResponse = await fetch(`${process.env.REACT_APP_BASE_URL}/api/getcategoryquantity/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: requestBody
        });
        console.log("after category quantity  api call");
        if (!checkCategoryQuantityResponse.ok) {
            console.log("223344: ", checkCategoryQuantityResponse);
            console.error('Failed to fetch check quantity api');
        }
        const checkCategoryQuantityData = await checkCategoryQuantityResponse.json();
        console.log("eventData api call", checkCategoryQuantityData);
        const quantity = (checkCategoryQuantityData.category_quantity);
        console.error('quantity ', quantity);
        console.error('counts[bookedId] ', counts[bookedId]);
        let requiredQuantity=0
        if(counts[bookedId] !== undefined){
             requiredQuantity = counts[bookedId]+1
        }
        else{
            requiredQuantity= 1
        }
        
        console.error('quantity > requiredQuantity ', (quantity > requiredQuantity ));
        console.error('requiredQuantity ', requiredQuantity);

        let is_seat_available=false  
        if (quantity > requiredQuantity || quantity === requiredQuantity){
            is_seat_available=true
        }
        
        if(!is_seat_available){
            alert("Seats quantity exceeds the available quantity limit. ");

            return
        }
        else{

        const existingRow = nrMainLevels.find(row => row.bookedId === bookedId);
      
        if (event.is_event_free){
            price=0
        }
        if (existingRow) {
          // If an existing row is found, update its price and quantity
          setNrMainLevels(prevMainLevels => {
            return prevMainLevels.map(row => {
              if (row.category === category && row.sub_category === sub_category) {
                return {
                  ...row,
                  price: row.price + price,
                  quantity: row.quantity + 1 // Assuming there's a quantity property in the row
                };
              }
              return row;
            });
          });
        if (event.is_event_free){
            price=0
        }
          // Update selectedSeats to reflect the changes
        setNrSelectedSeats(prevSelectedSeats => {
            return prevSelectedSeats.map(seat => {
            if (seat.category === category && seat.sub_category === sub_category) {
                return {
                ...seat,
                price: seat.price + price,
                quantity: seat.quantity + 1 
                // Add any other properties you need to update here
                };
            }
            return seat;
            });
        });
        
        } else {
            
          // If no existing row is found, add a new row
          setNrMainLevels(prevMainLevels => {
            const newMainLevel = { status, category, sub_category, price, quantity: 1, bookedId:bookedId }; // Assuming there's a quantity property
            return [...prevMainLevels, newMainLevel];
          });
            // Update selectedSeats to add the new seat
            
            
            setNrSelectedSeats(prevSelectedSeats => {
            const newSeat = {status, category, sub_category, price, quantity: 1 , bookedId:bookedId };
            return [...prevSelectedSeats, newSeat];
            });
        }

        setCounts((prevCounts) => ({
            ...prevCounts,
            [bookedId]: (prevCounts[bookedId] || 0) + 1, // Increment count for the specified category id
          }));

        }
        // Update selectedSeats if needed
        // Assuming you need to add selected seats here
      };
      
      const handleRemoveNrMainLevelByLabel = (index, price, bookedId) => {
        console.log("Removing main level ::", bookedId);
        console.log("mainLevels:", nrMainLevels);
    
        // Find the row with the specified bookedId
        const targetRow = nrMainLevels.find(row => row.bookedId === bookedId);
    
        if (targetRow) {
            console.log("Row found:", targetRow);
    
            if (targetRow.quantity > 1) {
                // If quantity is greater than 1, decrement the quantity and update the price
                setNrMainLevels(prevMainLevels => {
                    return prevMainLevels.map(row => {
                        if (row.bookedId === bookedId) {
                            return {
                                ...row,
                                quantity: row.quantity - 1,
                                price: row.price - price // Subtract the price
                            };
                        }
                        return row;
                    });
                });
    
                // Update the corresponding seat in selectedSeats
                setNrSelectedSeats(prevSelectedSeats => {
                    return prevSelectedSeats.map(seat => {
                        if (seat.bookedId === bookedId) {
                            return {
                                ...seat,
                                quantity: seat.quantity - 1,
                                price: seat.price - price // Subtract the price
                            };
                        }
                        return seat;
                    });
                });
            } else {
                // If quantity is 1, remove the entire row
                setNrMainLevels(prevMainLevels => prevMainLevels.filter(row => row.bookedId !== bookedId));
    
                // Remove the corresponding seat from selectedSeats
                setNrSelectedSeats(prevSelectedSeats => prevSelectedSeats.filter(seat => seat.bookedId !== bookedId));
            }
            setCounts((prevCounts) => ({
                ...prevCounts,
                [bookedId]: Math.max((prevCounts[bookedId] || 0) - 1, 0), // Decrement count, but ensure it doesn't go below 0
              }));


        } else {
            console.log("Row not found with bookedId:", bookedId);
        }
        
    };
    
        
        const calculateSubtotal = (selectedSeats) => {
            // Logic to calculate subtotal
            if(event.is_event_free){
                // setSubTotalCart(0);
                return 0;
            }
            else{
            let subtotal = 0;
            selectedSeats.forEach(seat => {
              subtotal += seat.price;
            });
            
            // setSubTotalCart(subtotal);
            return parseFloat(subtotal.toFixed(2));
    
           }
          };
        
          // Function to calculate taxes based on subtotal
          const calculateTaxes = (subtotal) => {
            // Logic to calculate taxes
            console.log("check free event status ", (event.is_event_free));
            if(event.is_event_free){
                // setTaxesCart(0);
                return 0;
            }
            else{
                if (event.is_event_tax_enable){
    
                    console.log("in free event else side tax enable: ", (event.event_tax));
                    const taxRate = event.event_tax; // Example tax rate (10%)
                    // setTaxesCart(taxRate);
                    return parseFloat(((subtotal * taxRate)/100).toFixed(2));
                }
                else{
                    console.log("in free event else side tax not  enable: ");
    
                    return 0;
                }
            }
          };
        
          // Function to calculate service fee based on subtotal
          const calculateServiceFee = (subtotal) => {
            // Logic to calculate service fee
            if(event.is_event_free){
                return 0;
            }
            else{
                // console.log("in else service fee subtotal \n", (subtotal));
                // console.log("in else service fee subTotalCart \n", (subTotalCart));
                // console.log("taxesCart \n", (taxesCart));
                
                const temp_P = (((subtotal + taxesCart)*0.0429)+(1.0)).toFixed(2);
                const temp_G = (((subtotal + taxesCart)*0.029)+(0.3)).toFixed(2);
                const P = parseFloat(temp_P);
                const G = parseFloat(temp_G);
                
                // console.log("P \n", (P));
                // console.log("G \n", (G));
                setP(P);
                setG(G);
                if (event.absorb_fee){
                    console.log("if is_absorb_fee serviceFeeRate \n", (P+G));
                    return  0;
    
                }
                else{
                    // console.log(" else is_absorb_feeP \n", P);
                    // console.log("else is_absorb_feeG \n", G);
                    const serviceFeeRate = parseFloat((P+G).toFixed(2));
                    // Example service fee rate (5%)
                    // setTotalBillCart((subTotalCart+P+G));
                    return serviceFeeRate;
                }
            }
          };
        
          useEffect(() => {
            if(selectedSeats.length>0){
    
            
            // Calculate subtotal based on selected seats
            const subtotal = calculateSubtotal(selectedSeats);
            setSubTotalCart(subtotal);
        
            // Calculate taxes based on subtotal
            const calculatedTaxes = calculateTaxes(subtotal);
            setTaxesCart(calculatedTaxes);
        
            // Calculate service fee based on subtotal
            const calculatedServiceFee = calculateServiceFee(subtotal);
            setServiceFeeCart(calculatedServiceFee);
        
            // Calculate total
            const calculatedTotal = subtotal + calculatedTaxes + calculatedServiceFee;
            setTotalBillCart(calculatedTotal);
           }
           else{
            setSubTotalCart(0);
            setTaxesCart(0);
            setServiceFeeCart(0);
            setTotalBillCart(0);
           }
    
          }, [selectedSeats]);

          useEffect(() => {
            if(nRSelectedSeats.length>0){
    
            
            // Calculate subtotal based on selected seats
            const subtotal = calculateSubtotal(nRSelectedSeats);
            setSubTotalCart(subtotal);
        
            // Calculate taxes based on subtotal
            const calculatedTaxes = calculateTaxes(subtotal);
            setTaxesCart(calculatedTaxes);
        
            // Calculate service fee based on subtotal
            const calculatedServiceFee = calculateServiceFee(subtotal);
            setServiceFeeCart(calculatedServiceFee);
        
            // Calculate total
            const calculatedTotal = subtotal + calculatedTaxes + calculatedServiceFee;
            setTotalBillCart(calculatedTotal);
           }
           else{
            setSubTotalCart(0);
            setTaxesCart(0);
            setServiceFeeCart(0);
            setTotalBillCart(0);
           }
    
          }, [nRSelectedSeats]);

          
    useEffect(() => {
        const fetchData = async () => {
            try {
                // base_api_url = "${process.env.REACT_APP_BASE_URL}";
                
                // const token = 'e0d25a4a3fda989bf969bc5971a9e36878ece9f2';
                const authToken = localStorage.getItem('authToken');

                if (!authToken) {
                console.error('Authentication token not found');
                }

                const requestBody = JSON.stringify({
                    unique_token : token,
                });
                // Fetch event data
                console.log("Before event api call and token :", token);
                console.log("url is : ", (`${process.env.REACT_APP_BASE_URL}/api/eventofuniquetoken/`));
                console.log("static url is : ", (`${base_api_url}/api/eventofuniquetoken/`));

                // const eventResponse = await fetch(`${process.env.REACT_APP_BASE_URL}/api/eventofuniquetoken/`, {
                    const eventResponse = await fetch(`${process.env.REACT_APP_BASE_URL}/api/eventofuniquetoken/`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Token ${authToken}`
                    },
                    body: requestBody
                });
                console.log("after api call");
                if (!eventResponse.ok) {
                    console.log("inside error api call: ", eventResponse);
                    console.error('Failed to fetch event data');
                }
                const eventData = await eventResponse.json();
                console.log("eventData api call", eventData);
                setEvent(eventData.event);
                const newEvent = eventData.event;


                // const baseURL = window.location.origin; // Your base URL
                const baseURL = `${base_api_url}`
                let imageURL = newEvent.Event_image;
                console.log("1 : imageURL: ", imageURL);
                if (imageURL.startsWith('/images')) {
                    
                    console.log("2 ");
                    
                    imageURL = `${baseURL}${imageURL}`;
                    console.log("3 updated imageURL : ", imageURL);
                    setEventImageUrl(imageURL);
                }
                else{
                    
                    console.log("in else");
                    setEventImageUrl(eventData.event.Event_image);

                }
                console.log("newEvent ", newEvent);
    
                // Extract venueId from event data
                const venueId = newEvent.Venue_name;
    
                // Fetch venue data using the extracted venueId
                const venueResponse = await fetch(`${process.env.REACT_APP_BASE_URL}/api/venues/${venueId}/`, {
                    headers: {
                        Authorization: `Token ${authToken}`
                    }
                });
                if (!venueResponse.ok) {
                    console.error('Failed to fetch venue data');
                }
                const venueData = await venueResponse.json();
                setVenue(venueData);


                 // Extract venueId from event data
                //  const orgId = eventData.user;
    
                 // Fetch venue data using the extracted venueId
                const eventPriceResponse = await fetch(`${process.env.REACT_APP_BASE_URL}/api/eventpricerange/`, {
                    method: 'POST',  // Assuming you are making a POST request
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Token ${authToken}`
                    },
                    body: JSON.stringify({ event_id: (newEvent.id) })
                 });
                 if (!eventPriceResponse.ok) {
                     console.error('Failed to fetch venue data');
                 }
                 const eventPrice_data = await eventPriceResponse.json();
                 setEventPriceRange(eventPrice_data);


                 // Extract venueId from event data
                 const orgId = newEvent.user;
                 console.log("org id is", orgId);
    
                 // Fetch venue data using the extracted venueId
                 const orgResponse = await fetch(`${process.env.REACT_APP_BASE_URL}/api/users/${orgId}/`, {
                     headers: {
                         Authorization: `Token ${authToken}`
                     }
                 });
                 if (!orgResponse.ok) {

                     console.error('Failed to fetch venue data');
                 }
                 const orgData = await orgResponse.json();
                 console.log("orgData  is", orgData);
                 setOrg(orgData);

                //  *************** child component work
                ///////////////////////////////////////

                
                // fetching pricing 
                const requestBody2 = JSON.stringify({
                    token : (token)
                });
                // fetch event with eventId
                const PricingResponse = await fetch(`${process.env.REACT_APP_BASE_URL}/api/completepricingofevent/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${authToken}`
                },
                body: requestBody2
                });
        
                // Check if the request was successful (status code 2xx)
                if (PricingResponse.ok) {
                    const PricingResponseData = await PricingResponse.json(); // Parse the response JSON
                    console.log('event PricingResponseData successfully:', PricingResponseData); 
                    setPricing(PricingResponseData.pricing); 
                    // Optionally, you can return the response data or perform other actions here
                } else {
                    console.error(`Error: ${PricingResponse.status}`);
                    const pricingResponseBody = await PricingResponse.text();
                    // event.target.submit();
                    console.log('authToken', authToken);
                    console.log('Form submitted with data:', requestBody2);
                    console.error(`Response body: ${pricingResponseBody}`);
                    return;
                }
                // pricing api call ends here

                /////////////////
                // //////////////////
                //////////////////


            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }

        };
    
        // Call the fetchData function
        if (prevElement.current !== token) {
            console.log("inside to fetchdata");
            fetchData();
            getHoldToken();

            prevElement.current = token;
        }
        else{
            console.log("ouside to fetchdata token ", token);            
            console.log("ouside to fetchdata");
        }
    }, [token]); // Include eventId in the dependency array to trigger the effect when it changes
    

    const getTicketFinalButton = async () => {
        setLoading(true)
        let message = ""
        if (event.is_event_free){

        if (
            !firstName ||
            !lastName ||
            !address1 ||
            !email ||
            !city||
            !country ||
            !state ||
            !postal ||
            !number
            
          ) {
            message = message+("\n * fill all required fields first");// At least one field is empty
          } 

        }
        else{
            if (
                !firstName ||
                !lastName ||
                !address1 ||
                !email ||
                !city||
                !country ||
                !state ||
                !postal ||
                !number
                
              ) {
                message = message+("\n * fill all required fields first");// At least one field is empty
              } 
        }

           // Check if email is in proper format
          // Check if email is in proper format
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            message = message+("\n *Email is not in proper format");
            // return ; // Email is not in proper format
        }

        // Check if credit card number has proper length
        // if ( (!event.is_event_free) && creditCardNumber.toString().length !== 16) {
        //     message = message+("\n *Enter correct and complete card number details");
        //     // return ; // Credit card number does not have proper length
        // }

        // Check if CVV is a three-digit number
        // if ((!event.is_event_free) && creditCardCVV.toString().length !== 3 || isNaN(creditCardCVV)) {
        //     message = message+("\n *CVV is not correct, it should be three digit positive number");
        //     // return ; // CVV is not a three-digit number
        // }

        if (message){
            setLoading(false)
            alert(message);
            

        }
        else{
            console.log("in popup opening funcitons ");
            // setPopup15Class('hide_popup');
            
            //pgd
            const response = await bookingAPICall()
            if (response){
                setLoading(false)
                setPopup2Class('show_popup');
                // setPopup16Class('show_popup');
                console.log("in first function :", response)
                return response
            }
            else{
                setLoading(false)
                alert("Booking failed please reload the page!")
            }
        } 
    }


    const bookingAPICall = async () =>{
        // const token = 'e0d25a4a3fda989bf969bc5971a9e36878ece9f2';
        const authToken = localStorage.getItem('authToken');

        if (!authToken) {
        console.error('Authentication token not found');
        }
        const authUserId = localStorage.getItem('authUserId');

        if (!authUserId) {
        console.error('Authentication token not found');
        }

        if(event.is_seating_reserved){
            const requestBody = JSON.stringify({
                "first_name": firstName,
                "last_name": lastName,
                "billing_address_line1": address1,
                "billing_state": state,
                "billing_email": email,
                "billing_zip_code": parseInt(postal),
                "user_id": parseInt(authUserId),
                "event_id": parseInt(event.id),
                "selected_seats": selectedSeats,
                "total_bill": totalBillCart,
                "sub_bill": subTotalCart,
                "taxes": taxesCart,
                "service_fees": serviceFeeCart,
                "P": P,
                "G": G,
                "holdtoken": holdToken,
                "billinginfo_order_note": billingNote,
                "billing_city": city,
                "billing_country": country,
                "phone": parseInt(number),
                "is_text_ticket": (isTextTicket),
                "intent_id": intentId
        });
        console.log("body is :", requestBody)
    // const eventResponse = await fetch(`${process.env.REACT_APP_BASE_URL}/api/eventofuniquetoken/`, {
        const paymentResponse = await fetch(`${process.env.REACT_APP_BASE_URL}/api/reservebooktickets/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${authToken}`
        },
        body: requestBody
         });
         console.log("after api call");
         if (!paymentResponse.ok) {
             console.log("inside error api call: ", paymentResponse);
            //  console.error('Failed to paymentResponse');
            return false
            //  setPopup3Class('show_popup');
         }
         else{
            
            console.log('succcesful to paymentResponse');
            const paymentResponseData = await paymentResponse.json();
            // const user = paymentResponseData.myUser;
            console.log("paymentResponseData: ", paymentResponseData);
            setOrderPrice(parseFloat((paymentResponseData.total_price)/100).toFixed(2));
            setOrderId(paymentResponseData.order);
            // console.log("user: ", paymentResponseData.myUser);
            // setPopup2Class('show_popup');
            return true
         }

        }
        else{
             
            const requestBody = JSON.stringify({
                    "first_name": firstName,
                    "last_name": lastName,
                    "billing_address_line1": address1,
                    "billing_state": state,
                    "billing_email": email,
                    "billing_zip_code": parseInt(postal),
                    "user_id": parseInt(authUserId),
                    "event_id": parseInt(event.id),
                    "selected_seats": nRSelectedSeats,
                    "total_bill": totalBillCart,
                    "sub_bill": subTotalCart,
                    "taxes": taxesCart,
                    "service_fees": serviceFeeCart,
                    "P": P,
                    "G": G,
                    "billinginfo_order_note": billingNote,
                    "billing_city": city,
                    "billing_country": country,
                    "phone": parseInt(number),
                    "is_text_ticket": (isTextTicket),
                    "intent_id": intentId
            });
            console.log("body is :", requestBody)
        // const eventResponse = await fetch(`${process.env.REACT_APP_BASE_URL}/api/eventofuniquetoken/`, {
            const paymentResponse = await fetch(`${process.env.REACT_APP_BASE_URL}/api/booktickets/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${authToken}`
            },
            body: requestBody
             });
             console.log("after api call");
             if (!paymentResponse.ok) {
                 console.log("inside error api call: ", paymentResponse);
                //  console.error('Failed to paymentResponse');
                // setPopup3Class('show_popup');
                return false
             }
             else{
                
                console.log('succcesful to paymentResponse');
                const paymentResponseData = await paymentResponse.json();
                // const user = paymentResponseData.myUser;
                console.log("paymentResponseData: ", paymentResponseData);
                setOrderPrice(parseFloat((paymentResponseData.total_price)/100).toFixed(2));
                setOrderId(paymentResponseData.order);
                // console.log("details: ", paymentResponseData.details);
                // setOrderId(paymentResponseData.order);
                // setPopup2Class('show_popup');
                return true

             }

        }

    }
    
   


    return (
        
        <div className="conatiner_warp">
            {loading && 
            <div id="preloader" className="preloader">
                <div className="loader"></div>
                </div>
            }
            {showBackButton && <ArchivedBack />}
            
            <div className="main_sell_ticket_section">
                {event &&(
                <div className="event_sell_info">
                    <h1>{event.Event_Name}</h1>
                    <p>{event.start_date} - {event.end_date}</p>
                    <p>{convertTo12HourFormat(event.start_time)}</p>
                    {!(event.is_seating_reserved) ? (
                    <SellTicketwarp eventId={event.id} counts={counts} setCounts={setCounts} handleRemoveNrMainLevelByLabel={handleRemoveNrMainLevelByLabel} handleAddNrMainLevel={handleAddNrMainLevel} status={"sellTickets"}/>
                ) : (
                    <SeatsioSeatingChart 
                    workspaceKey={process.env.REACT_APP_SEATS_IO_WORKSPACE_KEY}
                    event={event.event_id}
                    pricing={pricing}
                    showFullScreenButton= {true}
                    showSectionPricingOverlay = {true}
                    session='manual'
                    holdToken={holdToken} 
                    onObjectSelected=  {  function (object)
                            {
                            console.log("object is ",object );
                            console.log("inside deselect ", mainLevels);
                            let ticketTypes=""
                            if (object.pricing?.ticketTypes !== undefined) {
                                ticketTypes = object.pricing.ticketTypes;
                            }
                            console.log("object is ",object );
                            let seat_sub_category = ''
                            let seat_category = ''
                            let seat_label=''
                            let seat_type=''
                            let price=0
                            seat_type = object.objectType;
                            console.log("seat type is ", seat_type);
                            
                            seat_label=object.label;
                            const [table, seatNumber] = seat_label ? seat_label.split('-') : ["", ""];
                            seat_category=object.category.label;

                                if (seat_type.includes("Seat"))
                                {
                                    if (ticketTypes) {
                                        seat_sub_category=object.selectedTicketType;

                                        for (const ticketVar of ticketTypes) {
                                            if (seat_sub_category.includes(ticketVar.ticketType)) {
                                                price= ticketVar.price
                                            }
                                        }
                                    }
                                    else {
                                        if (object.pricing?.price !== undefined) {
                                            price = object.pricing.price;
                                        }
                                    }
                                }
                                else{
                                    price = object.pricing.price;
                                }
                            const label = seat_sub_category ? seat_sub_category : seat_category;

                            handleAddMainLevel(label,  seat_category, seat_sub_category, seatNumber, table, price, seat_label);

                            }
                        }

                        onObjectDeselected={  function (object)
                            {
                                // alert("it is in deselect")
                                console.log("inside deselect ", mainLevels);
                                handleRemoveMainLevelByLabel(object.label, false) ;
                            }
                        } 
                        region="na"
                    />
                )}

                </div>

                )}
                <div className="order_summary ">
                    <h2>Order Summary</h2>
                    {/* <div className="order_sum_text">

                        <div className="order_summary_data">
                            <p className="no">x1</p>
                            <div className="order_tinfo">
                                <p className="type_name">Ticket Type Name </p>
                                <p className="type_id">Seat ID or Table ID or Both</p>
                            </div>
                            <div className="order_pinfo">$25.00</div>
                            <div className="order_trash">
                                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15"
                                    viewBox="0 0 15 15" fill="none">
                                    <path
                                        d="M3.04688 15H11.9531V2.34375H12.6562V1.875H9.375V0H5.625V1.875H2.34375V2.34375H3.04688V15ZM6.09375 0.46875H8.90625V1.875H6.09375V0.46875ZM11.4844 2.34375V14.5312H3.51562V2.34375H11.4844Z"
                                        fill="#FF0000" />
                                    <path d="M6.32812 4.6875H5.85938V12.6562H6.32812V4.6875Z"
                                        fill="#FF0000" />
                                    <path d="M9.14062 4.6875H8.67188V12.6562H9.14062V4.6875Z"
                                        fill="#FF0000" />
                                </svg>
                            </div>
                        </div>
                        <div className="order_summary_data">
                            <p className="no">x1</p>
                            <div className="order_tinfo">
                                <p className="type_name">Ticket Type Name </p>
                                <p className="type_id">Seat ID or Table ID or Both</p>
                            </div>
                            <div className="order_pinfo">$25.00</div>
                            <div className="order_trash">
                                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15"
                                    viewBox="0 0 15 15" fill="none">
                                    <path
                                        d="M3.04688 15H11.9531V2.34375H12.6562V1.875H9.375V0H5.625V1.875H2.34375V2.34375H3.04688V15ZM6.09375 0.46875H8.90625V1.875H6.09375V0.46875ZM11.4844 2.34375V14.5312H3.51562V2.34375H11.4844Z"
                                        fill="#FF0000" />
                                    <path d="M6.32812 4.6875H5.85938V12.6562H6.32812V4.6875Z"
                                        fill="#FF0000" />
                                    <path d="M9.14062 4.6875H8.67188V12.6562H9.14062V4.6875Z"
                                        fill="#FF0000" />
                                </svg>
                            </div>
                        </div>
                    </div> */}

                    {event.is_seating_reserved ? (
                        <div className="order_sum_text">
                        {mainLevels.map( (mainLev, index) => {
                            return (
                                <div key={index} className="order_summary_data">
                                    <p className="no">x1</p>
                                    <div className="order_tinfo">
                                        <p className="type_name">Ticket Type Name: {mainLev.label} </p>
                                        <p className="type_id">Seat ID: {mainLev.seat} and  Table ID: {mainLev.table}</p>
                                    </div>
                                    <div className="order_pinfo">${mainLev.price}</div>
                                    <div className="order_trash" onClick={() => handleRemoveMainLevelByLabel(mainLev.full_label, true)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                                            <path d="M3.04688 15H11.9531V2.34375H12.6562V1.875H9.375V0H5.625V1.875H2.34375V2.34375H3.04688V15ZM6.09375 0.46875H8.90625V1.875H6.09375V0.46875ZM11.4844 2.34375V14.5312H3.51562V2.34375H11.4844Z" fill="#FF0000" />
                                            <path d="M6.32812 4.6875H5.85938V12.6562H6.32812V4.6875Z" fill="#FF0000" />
                                            <path d="M9.14062 4.6875H8.67188V12.6562H9.14062V4.6875Z" fill="#FF0000" />
                                        </svg>
                                    </div>
                                </div>
                            );
                        })}
                        </div>
                    ):(
                        <div className="order_sum_text">
                        {nrMainLevels.map( (mainLev, index) => {
                            return (
                                <div key={index} className="order_summary_data">
                                    <p className="no">x{mainLev.quantity}</p>
                                    <div className="order_tinfo">

                                    <p className="type_name">
                                        Ticket Type Name: {mainLev.status ? mainLev.status : mainLev.sub_category} {mainLev.category}
                                    </p>                                                                 
                                    </div>
                                    <div className="order_pinfo">${mainLev.price}</div>
                                    {/* <div className="order_trash" disabled onClick={() => handleRemoveNrMainLevelByLabel(index, mainLev.price, mainLev.bookedId)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                                            <path d="M3.04688 15H11.9531V2.34375H12.6562V1.875H9.375V0H5.625V1.875H2.34375V2.34375H3.04688V15ZM6.09375 0.46875H8.90625V1.875H6.09375V0.46875ZM11.4844 2.34375V14.5312H3.51562V2.34375H11.4844Z" fill="#FF0000" />
                                            <path d="M6.32812 4.6875H5.85938V12.6562H6.32812V4.6875Z" fill="#FF0000" />
                                            <path d="M9.14062 4.6875H8.67188V12.6562H9.14062V4.6875Z" fill="#FF0000" />
                                        </svg>
                                    </div> */}
                                </div>
                            );
                        })}
                        </div>
                    )}
                    <div className="order_main_list">
                        <div className="order_list_items">
                            <div className="order_list_item">
                                <p>Subtotal</p>
                                <p>${subTotalCart}</p>
                            </div>
                            <div className="order_list_item">
                                <p>Taxes</p>
                                <p>${taxesCart}</p>
                            </div>
                            <div className="order_list_item">
                                <p>Service Fees</p>
                                <p>${serviceFeeCart}</p>
                            </div>
                            <div className="order_list_item">
                                <p>Total</p>
                                <p>${totalBillCart}</p>
                            </div>
                        </div>




                    </div>
                    <div className="box">
                        <button className="btn main_sell_btn"  onClick={openPopup1}>Checkout</button>
                    </div>
                    <div id="popup1" className={`overlay_14 ${popup1Class}`}>
                        <div className="popup">
                            <div className="info_form_section">
                                <h2>Enter Payment Information and Confirm Purchase</h2>
                                <h3>Event Title</h3>
                                <button className="jclose" onClick={closePopup1}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25"
                                        viewBox="0 0 25 25" fill="none">
                                        <g clip-path="url(#clip0_517_27)">
                                            <path
                                                d="M12.5 0.703125C5.97656 0.703125 0.703125 5.97656 0.703125 12.5C0.703125 19.0234 5.97656 24.3359 12.5 24.3359C19.0234 24.3359 24.3359 19.0234 24.3359 12.5C24.3359 5.97656 19.0234 0.703125 12.5 0.703125ZM12.5 22.5781C6.95313 22.5781 2.46094 18.0469 2.46094 12.5C2.46094 6.95313 6.95313 2.46094 12.5 2.46094C18.0469 2.46094 22.5781 6.99219 22.5781 12.5391C22.5781 18.0469 18.0469 22.5781 12.5 22.5781Z"
                                                fill="#FAE100" />
                                            <path
                                                d="M16.0937 8.86719C15.7422 8.51562 15.1953 8.51562 14.8438 8.86719L12.5 11.25L10.1172 8.86719C9.76562 8.51562 9.21875 8.51562 8.86719 8.86719C8.51562 9.21875 8.51562 9.76562 8.86719 10.1172L11.25 12.5L8.86719 14.8828C8.51562 15.2344 8.51562 15.7812 8.86719 16.1328C9.02344 16.2891 9.25781 16.4062 9.49219 16.4062C9.72656 16.4062 9.96094 16.3281 10.1172 16.1328L12.5 13.75L14.8828 16.1328C15.0391 16.2891 15.2734 16.4062 15.5078 16.4062C15.7422 16.4062 15.9766 16.3281 16.1328 16.1328C16.4844 15.7812 16.4844 15.2344 16.1328 14.8828L13.75 12.5L16.1328 10.1172C16.4453 9.76562 16.4453 9.21875 16.0937 8.86719Z"
                                                fill="#FAE100" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_517_27">
                                                <rect width="25" height="25" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </button>
                                <div className="sell_form_section">
                                    <div className="sell_forma">
                                        <div className="row_form">
                                            <label htmlFor="">First Name *</label>
                                            <input type="text" value={firstName} onChange={handleFirstNameChange} placeholder="First Name " />
                                        </div>
                                        <div className="row_form">
                                            <label htmlFor="">Last Name *</label>
                                            <input type="text" value={lastName} onChange={handleLastNameChange} placeholder="Last Name" />
                                        </div>
                                    </div>
                                    <div className="sell_forma">
                                        <div className="row_form">
                                            <label htmlFor="">Email</label>
                                            <input type="email" value={email} onChange={handleEmailChange} placeholder="Email" />
                                        </div>
                                        <div className="row_form">
                                            <div className="subrow_form">
                                                <div>

                                                    <label htmlFor="">Cell phone</label>
                                                </div>
                                                <div className="flex">
                                                    <input type="checkbox" checked={isTextTicket} onChange={handleIsTextTicketChange} />
                                                    <label htmlFor="">Text Tickets</label>
                                                </div>


                                            </div>
                                            <input type="number" value={number} onChange={handleNumberChange} placeholder="Number" />
                                        </div>
                                    </div>
                                    <div className="billing_info">
                                        <label htmlFor="">Billing Address</label>
                                        <input type="text" placeholder="Billing Address"
                                        value={address1} onChange={handleAddress1Change}
                                            className="billing_info" />

                                    </div>
                                    <div className="sell_forma">
                                        <div className="row_form">
                                            <label htmlFor="">City</label>
                                            <input type="text" value={city} onChange={handleCityChange} placeholder="City" />
                                        </div>
                                        <div className="row_form">
                                            <label htmlFor="">Country</label>
                                            <input type="text" value={country} onChange={handleCountryChange} placeholder="Country" />
                                        </div>
                                    </div>
                                    <div className="sell_forma">
                                        <div className="row_form">
                                            <label htmlFor="">Billing State</label>
                                            <input type="text" value={state} onChange={handleStateChange} placeholder="Billing State" />
                                        </div>
                                        <div className="row_form">
                                            <label htmlFor="">Postal Code</label>
                                            <input type="number" value={postal} onChange={handlePostalChange} placeholder="Postal Code" />
                                        </div>
                                    </div>
                                    {/* <div className="sell_formr">
                                        <label htmlFor="">Payment Options</label>
                                        <div>


                                            <input
                                                type="radio"
                                                id="showRadio"
                                                name="form"
                                                value="Card"
                                                checked={paymentOption === 'Card'}
                                                onChange={handlePaymentChange}
                                            />
                                            <label htmlFor="Card">Credit/Debit Card</label>
                                        </div>

                                        <div>
                                            <input
                                                type="radio"
                                                id="hideRadio"
                                                name="form"
                                                value="Cash"
                                                checked={paymentOption === 'Cash'}
                                                onChange={handlePaymentChange}
                                            />
                                            <label htmlFor="Cash">Cash</label>
                                        </div>
                                    </div> */}
                                    <br></br>
                                    <div className={`sell_forma_stripe ${paymentOption === 'Card' ? '' : 'hidden'}`} id="sell_fos2">
                                        {/* <div className="row_form">
                                            <input type="number" value={creditCardNumber} onChange={handleCreditCardNumberChange}
                                             placeholder="Card Number" className="cnumber" />
                                        </div>
                                        <div className="row_form">
                                            <input type="date" value={creditCardExpiry} onChange={handleCreditCardExpiryChange} 
                                             placeholder="MM/YYYY" className="MMnumber" />
                                        </div>
                                        <div className="row_form">
                                            <input type="text" value={creditCardCVV} onChange={handleCreditCardCVVChange} placeholder="CVV" className="cvvnumber" />
                                        </div> */}
                                        {clientSecret && (
                                                        <Elements stripe={stripePromise} options={{ clientSecret }}>
                                                            <CheckoutForm getTicketFinalButton={getTicketFinalButton}/>
                                                        </Elements>
                                                           )}
                                    </div>
                                    <div className="sell_formr">
                                        <label htmlFor="">Order Note</label>
                                        <textarea name="" value={billingNote} onChange={handleBillingNoteChange} placeholder="Your order note will appear on your attendee list and order history" id="" ></textarea>
                                    </div>

                                </div>


                            </div>

                            <div className="info_order_section ">
                                <h2>Order Summary</h2>
                                {event.is_seating_reserved ? (
                                <div className="order_sum_text">
                                {mainLevels.map( (mainLev, index) => {
                                    return (
                                        <div key={index} className="order_summary_data">
                                        <p className="no">x1</p>
                                        <div className="order_tinfo">
                                            <p className="type_name">Ticket Type Name: {mainLev.label} </p>
                                            <p className="type_id">Seat ID: {mainLev.seat} and  Table ID: {mainLev.table}</p>
                                        </div>
                                        <div className="order_pinfo">${mainLev.price}</div>
                                        {/* <div className="order_trash" onClick={() => handleRemoveMainLevelByLabel(mainLev.full_label)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                                                <path d="M3.04688 15H11.9531V2.34375H12.6562V1.875H9.375V0H5.625V1.875H2.34375V2.34375H3.04688V15ZM6.09375 0.46875H8.90625V1.875H6.09375V0.46875ZM11.4844 2.34375V14.5312H3.51562V2.34375H11.4844Z" fill="#FF0000" />
                                                <path d="M6.32812 4.6875H5.85938V12.6562H6.32812V4.6875Z" fill="#FF0000" />
                                                <path d="M9.14062 4.6875H8.67188V12.6562H9.14062V4.6875Z" fill="#FF0000" />
                                            </svg>
                                        </div> */}
                                    </div>
                                    );
                                })}
                                </div>
                                ):(
                                    <div className="order_sum_text">
                                    {nrMainLevels.map( (mainLev, index) => {
                                        return (
                                            <div key={index} className="order_summary_data">
                                    <p className="no">x{mainLev.quantity}</p>
                                    <div className="order_tinfo">

                                    <p className="type_name">
                                        Ticket Type Name: {mainLev.status ? mainLev.status : mainLev.sub_category} {mainLev.category}
                                    </p>                                                                 
                                    </div>
                                    <div className="order_pinfo">${mainLev.price}</div>
                                    {/* <div className="order_trash" disabled onClick={() => handleRemoveNrMainLevelByLabel(index, mainLev.price, mainLev.bookedId)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                                            <path d="M3.04688 15H11.9531V2.34375H12.6562V1.875H9.375V0H5.625V1.875H2.34375V2.34375H3.04688V15ZM6.09375 0.46875H8.90625V1.875H6.09375V0.46875ZM11.4844 2.34375V14.5312H3.51562V2.34375H11.4844Z" fill="#FF0000" />
                                            <path d="M6.32812 4.6875H5.85938V12.6562H6.32812V4.6875Z" fill="#FF0000" />
                                            <path d="M9.14062 4.6875H8.67188V12.6562H9.14062V4.6875Z" fill="#FF0000" />
                                        </svg>
                                    </div> */}
                                </div>
                                    );
                                })}

                                </div>
                                )}
                                <div className="order_main_list">
                                    <div className="order_list_items">
                                        <div className="order_list_item">
                                            <p>Subtotal</p>
                                            <p>${subTotalCart}</p>
                                        </div>
                                        <div className="order_list_item">
                                            <p>Taxes</p>
                                            <p>${taxesCart}</p>
                                        </div>
                                        <div className="order_list_item">
                                            <p>Service Fees</p>
                                            <p>${serviceFeeCart}</p>
                                        </div>
                                        <div className="order_list_item">
                                            <p>Total</p>
                                            <p>${totalBillCart}</p>
                                        </div>
                                        <a className="btn main_sell_btn" href="">Apply Manual Discount</a>
                                    </div>
                                </div>
                                <div className="box box2">
                                    {/* <button className="btn main_sell_btn" >Buy Now</button> */}
                                </div>

                            </div>
                        </div>
                    </div>
                    <div id="popup2" className={`overlay_14 ${popup2Class}`}>

                        <div className="popupmain">
                            <div className="order_done">
                                <button onClick={closePopup2} className='order_done_i curs'>
                                    <FaXmark className="" />

                                </button>

                                <p>Order successful!</p>
                                <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64"
                                    viewBox="0 0 64 64" fill="none">
                                    <path
                                        d="M60.5998 13.4C59.6998 12.5 58.2998 12.5 57.3998 13.4L23.3998 46.4L6.59977 29.9C5.69977 29 4.29977 29.1 3.39977 29.9C2.49977 30.8 2.59977 32.2 3.39977 33.1L21.0998 50.3C21.6998 50.9 22.4998 51.2 23.3998 51.2C24.2998 51.2 24.9998 50.9 25.6998 50.3L60.5998 16.4C61.4998 15.7 61.4998 14.3 60.5998 13.4Z"
                                        fill="#52FF00" />
                                </svg>
                                
                                <p>Redirecting to Orders...</p>
                            </div>
                        </div>
                    </div>


                    <div id="popup3" className={`overlay_14 ${popup3Class}`}>
                        <div className="popupmain">
                            <div className="order_done">
                                <p>Order was not processed.</p>
                                {/* <svg onClick={closePopup3} xmlns="http://www.w3.org/2000/svg" width="58" height="58"
                                    viewBox="0 0 58 58" fill="none">
                                    <path
                                        d="M32.1996 29L56.5996 4.59998C57.4996 3.69998 57.4996 2.29998 56.5996 1.39998C55.6996 0.499976 54.2996 0.499976 53.3996 1.39998L28.9996 25.8L4.59961 1.39998C3.69961 0.499976 2.29961 0.499976 1.39961 1.39998C0.499609 2.29998 0.499609 3.69998 1.39961 4.59998L25.7996 29L1.39961 53.4C0.499609 54.3 0.499609 55.7 1.39961 56.6C1.79961 57 2.39961 57.3 2.99961 57.3C3.59961 57.3 4.19961 57.1 4.59961 56.6L28.9996 32.2L53.3996 56.6C53.7996 57 54.3996 57.3 54.9996 57.3C55.5996 57.3 56.1996 57.1 56.5996 56.6C57.4996 55.7 57.4996 54.3 56.5996 53.4L32.1996 29Z"
                                        fill="#FF0000" />
                                </svg> */}
                                <button onClick={closePopup3} className='order_done_i'>
                                    <FaXmark className="" />

                                </button>
                                <p>Invalid card details</p>
                            </div>
                        </div>
                    </div>
                </div>
                

            </div>
        </div>
    )
}

// Define your CheckoutForm component
const CheckoutForm = ({ getTicketFinalButton }) => {
    const REACT_APP_STRIPE_PUBLISHABLE_KEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY

    const stripe = useStripe(REACT_APP_STRIPE_PUBLISHABLE_KEY);
    const elements = useElements();

    const handleSubmit = async (event) => {
        event.preventDefault();

        const response = await getTicketFinalButton();
        console.log(" response : ", response)
        if (!response){
            alert("failed to book reserve tickets, pleaser book tickets again.")
            return
        }
        if (!stripe || !elements) {
            return;
        }

        const result = await stripe.confirmPayment({
            elements,
            confirmParams: {},
            redirect: "if_required"
        });
        
        if (result.error) {
            console.error(result.error.message);
            // Handle error, e.g., display error message to user
        } else {
            console.log('Payment successful!');
            // Handle successful payment confirmation, e.g., show a success message
            // Optionally, you can reload the page here if needed
            window.location.reload();
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <PaymentElement />
            {/* <button type="submit" style={{ marginTop: '20px', width: '100%', padding: '10px', fontSize: '18px', backgroundColor: '#000', color: '#FFD700', border: 'none', borderRadius: '5px', cursor: 'pointer', textTransform: 'uppercase' }}> */}
            <button type="submit" className='btn'  style={{ marginTop: '20px', width: '100%', padding: '10px', fontSize: '18px' }}>Pay</button>
        </form>
    );
};

export default SellTicketsCom
