import { useState } from "react";
import styles from "./combobox.module.css";

const ComboboxOption = ({ data, onClick }) => {
  return (
    <div className={styles.option} onClick={onClick}>
      <span>{data?.text}</span>
    </div>
  );
};

export const Combobox = ({
  onChange,
  onSelectOption = () => {},
  options = [],
  defaultValue,
  placeholder,
}) => {
  const [value, setValue] = useState(defaultValue || "");
  const [showOptions, setShowOptions] = useState(options?.length > 0);
  console.log("Options are: ", options);

  const handleChange = (event) => {
    setValue(event?.target?.value);
    onChange(event);
  };

  const handleSelectOption = (option) => {
    console.log(option);
    setValue(option?.text);
    onSelectOption(option);
    setShowOptions(false);
  };

  return (
    <div className={styles.container}>
      <div className={styles.inputContainer}>
        <input
          className={styles.input}
          onFocus={() => setShowOptions(true)}
          value={value}
          type="text"
          onChange={handleChange}
          placeholder={placeholder}
        />
      </div>
      {options && options?.length > 0 && showOptions && (
        <div className={styles.optionsContainer}>
          {options?.map((option) => (
            <ComboboxOption
              data={option}
              onClick={() => handleSelectOption(option)}
            />
          ))}
        </div>
      )}
    </div>
  );
};
