import React, {useState, useEffect} from 'react';
import { FaSearch, FaCheck } from 'react-icons/fa';
import ArchivedBack from '../ArchivedBack/ArchivedBack';
import { saveAs } from 'file-saver';


const AttendeesCom = ({ title = "", showBackButton, bookings_data="", setBookings="", fetchEventData , unique_token}) => {

    const markBookingAsAdmitted = async (bookingId, status) => {
        console.log("booking id for admit is ", bookingId)
        const authToken = localStorage.getItem('authToken');
        if (!authToken) {
            console.error('Authentication token not found');
        }
        // Define the PATCH request options
        const requestBody = JSON.stringify({
            is_admitted : !status,
    });
        const bookingResponse = await fetch(`${process.env.REACT_APP_BASE_URL}/api/bookings/${bookingId}/`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${authToken}`
            },
            body:requestBody
            });
    
            // Check if the request was successful (status code 2xx)
            if (bookingResponse.ok) {
                const bookingResponseData = await bookingResponse.json(); // Parse the response JSON
                // setBookings(bookingResponseData.bookings);
                console.log("afte changing admitting   is ", (bookingResponseData));
                // const updatedBooking = await bookingResponseData.json();

                fetchEventData();
    
                // Optionally, you can return the response data or perform other actions here
            } else {
                console.log("bookings retrieving error ", bookingResponse.status)
            }   

      };
    
    const [csvData, setCsvData] = useState('');
    const [filteredBookings, setFilteredBookings] = useState([]);
    const [searchInput, setSearchInput] = useState(''); 
    

    

    const handleInputChange = (e) => {
        // Update the search input value state
        setSearchInput(e.target.value);
        // Call the function to update the search query state in the parent component
    };

    useEffect(() => {
        // Function to filter venues based on search query
        const doFilterBookings = async (query) => {
            console.log("query : ", query);
            console.log("bookings_data : ", bookings_data);
            const filtered_Bookings = bookings_data.filter((booking) => {
            
                const lowerCaseQuery = query.toLowerCase();

                const includesFirstName = booking.billinginfo_ID.first_name.toLowerCase().includes(lowerCaseQuery);
                const includesLastName = booking.billinginfo_ID.last_name.toLowerCase().includes(lowerCaseQuery);
                const includesSeat_number = booking.seat_number.toString().includes(query);
                const includesSeat_table = booking.seat_table.toString().includes(query);
                const includesId = booking.id.toString().includes(query);
                console.log("for price :",booking.total_price.toString().includes(query));
                if(includesFirstName || includesLastName || includesSeat_number ||includesSeat_table || includesId){
                    return true
                }
                else{
                    return false
                }
            });
            console.log("filteredBookings:", filtered_Bookings);
            console.log("Filered bookings old:", filteredBookings);
            // Update the filteredVenuesList state with the filtered venues
            setFilteredBookings(filtered_Bookings);
        };
    
        if(filteredBookings!==null && (searchInput!== null && searchInput!=='')  ){
            console.log("inside if");
            
            doFilterBookings(searchInput);
        }
        else{
            console.log("inside else");
            const setBookings = async () =>{
                console.log("bookings are in attendees are ", bookings_data)
                setFilteredBookings(bookings_data);
            }
            setBookings();
        }
    }, [searchInput]);

    useEffect(() => {
        const setBookings = async () =>{
            console.log("bookings are in attendees are ", bookings_data)
            setFilteredBookings(bookings_data);
        }
        setBookings();
    }, [bookings_data]);

    const convertToCsv = () => {
        // Get table headers
        const header = Array.from(document.querySelectorAll('.manage_filter_tables .thead .th'))
          .map(th => th.innerText)
          .join(',') + '\n';
        
        // Get table rows
        const rows = Array.from(document.querySelectorAll('.manage_filter_tables .filter_tables_body .body_tr'))
          .map(tr =>
            Array.from(tr.children)
              .map(td => td.innerText)
              .join(',')
          ).join('\n');
        
        const csv = header + rows;
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
        const csvData = URL.createObjectURL(blob);
        setCsvData(csvData);
        saveAs(blob, 'table_data.csv');
      };
      
      
    return (
        <div className="manage_order_area">
            {showBackButton && <ArchivedBack unique_token={unique_token} />}

            <div className="manage_order_section">
                {title && <h1>{title} : Attendees</h1>}
                <form action="" className="manage_order_form">
                    <div className="manage_filter_row">
                        <div className="manage_filter_in">
                            <input type="search"
                            value={searchInput} 
                            onChange={handleInputChange} 
                            placeholder="Search by name, order #, or amount..." />
                            <FaSearch style={{ color: '#000' }} />
                        </div>
                    </div>
                    <button type='button' className="manage_filter_btn cursor_pointer" onClick={convertToCsv}>Export CSV</button>
                </form>
            </div>

            <div className="manage_filter_table">
                <table className="manage_filter_tables">
                    {/* <thead>
                        <tr>
                            <th>Mark Admitted</th>
                            <th>Name</th>
                            <th>Section</th>
                            <th>Seat ID</th>
                            <th>Table ID</th>
                            <th>Order #</th>
                            <th>Order Note</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        Array.isArray(filteredBookings) && filteredBookings.map((booking, index) => (
                            <tr key={index}  
                            onClick={() => {
                                console.log('is_admitted:', booking.is_admitted); 
                                markBookingAsAdmitted(booking.id, booking.is_admitted);
                              }}>
                            <td>
                            <FaCheck style={{ color: "green", display: booking.is_admitted ? 'block' : 'none' }}/></td>
                            <td>{booking.billinginfo_ID.first_name} {booking.billinginfo_ID.last_name}</td>
                            <td>{booking.seat_section}</td>
                            <td>{booking.seat_number}</td>
                            <td>{booking.seat_table}</td>
                            <td># {booking.id}</td>
                            <td>{booking.billinginfo_ID.billinginfo_order_note}</td>
                            </tr>
                        ))
                    }
                    </tbody> */}
                    <div className='thead'>
                        <div className='manage_filter_tables_head'>

                            <div className='head_it th'>Mark Admitted</div>
                            <div className='head_it th'>Name</div>
                            <div className='head_it th'>Section</div>
                            <div className='head_it th'>Seat ID</div>
                            <div className='head_it th'>Table ID</div>
                            <div className='head_it th'>Order #</div>
                        </div>
                    </div>
                    <div className='filter_tables_body'>
                        {Array.isArray(filteredBookings) && filteredBookings.map((booking, index) => (
                            <div className={'body_tr' + (index === 0 ? ' first_ro' : '')} key={index} onClick={() => markBookingAsAdmitted(booking.id, booking.is_admitted)}>
                                <div className='head_it  tr'>
                                    <div className='green_check'>
                                        <FaCheck style={{ color: "green", display: booking.is_admitted ? 'block' : 'none' }} />
                                    </div>
                                </div>
                                <div className='head_it tr'>{booking.billinginfo_ID.first_name} {booking.billinginfo_ID.last_name}</div>
                                <div className='head_it tr'>{booking.seat_section}</div>
                                <div className='head_it tr'>{booking.seat_number}</div>
                                <div className='head_it tr'>{booking.seat_table}</div>
                                <div className='head_it tr'># {booking.id}</div>
                            </div>
                        ))}
                    </div>
                </table>
            </div>
        </div>
    );
};

export default AttendeesCom;
