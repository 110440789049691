import { axiosService } from "@/api/axiosConfig";
import { useMutation, useQuery } from "react-query";

const markTicketAsAdmitted = async (data) => {
  const response = await axiosService.post("tickets/mark-ticket-as-admitted/", {
    ticketId: data?.ticketId,
    eventId: data?.eventId,
  });
  return response.data;
};

export const useMarkTicketAsAdmitted = (data, enabled = true, options = {}) => {
  return useMutation({
    mutationKey: ["markTicketAsAdmitted", JSON.stringify(data)],
    mutationFn: () => markTicketAsAdmitted(data),
    onError: (error) => {
      console.log(error);
    },
    enabled: enabled,
    ...options,
  });
};
