import React, { useEffect, useState, useRef } from "react";
import { FaSearch } from "react-icons/fa";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import ArchivedBack from "../ArchivedBack/ArchivedBack";
import { useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";
import CryptoJS from "crypto-js";
import { useGetEventFromId } from "../../api/hooks/events/useGetEventFromId";
import { useGetEventBookings } from "./hooks/useGetEventBookings";
import {
  formatDate as formatDatePrevious,
  convertTo12HourFormat,
} from "../../utils/dateTimeHelpers";
import { useFullCustomerRefund } from "./hooks/useFullCustomerRefund";
import { generateViewTicketURL } from "@/utils/generateViewTicketURL";
const ManageOrdersCom = ({
  title = "",
  label = "",
  href = "",
  showBackButton,
  eventId,
}) => {
  const navigate = useNavigate();
  const [event, setEvent] = useState(true);
  const [bookings, setBookings] = useState(null);
  const [bookingIdForEmail, setBookingIdForEmail] = useState(undefined);
  const [bookingIndexForEmail, setBookingIndexForEmail] = useState(undefined);
  const [filteredBookings, setFilteredBookings] = useState([]);
  const [searchquery, setSearchquery] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const uniqueIntentIds = useRef({});
  const renderedIntentIds = new Set();
  const [openPopups5, setOpenPopups5] = useState({});
  const [openPopups8, setOpenPopups8] = useState({});
  const [openPopups9, setOpenPopups9] = useState({});
  const [openPopups10, setOpenPopups10] = useState({});
  const [openPopups27, setOpenPopups27] = useState({});
  const [csvData, setCsvData] = useState("");

  const [refundAmount, setRefundAmount] = useState(0);
  const [refundIntent, setRefundIntent] = useState("");
  const [refundMessage1, setRefundMessage1] = useState("");
  const [refundMessage2, setRefundMessage2] = useState("");

  const [popup5Class, setPopup5Class] = useState("hide_popup");
  const [popup15Class, setPopup15Class] = useState("hide_popup");
  const [popup16Class, setPopup16Class] = useState("hide_popup");
  const [start_date, setStart_date] = useState("");
  const [end_date, setEnd_date] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleRefundAmount = (e) => {
    // Update the search input value state
    setRefundAmount(e.target.value);
    // Call the function to update the search query state in the parent component
  };
  const handleStartDateChange = (e) => {
    // Update the search input value state
    setStart_date(e.target.value);
  };
  // Function to handle input change
  const handleEndDateChange = (e) => {
    // Update the search input value state
    setEnd_date(e.target.value);
  };

  const handleRefundIntent = (e) => {
    // Update the search input value state
    setRefundIntent(e.target.value);
    // Call the function to update the search query state in the parent component
  };

  const handleInputChange = (e) => {
    // Update the search input value state
    setSearchInput(e.target.value);
    // Call the function to update the search query state in the parent component
  };

  function encryptIntentId(intentId) {
    // Define a secret key for encryption. Keep this key secure and do not expose it in your code.
    const secretKey = "U2FsdGVkX1+8zVzH4kKkdI8L9SlnFmLgMBd5";

    // Encrypt the intent_id using AES encryption
    const encrypted = CryptoJS.AES.encrypt(
      intentId.toString(),
      secretKey
    ).toString();

    return encrypted;
  }

  const openPopup5 = (index) => {
    setOpenPopups5((prevState) => ({
      ...prevState,
      [index]: true,
    }));
  };
  const closePopup5 = (index) => {
    setOpenPopups5((prevState) => ({
      ...prevState,
      [index]: false,
    }));
  };

  const viewETickets = (intent_id) => {};
  const openPopup8 = (index) => {
    setOpenPopups8((prevState) => ({
      ...prevState,
      [index]: true,
    }));
  };
  const closePopup8 = (index) => {
    setOpenPopups8((prevState) => ({
      ...prevState,
      [index]: false,
    }));
  };
  const openPopup9 = (index) => {
    setOpenPopups9((prevState) => ({
      ...prevState,
      [index]: true,
    }));
  };
  const closePopup9 = (index) => {
    console.log("index ", index);
    setOpenPopups9((prevState) => ({
      ...prevState,
      [index]: false,
    }));
  };

  const fullCustomerRefund = useFullCustomerRefund(true, {
    onSuccess: () => {
      setRefundMessage1("Refund Successfully Initiated");
      setRefundMessage2(
        "Your customer should receive their refund within 3-5 business days"
      );
    },
  });

  const openPopup10 = async (
    index,
    booking,
    intent,
    quantity,
    booking_refundTransactions,
    is_partial
  ) => {
    console.log("Booking is: ", booking);
    const amount = refundAmount;
    const intent_id = intent;
    const is_partial_status = is_partial;
    if (is_partial) {
      if (amount === null || amount === 0 || isNaN(amount)) {
        alert("! Refund amount can not be empty or zero.");
        return;
      }
    }

    if (quantity && quantity < 1) {
      setRefundMessage1("Refund Not Initiated");
      setRefundMessage2(
        "! To Refund payments, there should be at least 2 seats in booking"
      );
      if (is_partial) {
        setOpenPopups9((prevState) => ({
          ...prevState,
          [index]: false,
        }));
      } else {
        setOpenPopups8((prevState) => ({
          ...prevState,
          [index]: false,
        }));
      }
      setOpenPopups10((prevState) => ({
        ...prevState,
        [index]: true,
      }));
      return;
    }

    if (booking_refundTransactions && booking_refundTransactions.length > 0) {
      alert(
        "! Full/Partial payment can not be done more than one. You have alread made refund on it earlier."
      );
      // return
    } else {
      // const is_partial = is_partial
      //   await makeRefund(is_partial, intent_id, amount);
      fullCustomerRefund.mutate(booking?.id);

      console.log("Intent id is : ", intent_id);
      // alert("in openPopup10")

      if (is_partial_status) {
        setOpenPopups9((prevState) => ({
          ...prevState,
          [index]: false,
        }));
        setOpenPopups10((prevState) => ({
          ...prevState,
          [index]: true,
        }));
        // window.location.reload();
      } else {
        setOpenPopups8((prevState) => ({
          ...prevState,
          [index]: false,
        }));
        setOpenPopups10((prevState) => ({
          ...prevState,
          [index]: true,
        }));
        // window.location.reload();
      }
    }
  };
  const closePopup10 = (index) => {
    setOpenPopups10((prevState) => ({
      ...prevState,
      [index]: false,
    }));
  };
  const openPopup27 = (index, id) => {
    setOpenPopups27((prevState) => ({
      ...prevState,
      [index]: true,
    }));

    setBookingIdForEmail(id);
    setBookingIndexForEmail(index);
  };
  const closePopup27 = (index) => {
    setOpenPopups27((prevState) => ({
      ...prevState,
      [index]: false,
    }));
  };

  const handleSendETickets = async (index) => {
    setLoading(true);
    // Validate email format
    const emailRegex = /^\S+@\S+\.\S+$/;
    if (!emailRegex.test(email)) {
      alert("Please enter a valid email address");
      closePopup27(index);
      return;
    }

    // Call API to send e-Tickets with the email
    // Replace 'YOUR_API_ENDPOINT' with your actual API endpoint
    const booking_id = bookingIdForEmail;
    await fetch(`${process.env.REACT_APP_BASE_URL}/api/sendeticket/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, booking_id }),
    })
      .then((response) => {
        if (response.ok) {
          setLoading(false);
          alert("e-Tickets sent successfully!");
          setOpenPopups27((prevState) => ({
            ...prevState,
            [bookingIndexForEmail]: false,
          }));
        } else {
          setLoading(false);
          console.error("Failed to send e-Tickets");
        }
      })
      .catch((error) => {
        console.error("Error sending e-Tickets:", error);
        alert("An error occurred while sending e-Tickets");
      });
  };

  useEffect(() => {
    // Function to filter venues based on search query
    const filterBookings = (start_date, end_date, filtBook) => {
      console.log("start ", start_date);
      console.log("end_date ", end_date);
      const filtBookings = filtBook.filter((booking) => {
        const bookingDate = new Date(booking.created)
          .toISOString()
          .split("T")[0];
        console.log("revenueDate ", bookingDate);
        return bookingDate >= start_date && bookingDate <= end_date;
      });
      // Update the filteredVenuesList state with the filtered venues
      setFilteredBookings(filtBookings);
    };

    if (start_date && end_date) {
      const start = new Date(start_date).toISOString().split("T")[0];
      const end = new Date(end_date).toISOString().split("T")[0];
      if (start < end || start === end) {
        filterBookings(start, end, bookings);
      }
    }
  }, [start_date, end_date]);

  const getEventFromId = useGetEventFromId(Number(eventId), eventId !== "", {
    onSuccess: (data) => {
      setEvent(data);
    },
  });

  const getEventBookings = useGetEventBookings(
    Number(eventId),
    eventId !== "",
    {
      onSuccess: (data) => {
        setBookings(data.bookings);
        setFilteredBookings(data.bookings);
      },
    }
  );

  //   useEffect(() => {
  //     const fetchEventData = async () => {
  //       const authToken = localStorage.getItem("authToken");
  //       if (!authToken) {
  //         console.error("Authentication token not found");
  //         navigate(`/Login/`);
  //         return;
  //       }
  //       const authUserId = localStorage.getItem("authUserId");
  //       console.log("token is ", authToken);
  //       console.log("authUserId is ", authUserId);

  //       if (!authUserId) {
  //         console.error("Authentication authUserId not found");
  //       }
  //       const eventResponse = await fetch(
  //         `${process.env.REACT_APP_BASE_URL}/api/events/${eventId}/`,
  //         {
  //           // method: 'POST',
  //           headers: {
  //             "Content-Type": "application/json",
  //             Authorization: `Token ${authToken}`,
  //           },
  //           // body:requestBody
  //         }
  //       );

  //       // Check if the request was successful (status code 2xx)
  //       if (eventResponse.ok) {
  //         const eventResponseData = await eventResponse.json(); // Parse the response JSON
  //         setEvent(eventResponseData);
  //         console.log("chart id is ", eventResponseData.chart_key);

  //         // Optionally, you can return the response data or perform other actions here
  //       } else {
  //         console.log("event retrieving error ", eventResponse.status);
  //       }

  //       // fetch booking
  //       const requestBody = JSON.stringify({
  //         booking_event: eventId,
  //       });
  //       // fetch event with eventId
  //       const bookingResponse = await fetch(
  //         `${process.env.REACT_APP_BASE_URL}/api/bookingsofevent/`,
  //         {
  //           method: "POST",
  //           headers: {
  //             "Content-Type": "application/json",
  //             Authorization: `Token ${authToken}`,
  //           },
  //           body: requestBody,
  //         }
  //       );

  //       // Check if the request was successful (status code 2xx)
  //       if (bookingResponse.ok) {
  //         const bookingResponseData = await bookingResponse.json(); // Parse the response JSON
  //         setBookings(bookingResponseData.bookings);
  //         setFilteredBookings(bookingResponseData.bookings);
  //         console.log("booking  is ", bookingResponseData.bookings);

  //         // Optionally, you can return the response data or perform other actions here
  //       } else {
  //         console.log("bookings retrieving error ", bookingResponse.status);
  //       }
  //     };
  //     fetchEventData();
  //   }, [eventId]);

  useEffect(() => {
    // Function to filter venues based on search query
    renderedIntentIds.clear();
    const filterBookings = async (query) => {
      console.log("query : ", query);
      const filtered_Bookings = bookings.filter((booking) => {
        // Perform case-insensitive search on venue name
        // const lowerCaseQuery = query.toLowerCase();
        // console.log("lowercase is :",(lowerCaseQuery));
        // console.log("(booking.total_price).toString() :",booking.total_price.toString().includes(query));
        // console.log("(first_name :",booking.billinginfo_ID.last_name.toLowerCase());
        // console.log("(booking.id.toString() :",booking.id.toString());

        // return (
        //     booking.billinginfo_ID.first_name.toLowerCase().includes(lowerCaseQuery) ||
        //     booking.billinginfo_ID.last_name.toLowerCase().includes(lowerCaseQuery) ||
        //     booking.total_price.toString().includes(query) ||
        //     booking.id.toString().includes(query)
        // );
        const lowerCaseQuery = query.toLowerCase();

        const includesFirstName = booking.billinginfo_ID.first_name
          .toLowerCase()
          .includes(lowerCaseQuery);
        const includesLastName = booking.billinginfo_ID.last_name
          .toLowerCase()
          .includes(lowerCaseQuery);
        const includesTotalPrice = booking.total_price
          .toString()
          .includes(query);
        const includesId = booking.id.toString().includes(query);
        console.log(
          "for price :",
          booking.total_price.toString().includes(query)
        );
        if (
          includesFirstName ||
          includesLastName ||
          includesTotalPrice ||
          includesId
        ) {
          return true;
        } else {
          return false;
        }
      });
      console.log("filteredBookings:", filtered_Bookings);
      console.log("Filered bookings old:", filteredBookings);
      // Update the filteredVenuesList state with the filtered venues
      setFilteredBookings(filtered_Bookings);
    };

    if (bookings !== null) {
      filterBookings(searchInput);
    }
  }, [searchInput]);

  useEffect(() => {
    // console.log("from useEffect filteredBOokings is :", filteredBookings);
  }, [filteredBookings]);

  const makeRefund = async (is_partial, intent_id, amount) => {
    const authToken = localStorage.getItem("authToken");
    if (!authToken) {
      console.error("Authentication token not found");
      navigate(`/Login/`);
      return;
    }
    const authUserId = localStorage.getItem("authUserId");
    console.log("token is ", authToken);
    console.log("authUserId is ", authUserId);

    if (!authUserId) {
      console.error("Authentication authUserId not found");
    }
    if (is_partial) {
      const payment = parseInt(amount);
      // partial refund payments
      const requestBody = JSON.stringify({
        intent_id: intent_id,
        amount: payment,
      });
      console.log("boyd is ", requestBody);
      // fetch event with eventId
      const partialRefundResponse = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/partialrefund/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${authToken}`,
          },
          body: requestBody,
        }
      );

      // Check if the request was successful (status code 2xx)
      if (partialRefundResponse.ok) {
        const partialRefundResponseData = await partialRefundResponse.json(); // Parse the response JSON
        setBookings(partialRefundResponseData.bookings);
        console.log("refund response is   is ", partialRefundResponseData);

        setRefundMessage1("Refund Successfully Initiated");
        setRefundMessage2(
          "Your customer should receive their refund within 3-5 business days"
        );
        return true;

        // Optionally, you can return the response data or perform other actions here
      } else {
        // const partialRefundResponseData = await partialRefundResponse.er();
        if (partialRefundResponse.status === 401) {
          // ! Partial payment can not be done more than one. You have alread made refun on it earlier.
          setRefundMessage1(
            "Full/Partial  payment can not be done more than one"
          );
          setRefundMessage2("You have already made refund on it earlier");
          return false;
        }
        const partialRefundResponseData = await partialRefundResponse.json();
        console.log("bookings retrieving error ", partialRefundResponseData);
        setRefundMessage1("Unable to Intitiate");
        setRefundMessage2("Try again in or reload the page");
        return false;
      }
    } // partial refund code ends here
    else {
      const payment = parseInt(amount);
      // partial refund payments
      const requestBody = JSON.stringify({
        intent_id: intent_id,
      });
      console.log("boyd is ", requestBody);
      // fetch event with eventId
      const fullRefundResponse = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/fullrefund/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${authToken}`,
          },
          body: requestBody,
        }
      );

      // Check if the request was successful (status code 2xx)
      if (fullRefundResponse.ok) {
        const fullRefundResponseData = await fullRefundResponse.json(); // Parse the response JSON
        setBookings(fullRefundResponseData.bookings);
        console.log("full refund response is   is ", fullRefundResponseData);

        setRefundMessage1("Refund Successfully Initiated");
        setRefundMessage2(
          "Your customer should receive their refund within 3-5 business days"
        );
        return true;

        // Optionally, you can return the response data or perform other actions here
      } else {
        // const partialRefundResponseData = await partialRefundResponse.er();
        if (fullRefundResponse.status === 401) {
          // ! Partial payment can not be done more than one. You have alread made refun on it earlier.
          setRefundMessage1("Refund payment  can not be done more than one");
          setRefundMessage2("you have already made refund on it earlier");
          return false;
        }
        const fullRefundResponseData = await fullRefundResponse.json();
        console.log("Refund payment error ", fullRefundResponseData);
        setRefundMessage1("Unable to Intitiate Refund");
        setRefundMessage2("Try again in or reload the page");
        return false;
      }
    }
  };

  const convertToCsv = () => {
    const header =
      Array.from(document.querySelectorAll(".manage_filter_tables thead th"))
        .map((th) => th.innerText)
        .join(",") + "\n";
    const rows = Array.from(
      document.querySelectorAll(".manage_filter_tables tbody tr")
    )
      .map((tr) =>
        Array.from(tr.children)
          .map((td) => td.innerText)
          .join(",")
      )
      .join("\n");
    const csv = header + rows;
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8" });
    const csvData = URL.createObjectURL(blob);
    setCsvData(csvData);
    saveAs(blob, "table_data.csv");
  };

  function formatDate(dateString) {
    const options = {
      weekday: "short",
      year: "numeric",
      month: "long",
      day: "numeric",
    };

    const timeOptions = {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };

    const date = new Date(dateString);

    // Format date part (Sat, August 3, 2024)
    const datePart = date.toLocaleDateString("en-US", options);

    // Format time part (12:02 AM)
    const timePart = date.toLocaleTimeString("en-US", timeOptions);

    return `${datePart} • ${timePart}`;
  }

  const createViewUrl = async (intent_id) => {
    // ticket view url work starts here
    const requestBody = JSON.stringify({
      booking_intent: intent_id,
    });

    const viewETicketResponse = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/createeticketpdf/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: requestBody,
      }
    );
    console.log("after api call");
    if (!viewETicketResponse.ok) {
      console.log("inside error api call: ", viewETicketResponse);
      console.error("Failed to fetch event data");
    }
    const ticketUrlData = await viewETicketResponse.json();
    console.log("ticketUrlData api call response ", ticketUrlData);

    const newUrl = ticketUrlData.pdf_url;

    // ticket work ends here
    const requestMessageBody = JSON.stringify({
      // phone : "+13239797746",
      phone: "+14086343220",
      message: newUrl,
      subject: "E-Ticket Purchased",
    });

    const requestMessageResponse = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/publishmessagetonumber/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: requestMessageBody,
      }
    );
    console.log("after api call");
    if (!requestMessageResponse.ok) {
      console.log("inside error api call: ", requestMessageResponse);
      console.error("Failed to fetch event data");
    } else {
      const requestMessageData = await requestMessageResponse.json();
      console.log("requestMessageData api call response ", requestMessageData);
    }

    return newUrl;
  };

  const handleTicketViewUrl = (intentId) => {
    navigate(generateViewTicketURL(intentId));
  };

  return (
    <>
      {loading && (
        <div id="preloader" className="preloader">
          <div className="loader"></div>
        </div>
      )}
      <div className="manage_order_area">
        {showBackButton && <ArchivedBack />}

        <div className="manage_order_section">
          {title && <h1>{title}</h1>}
          <form action="" className="manage_order_form">
            <div className="manage_filter_row">
              <div className="manage_filter_in">
                <input
                  type="search"
                  value={searchInput}
                  onChange={handleInputChange}
                  placeholder="Search by name, order #, or amount..."
                />
                {/* <i className='bx bx-search'></i> */}
                <FaSearch style={{ color: "#000" }} />
              </div>
            </div>
            <div className="manage_filter_d">
              <div className="filterid">
                <label htmlFor="">Filter by Date:</label>
                <input
                  type="date"
                  className="white_txt"
                  value={start_date}
                  onChange={handleStartDateChange}
                />
              </div>
              --
              <div className="filterid">
                <input
                  type="date"
                  className="white_txt"
                  value={end_date}
                  onChange={handleEndDateChange}
                />
              </div>
            </div>
            <button
              type="button"
              className="manage_filter_btn"
              onClick={convertToCsv}
            >
              Export CSV
            </button>
          </form>
        </div>
        <div className="manage_filter_table">
          <table className="manage_filter_tables">
            <thead>
              <tr>
                <th>Order #</th>
                <th>Name</th>
                <th># of Tickets</th>
                <th>Total Paid</th>
                <th>Payment Method</th>
                <th>More</th>
              </tr>
            </thead>
            <tbody>
              {filteredBookings &&
                Array.isArray(filteredBookings) &&
                filteredBookings.map((booking, index) => {
                  if (!renderedIntentIds.has(booking.intent_id)) {
                    // If not, add it to the Set and render the booking
                    renderedIntentIds.add(booking.intent_id);
                    return (
                      <React.Fragment key={index}>
                        <tr>
                          <td>{booking.id}</td>
                          <td>
                            {booking.billinginfo_ID.first_name}{" "}
                            {booking.billinginfo_ID.last_name}
                          </td>
                          <td>{booking.quantity}</td>
                          <td>
                            ${parseFloat(booking.total_price / 100).toFixed(2)}
                          </td>
                          <td>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="30"
                              height="30"
                              viewBox="0 0 30 30"
                              fill="none"
                            >
                              <path
                                d="M11.625 18.5625H13.5L14.625 11.4375H12.7969L11.625 18.5625Z"
                                fill="white"
                              />
                              <path
                                d="M18.375 14.2969C17.7188 13.9687 17.3438 13.7344 17.3438 13.4062C17.3438 13.125 17.6719 12.7969 18.4219 12.7969C19.0312 12.7969 19.4531 12.9375 19.8281 13.0781L20.0156 13.125L20.25 11.625C19.875 11.4844 19.3125 11.3438 18.6094 11.3438C16.7813 11.3438 15.4688 12.2812 15.4688 13.6875C15.4688 14.7187 16.3594 15.3281 17.1094 15.6562C17.8125 15.9844 18.0937 16.2187 18.0937 16.5469C18.0937 17.0156 17.5313 17.25 16.9688 17.25C16.2188 17.25 15.7969 17.1562 15.1875 16.875L14.9531 16.7812L14.6719 18.4219C15.0937 18.5625 15.8906 18.75 16.6875 18.75C18.6562 18.7969 19.9219 17.8125 19.9219 16.3125C19.875 15.4687 19.4063 14.8125 18.375 14.2969Z"
                                fill="white"
                              />
                              <path
                                d="M23.5313 11.4375C23.1094 11.4375 22.7344 11.5781 22.5469 12.0469L19.7812 18.5625H21.6563V18.6094C21.6563 18.6094 21.9375 17.7188 22.0781 17.5313C22.2656 17.5313 24.1875 17.5313 24.4219 17.5313C24.4687 17.7656 24.6562 18.6094 24.6562 18.6094H26.3906L24.8906 11.4844H23.5313V11.4375ZM22.5938 16.0781C22.7344 15.6562 23.3437 14.1094 23.3437 14.1094C23.3437 14.1563 23.5312 13.6875 23.5781 13.4531L23.7187 14.0625C23.7187 14.0625 24.0938 15.7969 24.1406 16.125C23.8594 16.0781 22.9688 16.0781 22.5938 16.0781Z"
                                fill="white"
                              />
                              <path
                                d="M10.125 11.4375L8.29688 16.3594L7.3125 11.4375H3.60938L5.67188 12.9844L7.17188 18.5625H9.09375L12.0937 11.4375H10.125Z"
                                fill="white"
                              />
                              <path
                                d="M25.5937 6.23438H4.40625C2.4375 6.23438 0.796875 7.82812 0.796875 9.84375V20.1563C0.796875 22.125 2.39062 23.7656 4.40625 23.7656H25.5469C27.5156 23.7656 29.1562 22.1719 29.1562 20.1563V9.84375C29.2031 7.875 27.5625 6.23438 25.5937 6.23438ZM27.0938 20.1563C27.0938 21 26.4375 21.6563 25.5937 21.6563H4.40625C3.5625 21.6563 2.90625 21 2.90625 20.1563V9.84375C2.90625 9 3.5625 8.34375 4.40625 8.34375H25.5469C26.3906 8.34375 27.0469 9 27.0469 9.84375V20.1563H27.0938Z"
                                fill="white"
                              />
                            </svg>
                          </td>
                          <td>
                            <div
                              id={index}
                              onClick={() => openPopup5(index)}
                              className="box "
                            >
                              <a className="" href="#">
                                {/* <i className='bx bx-dots-horizontal-rounded'style={{color: "#ffffff"}}></i> */}
                                <HiOutlineDotsHorizontal
                                  style={{ color: "#ffffff" }}
                                />
                              </a>
                            </div>
                            <div
                              id={`popup5${index}`}
                              className={`overlay_14 ${
                                openPopups5[index] ? "show_popup" : "hide_popup"
                              }`}
                            >
                              <div className="order_deatils">
                                <button
                                  className={`jclose ${
                                    openPopups5[index] ? "show" : "hide"
                                  }`}
                                  id={`close_popup5${index}`}
                                  onClick={() => closePopup5(index)}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="25"
                                    height="25"
                                    viewBox="0 0 25 25"
                                    fill="none"
                                  >
                                    <g clip-path="url(#clip0_517_27)">
                                      <path
                                        d="M12.5 0.703125C5.97656 0.703125 0.703125 5.97656 0.703125 12.5C0.703125 19.0234 5.97656 24.3359 12.5 24.3359C19.0234 24.3359 24.3359 19.0234 24.3359 12.5C24.3359 5.97656 19.0234 0.703125 12.5 0.703125ZM12.5 22.5781C6.95313 22.5781 2.46094 18.0469 2.46094 12.5C2.46094 6.95313 6.95313 2.46094 12.5 2.46094C18.0469 2.46094 22.5781 6.99219 22.5781 12.5391C22.5781 18.0469 18.0469 22.5781 12.5 22.5781Z"
                                        fill="#FAE100"
                                      />
                                      <path
                                        d="M16.0937 8.86719C15.7422 8.51562 15.1953 8.51562 14.8438 8.86719L12.5 11.25L10.1172 8.86719C9.76562 8.51562 9.21875 8.51562 8.86719 8.86719C8.51562 9.21875 8.51562 9.76562 8.86719 10.1172L11.25 12.5L8.86719 14.8828C8.51562 15.2344 8.51562 15.7812 8.86719 16.1328C9.02344 16.2891 9.25781 16.4062 9.49219 16.4062C9.72656 16.4062 9.96094 16.3281 10.1172 16.1328L12.5 13.75L14.8828 16.1328C15.0391 16.2891 15.2734 16.4062 15.5078 16.4062C15.7422 16.4062 15.9766 16.3281 16.1328 16.1328C16.4844 15.7812 16.4844 15.2344 16.1328 14.8828L13.75 12.5L16.1328 10.1172C16.4453 9.76562 16.4453 9.21875 16.0937 8.86719Z"
                                        fill="#FAE100"
                                      />
                                    </g>
                                    <defs>
                                      <clipPath id="clip0_517_27">
                                        <rect
                                          width="25"
                                          height="25"
                                          fill="white"
                                        />
                                      </clipPath>
                                    </defs>
                                  </svg>
                                </button>
                                <div className="order_dinfo">
                                  <div className="order_t">
                                    <div className="order_infog">
                                      <p>ORDER# {booking.id}</p>
                                      <p>
                                        Purchase Date/Time:{" "}
                                        {formatDate(booking.created)}{" "}
                                      </p>
                                    </div>

                                    <div
                                      className="dropdown1"
                                      style={{ float: "left" }}
                                    >
                                      <button className="dropbtn1">
                                        More{" "}
                                        <i className="bx bx-chevron-down"></i>
                                      </button>
                                      <div
                                        className="dropdown-content1"
                                        style={{ left: "0" }}
                                      >
                                        <div
                                          className="box "
                                          onClick={() =>
                                            handleTicketViewUrl(
                                              booking.intent_id
                                            )
                                          }
                                        >
                                          <a className="" href="#">
                                            View eTickets
                                          </a>
                                        </div>
                                        <div
                                          className="box "
                                          onClick={() => openPopup8(index)}
                                        >
                                          <a className="" href="#">
                                            Full Refund
                                          </a>
                                        </div>
                                        <div
                                          className="box "
                                          onClick={() => openPopup9(index)}
                                        >
                                          <a className="" href="#">
                                            Partial Refund
                                          </a>
                                        </div>
                                        <div
                                          className="box "
                                          onClick={() =>
                                            openPopup27(index, booking.id)
                                          }
                                        >
                                          <a className="" href="#">
                                            Send eTickets
                                          </a>
                                        </div>
                                        <div className="box ">
                                          <a className="" href="#">
                                            Add Ticket(s)
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="order_user">
                                    <p>
                                      Customer Name:{" "}
                                      {booking.billinginfo_ID.first_name}{" "}
                                      {booking.billinginfo_ID.last_name}
                                    </p>
                                    <p>
                                      Number of Tickets Purchased:{" "}
                                      {booking.quantity}
                                    </p>
                                    <p>
                                      Order Total: ${booking.total_price / 100}
                                    </p>
                                    <p>
                                      Event Title: {event.Event_Name}
                                      <br />
                                      {formatDatePrevious(
                                        event.start_date
                                      )} .{" "}
                                      {convertTo12HourFormat(event.start_time)}{" "}
                                      - {formatDatePrevious(event.end_date)} .{" "}
                                      {convertTo12HourFormat(event.end_time)}{" "}
                                    </p>
                                  </div>
                                  <div className="order_typed">
                                    <table>
                                      <thead>
                                        <tr>
                                          <th>Ticket Type</th>
                                          <th>QTY</th>
                                          <th>Price</th>
                                          <th>Service Fees</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {filteredBookings &&
                                          Array.isArray(filteredBookings) &&
                                          Object.entries(
                                            filteredBookings.reduce(
                                              (acc, sub_booking) => {
                                                if (
                                                  booking.intent_id ===
                                                  sub_booking.intent_id
                                                ) {
                                                  const key =
                                                    sub_booking.seat_sub_category
                                                      ? sub_booking.seat_sub_category
                                                      : sub_booking.seat_category;
                                                  if (!acc[key]) {
                                                    acc[key] = {
                                                      quantity: 0,
                                                      totalSeatPrice: 0,
                                                    };
                                                  }
                                                  acc[key].quantity++;
                                                  acc[key].totalSeatPrice +=
                                                    sub_booking.seat_price;
                                                }
                                                return acc;
                                              },
                                              {}
                                            )
                                          ).map(
                                            ([category, data], sub_index) => (
                                              <tr key={sub_index}>
                                                <td>{category}</td>
                                                <td>{data.quantity}</td>
                                                <td>${data.totalSeatPrice}</td>
                                                <td>$</td>
                                              </tr>
                                            )
                                          )}

                                        {/* {bookings && Array.isArray(bookings) && bookings.map((sub_booking, sub_index) => {
                                                    if (uniqueIntentIds.current === (sub_booking.intent_id)) {
                                                        return(
                                                    <tr key={sub_index}>
                                                        <td>
                                                        {sub_booking.seat_sub_category ? sub_booking.seat_sub_category : sub_booking.seat_category}
                                                        </td>                                                        
                                                        <td>{sub_booking.quantity}</td>
                                                        <td>${sub_booking.seat_price}</td>
                                                        <td>$ </td>
                                                    </tr>
                                                        )
                                                    }
                                                    return null
                                                })} */}
                                      </tbody>
                                    </table>
                                  </div>
                                  <div className="order_payd">
                                    <div className="order_paydo">
                                      <p>Payment Method</p>
                                      <p>
                                        Last 4 Digits: <br />
                                        ####-1234
                                      </p>
                                    </div>
                                    <div className="order_paydt">
                                      <div className="pay_row">
                                        <p>Subtotal</p>
                                        <p>${booking.sub_price}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>
                          <div
                            id={`popup8${index}`}
                            className={`overlay_14 ${
                              openPopups8[index] ? "show_popup" : "hide_popup"
                            }`}
                          >
                            <div className="main_popupre">
                              <p>Notice</p>
                              <p>
                                Confirm: You are about to send this customer a
                                refund of. $
                                {parseFloat(booking.total_price / 100).toFixed(
                                  2
                                )}{" "}
                              </p>
                              <div className="main_popupre_btns">
                                <p
                                  onClick={() => closePopup8(index)}
                                  className="tran"
                                >
                                  Cancel
                                </p>
                                <div
                                  className="box "
                                  onClick={() =>
                                    openPopup10(
                                      index,
                                      booking,
                                      booking.intent_id,
                                      booking.quantity,
                                      booking.refund_transactions,
                                      false
                                    )
                                  }
                                >
                                  <button className="yellow btn">
                                    Confirm
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            id={`popup10${index}`}
                            className={`overlay_14 ${
                              openPopups10[index] ? "show_popup" : "hide_popup"
                            }`}
                          >
                            <div className="main_popupre">
                              <button
                                className={`jclose ${
                                  openPopups10[index] ? "show" : "hide"
                                }`}
                                onClick={() => closePopup10(index)}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="25"
                                  height="25"
                                  viewBox="0 0 25 25"
                                  fill="none"
                                >
                                  <g clip-path="url(#clip0_517_27)">
                                    <path
                                      d="M12.5 0.703125C5.97656 0.703125 0.703125 5.97656 0.703125 12.5C0.703125 19.0234 5.97656 24.3359 12.5 24.3359C19.0234 24.3359 24.3359 19.0234 24.3359 12.5C24.3359 5.97656 19.0234 0.703125 12.5 0.703125ZM12.5 22.5781C6.95313 22.5781 2.46094 18.0469 2.46094 12.5C2.46094 6.95313 6.95313 2.46094 12.5 2.46094C18.0469 2.46094 22.5781 6.99219 22.5781 12.5391C22.5781 18.0469 18.0469 22.5781 12.5 22.5781Z"
                                      fill="#FAE100"
                                    />
                                    <path
                                      d="M16.0937 8.86719C15.7422 8.51562 15.1953 8.51562 14.8438 8.86719L12.5 11.25L10.1172 8.86719C9.76562 8.51562 9.21875 8.51562 8.86719 8.86719C8.51562 9.21875 8.51562 9.76562 8.86719 10.1172L11.25 12.5L8.86719 14.8828C8.51562 15.2344 8.51562 15.7812 8.86719 16.1328C9.02344 16.2891 9.25781 16.4062 9.49219 16.4062C9.72656 16.4062 9.96094 16.3281 10.1172 16.1328L12.5 13.75L14.8828 16.1328C15.0391 16.2891 15.2734 16.4062 15.5078 16.4062C15.7422 16.4062 15.9766 16.3281 16.1328 16.1328C16.4844 15.7812 16.4844 15.2344 16.1328 14.8828L13.75 12.5L16.1328 10.1172C16.4453 9.76562 16.4453 9.21875 16.0937 8.86719Z"
                                      fill="#FAE100"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_517_27">
                                      <rect
                                        width="25"
                                        height="25"
                                        fill="white"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>
                              </button>
                              <p>{refundMessage1}</p>
                              <p>{refundMessage2} </p>
                            </div>
                          </div>
                          <div
                            id={`popup9${index}`}
                            className={`overlay_14 ${
                              openPopups9[index] ? "show_popup" : "hide_popup"
                            }`}
                          >
                            <div className="main_popupre">
                              <a className="mclose" href="#">
                                <i
                                  className="bx x"
                                  onClick={() => closePopup9(index)}
                                ></i>
                              </a>
                              <div className="popup_center">
                                <h3>Enter Partial Refund Amount</h3>
                                <p className="sm">
                                  Order Total: $
                                  {parseFloat(
                                    booking.total_price / 100
                                  ).toFixed(2)}
                                </p>
                                <div className="inputs_pup">
                                  <label htmlFor="">
                                    Partial Refund Amount:
                                  </label>
                                  <input
                                    type="text"
                                    value={refundAmount}
                                    onChange={handleRefundAmount}
                                  />
                                </div>
                              </div>
                              <div className="main_popupre_btns">
                                <p
                                  onClick={() => closePopup9(index)}
                                  className="tran"
                                >
                                  Cancel
                                </p>
                                <div className="box ">
                                  <button
                                    className="yellow btn"
                                    onClick={() =>
                                      openPopup10(
                                        index,
                                        booking.intent_id,
                                        booking.quantity,
                                        booking.refund_transactions,
                                        true
                                      )
                                    }
                                  >
                                    Refund
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            id={`popup27${index}`}
                            className={`overlay_14 ${
                              openPopups27[index] ? "show_popup" : "hide_popup"
                            }`}
                          >
                            <div className="main_popupre">
                              <a className="mclose" href="#">
                                <i
                                  className="bx x"
                                  onClick={() => closePopup27(index)}
                                ></i>
                              </a>
                              <div className="popup_center">
                                <h3>Send e-Tickets</h3>
                                <div className="inputs_pup lg">
                                  <label htmlFor="">Email:</label>
                                  <input
                                    type="text"
                                    value={email}
                                    onChange={handleEmailChange}
                                  />
                                </div>
                              </div>
                              <div className="main_popupre_btns">
                                <p
                                  onClick={() => closePopup27(index)}
                                  className="tran"
                                >
                                  Cancel
                                </p>
                                <div className="box">
                                  <button
                                    className="yellow btn"
                                    onClick={() => handleSendETickets(index)}
                                  >
                                    Send e-Tickets
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* add refunded transacitions here */}
                        </tr>

                        {booking.refund_transactions &&
                          booking.refund_transactions.length > 0 && (
                            <tr>
                              <td></td>
                              <td>
                                {booking.billinginfo_ID.first_name}{" "}
                                {booking.billinginfo_ID.last_name}
                              </td>
                              <td></td>
                              <td>
                                - $
                                {booking.refund_transactions[0].refund_amount}
                              </td>
                              <td>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="30"
                                  height="30"
                                  viewBox="0 0 30 30"
                                  fill="none"
                                >
                                  <path
                                    d="M11.625 18.5625H13.5L14.625 11.4375H12.7969L11.625 18.5625Z"
                                    fill="white"
                                  />
                                  <path
                                    d="M18.375 14.2969C17.7188 13.9687 17.3438 13.7344 17.3438 13.4062C17.3438 13.125 17.6719 12.7969 18.4219 12.7969C19.0312 12.7969 19.4531 12.9375 19.8281 13.0781L20.0156 13.125L20.25 11.625C19.875 11.4844 19.3125 11.3438 18.6094 11.3438C16.7813 11.3438 15.4688 12.2812 15.4688 13.6875C15.4688 14.7187 16.3594 15.3281 17.1094 15.6562C17.8125 15.9844 18.0937 16.2187 18.0937 16.5469C18.0937 17.0156 17.5313 17.25 16.9688 17.25C16.2188 17.25 15.7969 17.1562 15.1875 16.875L14.9531 16.7812L14.6719 18.4219C15.0937 18.5625 15.8906 18.75 16.6875 18.75C18.6562 18.7969 19.9219 17.8125 19.9219 16.3125C19.875 15.4687 19.4063 14.8125 18.375 14.2969Z"
                                    fill="white"
                                  />
                                  <path
                                    d="M23.5313 11.4375C23.1094 11.4375 22.7344 11.5781 22.5469 12.0469L19.7812 18.5625H21.6563V18.6094C21.6563 18.6094 21.9375 17.7188 22.0781 17.5313C22.2656 17.5313 24.1875 17.5313 24.4219 17.5313C24.4687 17.7656 24.6562 18.6094 24.6562 18.6094H26.3906L24.8906 11.4844H23.5313V11.4375ZM22.5938 16.0781C22.7344 15.6562 23.3437 14.1094 23.3437 14.1094C23.3437 14.1563 23.5312 13.6875 23.5781 13.4531L23.7187 14.0625C23.7187 14.0625 24.0938 15.7969 24.1406 16.125C23.8594 16.0781 22.9688 16.0781 22.5938 16.0781Z"
                                    fill="white"
                                  />
                                  <path
                                    d="M10.125 11.4375L8.29688 16.3594L7.3125 11.4375H3.60938L5.67188 12.9844L7.17188 18.5625H9.09375L12.0937 11.4375H10.125Z"
                                    fill="white"
                                  />
                                  <path
                                    d="M25.5937 6.23438H4.40625C2.4375 6.23438 0.796875 7.82812 0.796875 9.84375V20.1563C0.796875 22.125 2.39062 23.7656 4.40625 23.7656H25.5469C27.5156 23.7656 29.1562 22.1719 29.1562 20.1563V9.84375C29.2031 7.875 27.5625 6.23438 25.5937 6.23438ZM27.0938 20.1563C27.0938 21 26.4375 21.6563 25.5937 21.6563H4.40625C3.5625 21.6563 2.90625 21 2.90625 20.1563V9.84375C2.90625 9 3.5625 8.34375 4.40625 8.34375H25.5469C26.3906 8.34375 27.0469 9 27.0469 9.84375V20.1563H27.0938Z"
                                    fill="white"
                                  />
                                </svg>
                              </td>
                            </tr>
                          )}
                      </React.Fragment>
                    );
                  }
                  return null;
                })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default ManageOrdersCom;
