import { parseSeatDataIntoCartItem } from "../helpers/seatHelpers";
import styles from "../ticketPurchaseWizard.module.css";
import { CartItem } from "./CartItem";
import { CartPrices } from "./CartPrices";

const CART_ITEMS = [
  // {
  //   quantity: 7,
  //   unitPrice: 19.99,
  //   itemName: "VIP Ticket",
  //   itemDescription: "The best, period",
  //   onDelete: () => {},
  //   onAddItem: () => {},
  //   onSubtractItem: () => {},
  // },
  // {
  //   quantity: 2,
  //   unitPrice: 5.99,
  //   itemName: "Regular Ticket",
  //   itemDescription: "Sitting, but barely",
  //   onDelete: () => {},
  //   onAddItem: () => {},
  //   onSubtractItem: () => {},
  // },
];

export const Cart = ({
  items,
  isEventFree = false,
  isAddSubtractEnabled = false,
}) => {
  return (
    <div className={styles.cart}>
      <h2 className={styles.cartTitle}>Cart</h2>
      <div className={styles.cartItemContainer}>
        {items.map((item) => (
          <CartItem
            item={item}
            key={item.itemDescription}
            isAddSubtractEnabled={isAddSubtractEnabled}
          />
        ))}
      </div>
      <div className={styles.cartPriceContainer}>
        {isEventFree ? (
          <p className={styles.cartPriceText}>This event is free</p>
        ) : (
          <CartPrices items={items} />
        )}
      </div>
    </div>
  );
};
