import {
  axiosService,
  DISABLE_CASE_CONVERSION,
} from "../../../api/axiosConfig";
import { useMutation } from "react-query";

export const fullCustomerRefund = async (bookingId) => {
  const response = await axiosService.post(
    "stripe/full-customer-refund/",
    {
      booking_id: bookingId,
    },
    {
      headers: {
        [DISABLE_CASE_CONVERSION]: true,
      },
    }
  );
  return response.data;
};

export const useFullCustomerRefund = (enabled = true, options = {}) => {
  return useMutation({
    mutationKey: "fullCustomerRefund",
    mutationFn: (bookingId) => fullCustomerRefund(bookingId),
    onError: (error) => {
      console.log(error);
    },
    enabled: true,
    ...options,
  });
};
