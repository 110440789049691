import axios from "axios";
import { camelizeKeys, decamelizeKeys } from "humps";
import {
  // clearAuthenticationTokens,
  // clearAuthenticationTokens,
  getAuthenticationTokens,
  // setAuthenticationTokens,
} from "../utils/tokenManager.js"

// const PUBLIC_ENDPOINTS = [
//   "users/register/email",
//   "users/login/email",
//   "users/refresh/",
// ];

export const DISABLE_CASE_CONVERSION = "Disable-Case-Conversion";

export const axiosService = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/api`,
  withCredentials: false,
});

// Request interceptor
axiosService.interceptors.request.use((config) => {
  const tokens = getAuthenticationTokens();

  if (tokens && tokens.access) {
    config.headers["Authorization"] = `Token ${tokens?.access}`;
  }

  if (
    config.data &&
    !(config.data instanceof FormData) &&
    config.headers[DISABLE_CASE_CONVERSION] !== "true"
  ) {
    config.data = decamelizeKeys(config.data);
  }

  if (config.data && config.data instanceof FormData) {
    config.headers = {
      ...config.headers,
      "Content-Type": "multipart/form-data",
    };
  }

  return config;
});

// Response interceptor
axiosService.interceptors.response.use(
  (response) => {
    if (
      response.data &&
      response.config.headers[DISABLE_CASE_CONVERSION] !== "true"
    ) {
      response.data = camelizeKeys(response.data);
    }
    return response;
  }

  // async (error) => {
  //   // Response interceptor
  //   axiosService.interceptors.response.use(
  //     (response) => {
  //       if (response.data) {
  //         response.data = camelizeKeys(response.data);
  //       }
  //       return response;
  //     },
  //     // async (error) => {
  //     //   const originalRequest = error.config;

  //     //   if (
  //     //     error.response &&
  //     //     error.response.status === 401 &&
  //     //     !originalRequest._retry
  //     //   ) {
  //     //     originalRequest._retry = true;

  //     //     const response = await axiosService.post(
  //     //       "users/refresh/",
  //     //       {
  //     //         refresh: getAuthenticationTokens().refresh,
  //     //       }
  //     //     );

  //     //     const newTokens = {
  //     //       access: response.data.access,
  //     //       refresh: response.data.refresh,
  //     //       user: getAuthenticationTokens().user,
  //     //     };

  //     //     setAuthenticationTokens(newTokens);

  //     //     originalRequest.headers[
  //     //       "Authorization"
  //     //     ] = `Bearer ${newTokens.access}`;

  //     //     return axiosService(originalRequest);

  //     //   } else if (originalRequest._retry) {
  //     //     clearAuthenticationTokens();
  //     //     window.location.href = "/Login_org"
  //     //   }

  //     //   if (error.response && error.response.data) {
  //     //     error.response.data = camelizeKeys(error.response.data);
  //     //   }

  //     //   return Promise.reject(error);
  //     // }
  //   );

  //   if (error.response && error.response.data) {
  //     error.response.data = camelizeKeys(error.response.data);
  //   }

  //   return Promise.reject(error);
  // }
);
