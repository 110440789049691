import styles from "./dropdown.module.css";

export const Dropdown = ({
  options,
  defaultValue,
  defaultOption,
  register,
  onChange,
}) => {
  return (
    <select
      defaultValue={defaultValue}
      className={styles.dropdown}
      onChange={onChange ? (e) => onChange(e.target.value) : undefined}
      {...register}
    >
      <option value="" disabled>
        {defaultOption || "Select time zone"}
      </option>
      {options.map((option, index) => (
        <option value={option} key={index}>
          {option.replaceAll("_", " ").replaceAll("/", " / ")}
        </option>
      ))}
    </select>
  );
};
