import styles from "../ticketPurchaseWizard.module.css";
import {
  calculateServiceFee,
  calculateSubtotal,
  calculateTaxes,
  calculateTotal,
} from "../helpers/billingHelpers";
import { formatCurrency } from "../../../utils/formatCurrency";
import { useGetEventFromToken } from "../../../api/hooks/events/useGetEventFromToken";
import { useParams } from "react-router-dom";
import clsx from "clsx";

export const CartPrices = ({ items }) => {
  const { token: eventToken } = useParams();

  const {
    data: eventData,
    isLoading: isGetEventDataLoading,
    isError: isGetEventDataError,
    isSuccess: isGetEventDataSuccess,
  } = useGetEventFromToken(eventToken, Boolean(eventToken));

  const subtotal = calculateSubtotal(items);
  const taxes = calculateTaxes(
    subtotal,
    eventData?.event?.eventTax,
    eventData?.event?.isEventFree
  );
  const serviceFee = calculateServiceFee(
    subtotal,
    taxes,
    eventData?.event?.absorbFee,
    eventData?.event?.isEventFree
  );

  const total = calculateTotal(subtotal, taxes, serviceFee);

  const formatTaxRate = (taxRate) => {
    return `(${taxRate}%)`;
  };

  return (
    <>
      <div className={styles.cartPriceRow}>
        <span>Subtotal</span>
        <span>{formatCurrency(subtotal)}</span>
      </div>
      <div className={styles.cartPriceRow}>
        <span>
          Taxes{" "}
          <span className={styles.cartTaxRate}>
            {formatTaxRate(eventData?.event?.eventTax)}
          </span>
        </span>
        <span>{formatCurrency(taxes)}</span>
      </div>
      <div className={styles.cartPriceRow}>
        <span>Service fees</span>
        <span>{formatCurrency(serviceFee)}</span>
      </div>
      <div className={clsx(styles.cartPriceRow, styles.total)}>
        <span>Total</span>
        <span>{formatCurrency(total)}</span>
      </div>
    </>
  );
};
