'use client'
import React, { useState } from 'react';
import './index.css'
// import Link from 'next/link';
import { RootLayout } from "@/layouts/RootLayout/RootLayout";

const Login_org = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

    // Function to handle email input change
    const handleUsernameChange = (e) => {
      setUsername(e.target.value);
    };
  
    // Function to handle password input change
    const handlePasswordChange = (e) => {
      setPassword(e.target.value);
    };
  
    const handleLogin = async (e) => {
      e.preventDefault();
  
      try {
        
        // Validate password length
        if (username.length < 1) {
          setError('Please enter username/email and password');
          return;
        }
        // Check if passwords match
        if (password.length < 1) {
          setError('Please enter username/email and password');
          return;
        }
        const role = "Organizer"
        // Make HTTP request to authenticate user
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/login/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ username, password , role}),
        });
  
        if (!response.ok) {
          setError('Authentication failed'); // Set error message
          console.error('Authentication failed');
          return
        }
        const data = await response.json();
        const authToken = data.token;
        const authUserId = data.user.id;
        const user_role = data.user.role;
        localStorage.setItem('authToken', authToken);
        localStorage.setItem('authUserId', authUserId);
        localStorage.setItem('role', user_role);
  
        // Handle successful authentication (redirect, set user state, etc.)
        // For example, you can redirect to another page after successful login
        window.location.href = '/organizer/dashboard/create-an-event-1';
      } catch (error) {
        console.error('Authentication error:', error.message);
        setError('Invalid username or password'); // Set error message
  
        // Handle authentication error (display error message, clear inputs, etc.)
      }
    };
  
  


  return (
    <>
      <RootLayout>
        <div className='main_container'>
          <div className="login_container">
            <div className="login_section">

              <div className="coustomer_form ">
                <h2>ORGANIZERS</h2>
                {error && <p className="error_message">{error}</p>} {/* Render error message */}
                <form onSubmit={handleLogin} className="login_cous" >
                  <p>Already have an account?</p>
                  <h2>Login</h2>
                  <label htmlFor="">Email</label>
                  <input 
                  type="email"
                  value={username}
                  onChange={handleUsernameChange}
                  required
                   />

                  <label htmlFor="">Password</label>
                  <input type="password"  value={password} onChange={handlePasswordChange} required />
                  <button type='submit' className='cursor_pointer submit_btn'>Login</button>
                  {/* {error && <p className="error_message">{error}</p>} Render error message */}
                </form>
                <div className="sign_btnss">

                  <p>Don’t have an account?</p>
                  <a href="/signup/organizer" className="cursor_pointer" >Sign Up</a>
                </div>
                <div className="sign_btnss">
                  <p >Forget You Psssword?</p>
                  <a href="/forgot" className="cursor_pointer">Reset Psssword</a>

                </div>
                <a href="/Login">Or login as <span className='yellow_cr'>Customer</span> </a>

              </div>
            </div>
          </div>
        </div>
      </RootLayout>
    </>
  )
}

export default Login_org
