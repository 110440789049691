import { FaEllipsisVertical } from "react-icons/fa6";
import styles from "./eventActionsButton.module.css";
import { useState } from "react";
import { useQueryClient } from "react-query";
import { getModifiedFields } from "../../helpers";
import { useUpdateEvent } from "@/api/hooks/events/useUpdateEvent";
import { useGetEventFromId } from "@/api/hooks/events/useGetEventFromId";

export const EventActionsButton = ({ eventId }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const queryClient = useQueryClient();
  const updateEvent = useUpdateEvent(eventId, {
    onSuccess: () => {
      queryClient.invalidateQueries(["getEventFromId", eventId]);
    },
  });

  const { data: eventData } = useGetEventFromId(eventId, eventId !== undefined);

  const handleAction = (action) => {
    const modifiedFields = getModifiedFields(action);
    updateEvent.mutate(modifiedFields);
    setIsDropdownOpen(false);
  };

  const handleToggleDropdownIsOpen = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  // const handleCloseDropdown = () => {
  //   setIsDropdownOpen(false);
  // };

  const ACTIONS = [
    {
      label: "Pause ticket sales",
      condition: !eventData?.isPaused,
      id: "PAUSE_TICKET_SALES",
    },
    {
      label: "Resume ticket sales",
      condition: eventData?.isPaused,
      id: "RESUME_TICKET_SALES",
    },
    {
      label: "Cancel event",
      condition: !eventData?.isCancelled,
      id: "CANCEL_EVENT",
    },
    {
      label: "Reinstate event",
      condition: eventData?.isCancelled,
      id: "REINSTATE_EVENT",
    },
    {
      label: "Archive event",
      condition: !eventData?.isArchived,
      id: "ARCHIVE_EVENT",
    },
    {
      label: "Un-archive event",
      condition: eventData?.isArchived,
      id: "UNARCHIVE_EVENT",
    },
    {
      label: "Publish event",
      condition: !eventData?.isPublished,
      id: "PUBLISH_EVENT",
    },
    {
      label: "Unpublish event",
      condition: eventData?.isPublished,
      id: "UNPUBLISH_EVENT",
    },
  ];

  return (
    <div className={styles.actionContainer}>
      <button className={styles.button} onClick={handleToggleDropdownIsOpen}>
        <FaEllipsisVertical />
      </button>
      {isDropdownOpen && (
        <div className={styles.dropdown}>
          {ACTIONS.filter((action) => action.condition).map((action) => (
            <button
              className={styles.action}
              onClick={() => handleAction(action.id)}
              key={action.label}
            >
              {action.label}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};
