import { useMutation } from "react-query";
import { axiosService } from "@/api/axiosConfig";

const submitBillingInfo = async (data) => {
  const response = await axiosService.post(
    "stripe/submit-billing-information/",
    data
  );
  return response.data;
};

export const useSubmitBillingInfo = (options = {}) => {
  return useMutation({
    mutationKey: "submitBillingInformation",
    mutationFn: (data) => submitBillingInfo(data),
    onError: (error) => {
      console.log(error);
    },
    ...options,
  });
};
