import { axiosService } from "../../axiosConfig";
import { useQuery } from "react-query";

const getEventFromToken = async (eventToken) => {
  const response = await axiosService.post("eventofuniquetoken/", {
    uniqueToken: eventToken,
  });

  return response.data;
};

export const useGetEventFromToken = (
  eventToken,
  enabled = true,
  options = {}
) => {
  return useQuery({
    queryKey: ["getEventFromToken", eventToken],
    queryFn: () => getEventFromToken(eventToken),
    onError: (error) => {
      console.log(error);
    },
    enabled: enabled,
    ...options,
  });
};
