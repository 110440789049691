import { useQuery } from "react-query";
import { axiosService } from "@/api/axiosConfig";

const getEventSeatCategoryData = async (eventId) => {
  const response = await axiosService.post(
    `categoriesandsubcategoriesofevent/`,
    {
      eventId: eventId,
    }
  );
  return response.data;
};

export const useGetEventSeatCategoryData = (
  eventId,
  enabled = true,
  options = {}
) => {
  return useQuery({
    queryKey: ["getEventSeatCategoryData", eventId],
    queryFn: () => getEventSeatCategoryData(eventId),
    onError: (error) => {
      console.log(error);
    },
    enabled: enabled,
    ...options,
  });
};
