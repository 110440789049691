// import Link from 'next/link'
import React , {useState, useEffect} from 'react'
import "./index.css"
import { RootLayout } from "@/layouts/RootLayout/RootLayout";
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';

const UpdatePassword = () => {
  
  const navigate = useNavigate();
  const { token } = useParams();
  const [confirm_password, setConfirm_password] = useState('');
  const [password, setPassword] = useState('');

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  }
  const handleConfirm_passwordChange = (e) => {
    setConfirm_password(e.target.value);
  }

  const update_password_click = async ()=> {
       // Check if password and confirm password match
        if (password !== confirm_password) {
          alert('Passwords do not match');
          return;
      }

      // Check if password length is less than 8 characters
      if (password.length < 8) {
          alert('Password should be at least 8 characters long');
          return;
      }

      // Check if password is null or empty
      if (!password.trim()) {
          alert('Password cannot be empty');
          return;
      }

        fetchData();
  }
  const fetchData = async () => {
    try {
        const requestBody = JSON.stringify({
            token : token,
            password: password,
            confirm_password: confirm_password
        });

        // const eventResponse = await fetch(`${process.env.REACT_APP_BASE_URL}/api/eventofuniquetoken/`, {
            const eventResponse = await fetch(`${process.env.REACT_APP_BASE_URL}/api/updatepassword/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: requestBody
        });
        console.log("after api call ", eventResponse);
        if (!eventResponse.ok) {
            console.log("inside error api call: ", eventResponse);
            alert('Failed to send password update link, please enter valid email address');
            return
        }else{
          const eventResponseData = await eventResponse.json();
          if (eventResponseData.success){
            alert('Password has been updated successfully.  ');
            navigate(`/Login/`);

          }
          else if(eventResponseData.success==="mismatch"){
            alert('Password and confirm password are not same.  ');
            return
          }
          else if(eventResponseData.success==="validation"){
            alert('Password is not as per standard, it should be at least 8 chracters  ');
            return
          }
          else{
            alert('Failed to update password, please try again ');
            return
          }
        }


    } catch (error) {
        console.error(error);
    } 
};

  return (
    <>
      <RootLayout>
    <div className='main_container'>
      <div className="forgot_container">
        <div className="forgot_box">
          <h1>Update Password</h1>
          <form action="">
            <label htmlFor="">Password</label>
            <div>
              <input type="password" value={password} onChange={handlePasswordChange} placeholder='Email' />

            </div>
            <label htmlFor="">Confirm Password</label>
            <div>
              <input type="password" value={confirm_password} onChange={handleConfirm_passwordChange}  placeholder='Email' />

            </div>
            <div>
              <button type='button'onClick={update_password_click}>Update Password</button>

            </div>
          </form>
        </div>
      </div>
    </div>
    </RootLayout>
    </>
  )

}

export default UpdatePassword
