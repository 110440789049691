// import Link from 'next/link'
import React , {useState, useEffect} from 'react'
import "./index.css"
import { RootLayout } from "@/layouts/RootLayout/RootLayout";
import { useNavigate } from 'react-router-dom';

const Forgot = () => {
  
  const navigate = useNavigate();
  const [email, setEmail] = useState('');

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  }

  const CheckUser=async ()=> {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
          alert("*Email is not in proper format");
          return
        }
        fetchData();
  }
  const fetchData = async () => {
    try {
        // base_api_url = "${process.env.REACT_APP_BASE_URL}";
        
        // const token = 'e0d25a4a3fda989bf969bc5971a9e36878ece9f2';
        // const authToken = localStorage.getItem('authToken');

        // if (!authToken) {
        // console.error('Authentication token not found');
        // }

        const requestBody = JSON.stringify({
            email : email,
        });

        // const eventResponse = await fetch(`${process.env.REACT_APP_BASE_URL}/api/eventofuniquetoken/`, {
            const eventResponse = await fetch(`${process.env.REACT_APP_BASE_URL}/api/sendlink/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: requestBody
        });
        console.log("after api call ", eventResponse);
        if (!eventResponse.ok) {
            console.log("inside error api call: ", eventResponse);
            alert('Failed to send password update link, please enter valid email address');
            return
        }else{
          const eventResponseData = await eventResponse.json();
          if (eventResponseData.success){
            alert('Password update link is sent on your email address. ');
            navigate(`/Login/`); 

          }
        }


    } catch (error) {
        console.error(error);
    } 
};

  return (
    <>
      <RootLayout>
    <div className='main_container'>
      <div className="forgot_container">
        <div className="forgot_box">
          <h1>Forgot Password</h1>
          <form action="">
            <label htmlFor="">Email</label>
            <div>
              <input type="email" value={email} onChange={handleEmailChange} placeholder='Email' />

            </div>
            <div>
              <button type='button'onClick={CheckUser}>Send Link</button>

            </div>
          </form>
        </div>
      </div>
    </div>
    </RootLayout>
    </>
  )
}

export default Forgot
