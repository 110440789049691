import { axiosService } from "../../axiosConfig";
import { useQuery } from "react-query";

const getEventPriceRange = async (eventId) => {
  const response = await axiosService.post("eventpricerange/", {
    eventId: eventId,
  });

  return response.data;
};

export const useGetEventPriceRange = (eventId, enabled = true) => {
  return useQuery({
    queryKey: ["getEventFromToken", eventId],
    queryFn: () => getEventPriceRange(eventId),
    onError: (error) => {
      console.log(error);
    },
    enabled: enabled,
  });
};
