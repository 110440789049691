import { useFormContext } from "react-hook-form";
import { ImageUpload } from "../../ImageUpload/ImageUpload";
import styles from "../organizerSetupWizard.module.css";

export const Page2 = () => {
  const methods = useFormContext();

  const handleUpload = (file) => {
    methods.setValue("pageTwo.file", file);
  };

  return (
    <form className={styles.wizardForm}>
      <h3>Add your logo</h3>
      <ImageUpload
        onChange={handleUpload}
        image={methods.getValues("pageTwo.file") || null}
      />
    </form>
  );
};
