export const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL
export const SEATS_IO_WORKSPACE_KEY =
  process.env.REACT_APP_SEATS_IO_WORKSPACE_KEY;
export const SEATS_IO_REGION = "na";
export const STRIPE_PUBLISHABLE_KEY =
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;

export const AWS_LOCATION_API_KEY =
  "v1.public.eyJqdGkiOiIxZGQxNzdkMS04OWIzLTRhZDQtYmFjZi03Zjc4M2ZiY2MzMjgifSHSCT8ShiFQLHWFafTupWq8bMGQ-e8rFxgLi6jIsoHyQTMM_9fW9JHo_eQS0HOujb60wWZHKN3uiAPPPN82qYPIs8al09G2lN_QBJID6NzuZ0T_Bthq54zw0phs_oyAGhPd0BWKmLk6XG6Mo6DhIDnnkGwDm74w167v3fVTbpzTzqsVoHedFDY35pInL3TKhiqfzncATE41Zu2I9PZ3cm-6jET-2XjDszTBqF-jkthWGTLpU-jwuskZn-EVarNAt5D_ysuL50fGwDRIs1WwVKeSd9yZH_zBVKXKdBbetdT9wS-FOA9H4gPz4ioAE4WTwsjSWTp_dQDymQ8ov--L3vo.NjAyMWJkZWUtMGMyOS00NmRkLThjZTMtODEyOTkzZTUyMTBi";
export const AWS_PLACE_INDEX_NAME = "EventVisionary";
export const AWS_REGION = "us-east-2";