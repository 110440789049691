import { useQuery} from "react-query";
import { axiosService } from "../../../api/axiosConfig";

const isStripeAccountConnected = async () => {
  const response = await axiosService.get(
    `stripe/is-stripe-account-connected/`
  );

  return response.data;
};

export const useIsStripeAccountConnected = () => {
    return useQuery({
        queryKey: ["isStripeAccountConnected"],
        queryFn: () => isStripeAccountConnected(),
        onError: (error) => console.log(error)
    })
}
