import { formatDateString } from "@/utils/dateTimeHelpers";
import styles from "./ticket.module.css";

export const Ticket = ({ data }) => {
  return (
    <div className={styles.ticket}>
      <div className={styles.ticketInfo}>
        <div className={styles.ticketInfoItem}>
          <span className={styles.ticketInfoLabel}>ID:</span>
          <span className={styles.ticketInfoValue}>{data?.id}</span>
        </div>
        <div className={styles.ticketInfoItem}>
          <span className={styles.ticketInfoLabel}>Full name:</span>
          <span className={styles.ticketInfoValue}>
            {data?.firstName} {data?.lastName}
          </span>
        </div>
        <div className={styles.ticketInfoItem}>
          <span className={styles.ticketInfoLabel}>Email:</span>
          <span className={styles.ticketInfoValue}>{data?.email}</span>
        </div>
        <div className={styles.ticketInfoItem}>
          <span className={styles.ticketInfoLabel}>Admitted:</span>
          <span className={styles.ticketInfoValue}>
            {data?.isAdmitted ? "YES" : "NO"}
          </span>
        </div>
        {data?.isAdmitted && (
          <div className={styles.ticketInfoItem}>
            <span className={styles.ticketInfoLabel}>Date admitted:</span>
            <span className={styles.ticketInfoValue}>
              {formatDateString(data?.admissionTime)}
            </span>
          </div>
        )}
        <div className={styles.ticketInfoItem}>
          <span className={styles.ticketInfoLabel}>Seat category:</span>
          <span className={styles.ticketInfoValue}>{data?.category}</span>
        </div>
        <div className={styles.ticketInfoItem}>
          <span className={styles.ticketInfoLabel}>Seat subcategory:</span>
          <span className={styles.ticketInfoValue}>{data?.subcategory}</span>
        </div>
      </div>
      <div className={styles.ticketImageContainer}>
        <img className={styles.ticketImage} src={data?.qrCode} />
      </div>
    </div>
  );
};
