import styles from "./organizerSetupWizard.module.css";
import { useState } from "react";
import { Page1 } from "./components/Page1";
import { Page2 } from "./components/Page2";
import { Page3 } from "./components/Page3";
import { FormProvider, useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useSubmitOrganizerSetupForm } from "./hooks/useSubmitOrganizerSetupForm";
import { useIsStripeAccountConnected } from "./hooks/useIsStripeAccountConnected";
import { getAuthenticationTokens } from "../../utils/tokenManager";
import { FaXmark } from "react-icons/fa6";
import { clsx } from "clsx";
import { Loader } from "../Loader/Loader";
import { useSearchParams } from "react-router-dom";
import { useGetUserFromId } from "../../api/hooks/useGetUserFromId";
import { ModalLayout } from "@/layouts/ModalLayout/ModalLayout";

const pageOneSchema = z.object({
  timezone: z.string(),
  email: z.string().email(),
  phone: z.string(),
  isEmailDisplay: z.boolean().optional(),
  isPhoneDisplay: z.boolean().optional(),
});

const pageTwoSchema = z.object({
  file: z.any().optional(),
});

const schema = z.object({
  pageOne: pageOneSchema,
  pageTwo: pageTwoSchema,
});

export const OrganizerSetupWizard = ({ close }) => {
  const methods = useForm({
    mode: "onChange",
    shouldUnregister: false,
    resolver: zodResolver(schema),
    defaultValues: {
      pageOne: {
        isEmailDisplay: true,
        isPhoneDisplay: true,
      },
      pageTwo: {
        file: undefined,
      },
    },
  });

  const [searchParams, _1] = useSearchParams();
  const success = searchParams.get("success");

  console.log(success);

  const mutation = useSubmitOrganizerSetupForm();

  const FINAL_PAGE = 3;
  const [currentPage, setCurrentPage] = useState(1);
  const { userId } = getAuthenticationTokens();

  const { data: userData, isSuccess: isGetUserDataSuccess } =
    useGetUserFromId(userId);

  const hasCompletedStripeOnboarding = Boolean(
    success && userData?.stripeAccountId !== ""
  );

  console.log(hasCompletedStripeOnboarding);

  const { data: isStripeConnectedData } = useIsStripeAccountConnected();

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleSkip = () => {
    if (currentPage < FINAL_PAGE) {
      setCurrentPage(currentPage + 1);
    } else {
      close();
    }
  };

  const PAGE_IDS = { 1: "pageOne", 2: "pageTwo", 3: "pageThree" };

  const submitForm = () => {
    const data = methods.getValues();
    mutation.mutate({ pageData: data, userId: userId });

    if (mutation.isSuccess) {
      close();
    }
  };

  const handleNext = async (e) => {
    const isValid = await methods.trigger(PAGE_IDS[currentPage]);

    if (isValid) {
      if (currentPage === FINAL_PAGE) {
        submitForm();
      } else {
        setCurrentPage(currentPage + 1);
      }
    }
  };

  return (
    <ModalLayout>
      <FormProvider {...methods}>
        <div className={styles.wizardContainer}>
          <button className={styles.wizardCloseButton} onClick={close}>
            <FaXmark />
          </button>
          <h2 className={styles.wizardTitle}>
            Setup Wizard: Step {currentPage}
          </h2>
          {currentPage === 1 && <Page1 />}
          {currentPage === 2 && <Page2 />}
          {currentPage === 3 && (
            <Page3 isStripeConnected={isStripeConnectedData?.connected} />
          )}

          <div className={styles.wizardButtonContainer}>
            <button
              className={clsx(
                styles.wizardButton,
                styles.wizardButtonTransparent
              )}
              disabled={currentPage === 1}
              onClick={handlePrevious}
            >
              Previous
            </button>
            <div className={styles.wizardButtonSubcontainer}>
              <button
                className={clsx(
                  styles.wizardButton,
                  styles.wizardButtonTransparent
                )}
                onClick={handleSkip}
              >
                Skip
              </button>

              <button
                className={clsx(
                  styles.wizardButton,
                  styles.wizardButtonPrimary
                )}
                type={currentPage === FINAL_PAGE ? "submit" : "button"}
                onClick={handleNext}
              >
                {currentPage === FINAL_PAGE ? (
                  <>{mutation.isLoading ? <Loader size="sm" /> : "Finish"}</>
                ) : (
                  "Next"
                )}
              </button>
            </div>
          </div>
        </div>
      </FormProvider>
    </ModalLayout>
  );
};
