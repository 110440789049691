import { Link } from "react-router-dom";
import styles from "../ticketPurchaseWizard.module.css";
import { generateViewTicketURL } from "@/utils/generateViewTicketURL";
import { useQueryClient } from "react-query";

export const PaymentSuccessful = ({ mode, itemsPurchased }) => {
  const queryClient = useQueryClient();
  const paymentIntentId = queryClient.getQueryData([
    "createPaymentIntent",
    JSON.stringify(itemsPurchased),
  ])?.intentId;

  return (
    <div className={styles.modalMainContent}>
      <div className={styles.paymentSuccessfulContainer}>
        <h1 className={styles.paymentSuccessfulTitle}>
          Tickets booked successfully!
        </h1>
        {mode === "CUSTOMER" ? (
          <>
            <p className={styles.paymentSuccessfulText}>
              We'll email your tickets to you as soon as possible. You can also
              view and download your tickets in PDF form{" "}
              <Link
                to={generateViewTicketURL(paymentIntentId)}
                className={styles.link}
                target="_blank"
              >
                here.
              </Link>{" "}
              Click the Finish button to close this wizard.
            </p>
            <p className={styles.paymentSuccessfulText}>
              Thank you for using Event Visionary
            </p>
          </>
        ) : (
          <>
            <p className={styles.paymentSuccessfulText}>
              These tickets have been booked. We'll email them to the email
              address you entered earlier. You can also view and download the
              tickets in PDF form{" "}
              <Link
                to={generateViewTicketURL(paymentIntentId)}
                className={styles.link}
                target="_blank"
              >
                here.
              </Link>{" "}
              Thank you for using Event Visionary
            </p>
          </>
        )}
      </div>
    </div>
  );
};
