import { AWS_LOCATION_API_KEY, AWS_REGION } from "@/constants/environment";
import { LocationClient } from "@aws-sdk/client-location";
import { withAPIKey } from "@aws/amazon-location-utilities-auth-helper";

const authHelper = await withAPIKey(AWS_LOCATION_API_KEY);

export const useAWSLocationClient = () => {
  const config = authHelper.getLocationClientConfig();
  const client = new LocationClient({ ...config, region: AWS_REGION });
  return client;
};
