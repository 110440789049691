import { REACT_APP_BASE_URL } from "@/constants/environment";
import { useMutation } from "react-query";

const downloadTicketsAsPDF = async (paymentIntentId) => {
  const PDF_URL = `${REACT_APP_BASE_URL}/api/tickets/download-tickets-as-pdf/${paymentIntentId}/`;
  const link = document.createElement("a");
  link.href = PDF_URL;
  link.download = "document.pdf";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const useDownloadTicketsAsPDF = (options = {}) => {
  return useMutation({
    mutationKey: "downloadTicketsAsPDF",
    mutationFn: (paymentIntentId) => downloadTicketsAsPDF(paymentIntentId),
    onError: (error) => {
      console.log(error);
    },
    ...options,
  });
};
