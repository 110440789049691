import {
  FaFacebook,
  FaFacebookF,
  FaInstagram,
  FaXTwitter,
} from "react-icons/fa6";
import styles from "./footer.module.css";
import { Link } from "react-router-dom";

const LINKS = [
  {
    label: "Facebook",
    icon: <FaFacebook />,
    href: "https://www.facebook.com/profile.php?id=61554004420226",
  },
  {
    label: "Instagram",
    icon: <FaInstagram />,
    href: "https://www.instagram.com/eventvisionary/?igshid=MzMyNGUyNmU2YQ%3D%3D",
  },
  {
    label: "X/Twitter",
    icon: <FaXTwitter />,
    href: "https://twitter.com/EventVisionary",
  },
];

export const Footer = () => {
  return (
    <div className={styles.footer}>
      <div className={styles.socialMediaContainer}>
        <h6 className={styles.socialMediaTitle}>Follow us</h6>
        <div className={styles.socialMediaIconContainer}>
          {LINKS.map((link) => (
            <Link
              className={styles.socialMediaIcon}
              to={link.href}
              key={link.label}
              target="_blank"
            >
              {link.icon}
            </Link>
          ))}
        </div>
        <span className={styles.copyright}>
          &copy;2023, Event Visionary LLC.
        </span>
      </div>
    </div>
  );
};
