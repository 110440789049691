import { useFormContext } from "react-hook-form";
import styles from "../ticketPurchaseWizard.module.css";
import { EventInfoHeader } from "../components/EventInfoHeader";
import clsx from "clsx";

export const BillingInfoForm = ({ mode }) => {
  const {
    watch,
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <div className={styles.modalMainContent}>
      <EventInfoHeader />
      <div className={styles.modalContentSection}>
        <h3 className={styles.modalContentSectionTitle}>Billing information</h3>
        <form className={styles.billingInfoForm}>
          <div className={styles.wizardFormInputContainer}>
            <input
              type="text"
              placeholder="First name *"
              className={styles.wizardFormInput}
              {...register("payment.billingInfo.firstName")}
            />
            {errors?.payment?.billingInfo?.firstName && (
              <p className={styles.wizardFormInputError}>
                {errors.payment.billingInfo.firstName.message}
              </p>
            )}
          </div>
          <div className={styles.wizardFormInputContainer}>
            <input
              type="text"
              placeholder="Last name *"
              className={styles.wizardFormInput}
              {...register("payment.billingInfo.lastName")}
            />
            {errors?.payment?.billingInfo?.lastName && (
              <p className={styles.wizardFormInputError}>
                {errors.payment.billingInfo.lastName.message}
              </p>
            )}
          </div>
          <div className={styles.wizardFormInputContainer}>
            <input
              type="email"
              placeholder="Email *"
              className={styles.wizardFormInput}
              {...register("payment.billingInfo.email")}
            />
            {errors?.payment?.billingInfo?.email && (
              <p className={styles.wizardFormInputError}>
                {errors.payment.billingInfo.email.message}
              </p>
            )}
          </div>

          <div className={styles.wizardFormInputContainer}>
            <input
              type="text"
              placeholder="Address line 1 *"
              className={styles.wizardFormInput}
              {...register("payment.billingInfo.addressLineOne")}
            />
            {errors?.payment?.billingInfo?.addressLineOne && (
              <p className={styles.wizardFormInputError}>
                {errors.payment.billingInfo.addressLineOne.message}
              </p>
            )}
          </div>
          <div className={styles.wizardFormInputContainer}>
            <input
              type="text"
              placeholder="Address line 2"
              className={styles.wizardFormInput}
              {...register("payment.billingInfo.addressLineTwo")}
            />
            {errors?.payment?.billingInfo?.addressLineTwo && (
              <p className={styles.wizardFormInputError}>
                {errors.payment.billingInfo.addressLineTwo.message}
              </p>
            )}
          </div>
          <div className={styles.wizardFormInputContainer}>
            <input
              type="text"
              placeholder="ZIP code *"
              className={styles.wizardFormInput}
              {...register("payment.billingInfo.zipCode")}
            />
            {errors?.payment?.billingInfo?.zipCode && (
              <p className={styles.wizardFormInputError}>
                {errors.payment.billingInfo.zipCode.message}
              </p>
            )}
          </div>
          <div className={styles.wizardFormInputContainer}>
            <input
              type="text"
              placeholder="City *"
              className={styles.wizardFormInput}
              {...register("payment.billingInfo.city")}
            />
            {errors?.payment?.billingInfo?.city && (
              <p className={styles.wizardFormInputError}>
                {errors.payment.billingInfo.city.message}
              </p>
            )}
          </div>
          <div className={styles.wizardFormInputContainer}>
            <input
              type="text"
              placeholder="State *"
              className={styles.wizardFormInput}
              {...register("payment.billingInfo.state")}
            />
            {errors?.payment?.billingInfo?.state && (
              <p className={styles.wizardFormInputError}>
                {errors.payment.billingInfo.state.message}
              </p>
            )}
          </div>

          <div className={styles.wizardFormInputContainer}>
            <input
              type="text"
              placeholder="Country *"
              className={styles.wizardFormInput}
              {...register("payment.billingInfo.country")}
            />
            {errors?.payment?.billingInfo?.country && (
              <p className={styles.wizardFormInputError}>
                {errors.payment.billingInfo.country.message}
              </p>
            )}
          </div>

          {mode === "ORGANIZER" && (
            <>
              <div className={styles.wizardFormInputContainer}>
                <input
                  type="text"
                  required={watch("payment.billingInfo.isTextTicketRequired")}
                  disabled={
                    watch("payment.billingInfo.isTextTicketRequired") === false
                  }
                  placeholder="Phone number"
                  className={styles.wizardFormInput}
                  {...register("payment.billingInfo.phone")}
                />
                {errors?.payment?.billingInfo?.phone && (
                  <p className={styles.wizardFormInputError}>
                    {errors.payment.billingInfo.phone.message}
                  </p>
                )}
                {mode === "ORGANIZER" && (
                  <div className={styles.checkboxContainer}>
                    <input
                      type="checkbox"
                      {...register("payment.billingInfo.isTextTicketRequired")}
                    />
                    <label>Text tickets?</label>
                  </div>
                )}
              </div>
              <div className={styles.orderNoteContainer}>
                <textarea
                  placeholder="Order note"
                  className={styles.orderNote}
                  {...register("payment.billingInfo.orderNote")}
                />
                {errors?.payment?.billingInfo?.orderNote && (
                  <p className={styles.wizardFormInputError}>
                    {errors.payment.billingInfo.orderNote.message}
                  </p>
                )}
              </div>
            </>
          )}
        </form>
      </div>
    </div>
  );
};
