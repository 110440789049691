import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { STRIPE_PUBLISHABLE_KEY } from "@/constants/environment";
import { useCreatePaymentIntent } from "../hooks/useCreatePaymentIntent";
import { useParams } from "react-router-dom";
import { getAuthenticationTokens } from "@/utils/tokenManager";
import { useGetEventFromToken } from "@/api/hooks/events/useGetEventFromToken";
import styles from "../ticketPurchaseWizard.module.css";
import { SkeletonLoader } from "@/components/SkeletonLoader/SkeletonLoader";
import { useQueryClient } from "react-query";
import { ErrorNotification } from "@/components/ErrorNotification/ErrorNotification";

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

// Data looks like this
// const data = {
//   seatsToPurchase: SeatData[]
//   currency: "USD"
// }

export const StripeCheckoutWrapper = ({
  currency,
  purchaseType = "SEATS",
  itemsToPurchase,
  children,
}) => {
  // This is where you should create the PaymentIntent
  const { token: eventToken } = useParams();
  const { userId } = getAuthenticationTokens();
  const queryClient = useQueryClient();
  const {
    data: eventData,
    isLoading: isGetEventDataLoading,
    isSuccess: isGetEventDataSuccess,
    isError: isGetEventDataError,
  } = useGetEventFromToken(eventToken, Boolean(eventToken));

  const billingInfoId = queryClient.getQueryData(
    "submitBillingInformation"
  )?.id;

  const {
    data: createPaymentIntentData,
    isLoading: isCreatePaymentIntentLoading,
    isSuccess: isCreatePaymentIntentSuccess,
    isError: isCreatePaymentIntentError,
  } = useCreatePaymentIntent(
    {
      eventId: eventData?.event?.id,
      userId: userId,
      billingInfoId: billingInfoId,
      itemsToPurchase: itemsToPurchase,
      purchaseType: purchaseType,
      currency: currency,
    },
    isGetEventDataSuccess
  );

  if (isGetEventDataLoading || isCreatePaymentIntentLoading) {
    return (
      <div className={styles.modalMainContent}>
        <SkeletonLoader
          width="100%"
          height="100%"
          style={{ minHeight: "50vh" }}
        />
      </div>
    );
  }

  if (isGetEventDataError || isCreatePaymentIntentError) {
    return (
      <div className={styles.modalMainContent}>
        <div className={styles.stripeElementsContainer}>
          <ErrorNotification width="100%" height="100%" />
        </div>
      </div>
    );
  }
  if (isCreatePaymentIntentSuccess) {
    const options = {
      clientSecret: createPaymentIntentData?.clientSecret,
      appearance: {
        theme: "stripe",
      },
    };

    return (
      <div className={styles.modalMainContent}>
        <div className={styles.stripeElementsContainer}>
          <Elements options={options} stripe={stripePromise}>
            {children}
          </Elements>
        </div>
      </div>
    );
  }
};
