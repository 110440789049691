import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { useGetStripeConfirmationToken } from "../hooks/useGetStripeConfirmationToken";
import styles from "../ticketPurchaseWizard.module.css";
import { Button } from "../../Button/Button";
import { useConfirmPayment } from "../hooks/useConfirmPayment";
import { useQueryClient } from "react-query";
import { Loader } from "@/components/Loader/Loader";
import { useGetEventFromToken } from "@/api/hooks/events/useGetEventFromToken";
import { useParams } from "react-router-dom";

export const StripeCardDetailsForm = ({
  endPaymentProcess,
  itemsToPurchase,
}) => {
  const { token: eventToken } = useParams();

  const stripe = useStripe();
  const elements = useElements();
  const queryClient = useQueryClient();
  const confirmationTokenOptions = {
    elements,
    params: {
      return_url: `${window.location.href}?${new URLSearchParams({
        success: true,
      }).toString()}`,
    },
  };
  const getStripeConfirmationToken = useGetStripeConfirmationToken();

  const handleConfirmPayment = async (event) => {
    event.preventDefault();
    elements.submit();
    getStripeConfirmationToken.mutateAsync({
      stripe: stripe,
      options: confirmationTokenOptions,
    });
  };

  const paymentIntentId = queryClient.getQueryData([
    "createPaymentIntent",
    JSON.stringify(itemsToPurchase),
  ])?.intentId;

  console.log("Payment intent id from card form is: ", paymentIntentId);

  const {
    data: eventData,
    isLoading: isGetEventDataLoading,
    isSuccess: isGetEventDataSuccess,
  } = useGetEventFromToken(eventToken, Boolean(eventToken));

  const confimPayment = useConfirmPayment(
    {
      paymentIntentId: paymentIntentId,
      itemsToPurchase: itemsToPurchase,
      confirmationToken:
        getStripeConfirmationToken?.data?.confirmationToken?.id,
      purchaseType: eventData?.event?.isSeatingReserved ? "SEATS" : "TICKETS",
    },
    getStripeConfirmationToken.isSuccess && isGetEventDataSuccess,
    {
      onSuccess: () => {
        endPaymentProcess();
      },
    }
  );

  return (
    <form className={styles.billingInfoForm}>
      <div className={styles.stripePaymentElementContainer}>
        <div className={styles.stripePaymentElement}>
          <PaymentElement />
        </div>
        <Button
          type="submit"
          size="sm"
          onClick={handleConfirmPayment}
          disabled={
            getStripeConfirmationToken.isLoading ||
            isGetEventDataLoading ||
            confimPayment.isLoading
          }
          variant="gradient"
          style={{ width: "10rem" }}
        >
          {confimPayment.isLoading ||
          getStripeConfirmationToken.isLoading ||
          isGetEventDataLoading ? (
            <Loader />
          ) : (
            "Confirm payment"
          )}
        </Button>
      </div>
    </form>
  );
};
