import { useMutation } from "react-query";

const getStripeConfirmationToken = async (stripe, confirmPaymentOptions) => {
  console.log("Stripe is", stripe);
  console.log("Options are: ", confirmPaymentOptions);

  const response = await stripe.createConfirmationToken(confirmPaymentOptions);
  return response;
};

export const useGetStripeConfirmationToken = () => {
  return useMutation({
    mutationKey: "getStripeConfirmationToken",
    mutationFn: (data) =>
      getStripeConfirmationToken(data?.stripe, data?.options),
    onSuccess: (data) => {
      console.log(data);
    },
    onError: (error) => {
      console.log(error);
    },
  });
};
