import { axiosService } from "@/api/axiosConfig";
import { useQuery } from "react-query";

const getSeatsIoHoldToken = async (eventToken) => {
  const response = await axiosService.get("seatioholdtoken/");
  return response.data;
};

export const useGetSeatsIoHoldToken = (eventToken, enabled = true) => {
  return useQuery({
    queryKey: ["getSeatsIoHoldToken", eventToken],
    queryFn: () => getSeatsIoHoldToken(eventToken),
    onError: (error) => {
      console.log(error);
    },
    enabled: enabled,
  });
};
