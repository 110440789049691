import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import "./global.css";
import App from "./App";
import Pricing from "./(root)/Pricing/page";
import EventsPage from "./(root)/EventsPage/page.js";
import NewEvent from "./(root)/NewEvent/page";
import Dashboard from "./(root)/Dashboard/page.js";
import Event from "./(root)/Event/page.js";
import Archived from "./(root)/archived/page.js";
import Draft from "./(root)/Draft/page.js";
import EventDash from "./(root)/eventdash/page.js";
import SellTickets from "./(root)/sellTickets/page.js";
import Hold_Seats from "./(root)/managetwo/page.js";
import Attendees from "./(root)/attendees/page.js";
import ManageOrder from "./(root)/ManageOrder/page.js";
import Eventdetails from "./(root)/eventdetails/page.js";
import Ticketprices from "./(root)/ticketprices/page.js";
import SettingChart from "./(root)/settingChart/page.js";
import Venues from "./(root)/venues/page.js";
import TrialOPT from "./(root)/TrialOPT/page.js";
import Reports from "./(root)/reports/page.js";
import Settingdash from "./(root)/settingdash/page.js";
import Myaccount from "./(root)/myaccount/page.js";
import About from "./(root)/About/page.js";
import ArchivedAttendees from "./(root)/ArchivedAttendess/page.js";
import ArchivedChart from "./(root)/ArchivedChart/page.js";
import ArchivedEvent from "./(root)/ArchivedEvent/page.js";
import ArchivedOrder from "./(root)/ArchivedOrder/page.js";
import ArchivedPrice from "./(root)/ArchivedPrice/page.js";
import CustomerAccount from "./(root)/CustomerAccount/page.js";
import DefineTicket from "./(root)/DefineTicket/page.js";
import Events from "./(root)/Events/page.js";
import Forgot from "./(root)/forgot/page.js";
import UpdatePassword from "./(root)/UpdatePassword/page.js";
import Keywords from "./(root)/Keywords/page.js";
import Login from "./(root)/Login/page.js";
import Login_org from "./(root)/Login_org/page.js";
import Sign_Up from "./(root)/Sign_Up/page.js";
import Sign_Up_org from "./(root)/Sign_Up_org/page.js";
import MyPlan from "./(root)/MyPlan/page.js";
import MyPurchases from "./(root)/MyPurchases/page.js";
import PublishEvent from "./(root)/PublishEvent/page.js";
import Setting from "./(root)/Setting/page.js";
import ShareEvent from "./(root)/ShareEvent/page.js";
import SingleEvent from "./(root)/SingleEvent/page.js";
import TicketPF from "./(root)/(ticket)/TicketsPF/page.js";

import {
  BrowserRouter as Router,
  ScrollRestoration,
  Route,
  Routes,
  Navigate,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { SingleEventPage } from "./(root)/SingleEventPage/SingleEventPage.jsx";
import { ErrorBoundary } from "@/components/ErrorBoundary/ErrorBoundary.jsx";
import { ScanTicket } from "./(root)/ScanTicket/ScanTicket";
import { Bounce, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ViewTickets } from "./(root)/ViewTickets/ViewTickets";

const root = ReactDOM.createRoot(document.getElementById("root"));
const authToken = localStorage.getItem("authToken");
let get_role = "";

if (authToken) {
  get_role = localStorage.getItem("role");
  console.log("role is ", get_role);
}

const client = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      {authToken ? (
        <>
          <Route path="/" element={<App />} />
          <Route path="/Pricing" element={<Pricing />} />
          <Route
            path="/organizer/dashboard/create-an-event-1"
            element={<NewEvent />}
          />
          <Route path="/organizer/dashboard" element={<Dashboard />} />
          <Route path="/organizer/dashboard/events" element={<Event />} />
          <Route path="/archived" element={<Archived />} />
          <Route path="/Draft" element={<Draft />} />
          <Route
            path="/organizer/dashboard/events/:token"
            element={<EventDash />}
          />
          <Route
            path="/organizer/dashboard/events/:token/sell-tickets"
            element={<SellTickets />}
          />
          <Route path="/managetwo/:eventId" element={<Hold_Seats />} />
          <Route path="/scan-ticket/:eventId" element={<ScanTicket />} />
          <Route
            path="/organizer/dashboard/events/:eventId/manage-attendees"
            element={<Attendees />}
          />
          <Route path="/ManageOrder/:eventId" element={<ManageOrder />} />
          <Route path="/eventdetails/:eventId" element={<Eventdetails />} />
          <Route path="/ticketprices/:eventId" element={<Ticketprices />} />
          <Route path="/settingChart/:chartKey" element={<SettingChart />} />
          <Route path="/organizer/dashboard/venues" element={<Venues />} />
          <Route path="/TrialOPT" element={<TrialOPT />} />
          <Route path="/organizer/dashboard/reports" element={<Reports />} />
          <Route
            path="//organizer/dashboard/settings"
            element={<Settingdash />}
          />
          <Route path="/organizer/dashboard/account" element={<Myaccount />} />
          <Route path="/About" element={<About />} />
          <Route path="/ArchivedAttendess" element={<ArchivedAttendees />} />
          <Route path="/ArchivedChart" element={<ArchivedChart />} />
          <Route path="/ArchivedEvent" element={<ArchivedEvent />} />
          <Route path="/ArchivedOrder" element={<ArchivedOrder />} />
          <Route path="/ArchivedPrice" element={<ArchivedPrice />} />
          <Route path="/CustomerAccount" element={<CustomerAccount />} />
          <Route
            path="/organizer/dashboard/create-an-event-2/:eventId"
            element={<DefineTicket />}
          />
          <Route path="/forgot" element={<Forgot />} />
          <Route path="/updatepassword/:token" element={<UpdatePassword />} />
          <Route path="/Keywords" element={<Keywords />} />
          <Route path="/Login" element={<Login />} />
          <Route path="/login/organizer" element={<Login_org />} />
          <Route path="/Sign_Up" element={<Sign_Up />} />
          <Route path="/signup/organizer" element={<Sign_Up_org />} />
          <Route path="/MyPlan" element={<MyPlan />} />
          <Route path="/MyPurchases" element={<MyPurchases />} />
          <Route
            path="/organizer/dashboard/create-an-event-3/:eventId"
            element={<PublishEvent />}
          />
          <Route path="/Setting" element={<Setting />} />
          <Route
            path="/organizer/dashboard/create-an-event-4/:eventId"
            element={<ShareEvent />}
          />
          <Route path="/events/:organizer" element={<Events />} />
          {/* <Route
          path="/events/:organizer/:title/:date/:token"
          element={<SingleEvent />}
        /> */}
          <Route
            path="/events/:organizer/:title/:date/:token"
            element={<SingleEventPage />}
            errorElement={<ErrorBoundary />}
          />
          <Route
            path="/events/id/:token"
            element={<SingleEventPage />}
            errorElement={<ErrorBoundary />}
          />
          <Route path="/scan-ticket/:eventId" element={<ScanTicket />} />
          <Route
            path="/view-tickets/:paymentIntentId"
            element={<ViewTickets />}
          />
          <Route path="/events" element={<EventsPage />} />
          {/* <Route path="/SingleEvent/:token" element={<SingleEvent />} /> */}
          {/* Define additional pages/routes */}
        </>
      ) : (
        <>
          <Route path="/" element={<Navigate to="/login/organizer" />} />
          <Route path="/Pricing" element={<Pricing />} />
          <Route path="/Login" element={<Login />} />
          <Route path="/Sign_Up" element={<Sign_Up />} />
          <Route path="/signup/organizer" element={<Sign_Up_org />} />
          <Route path="/login/organizer" element={<Login_org />} />
          <Route path="/forgot" element={<Forgot />} />
          <Route path="/events/:organizer" element={<Events />} />
          <Route
            path="/events/:organizer/:title/:date/:token"
            element={<SingleEventPage />}
          />
          <Route
            path="/events/id/:token"
            element={<SingleEventPage />}
            errorElement={<ErrorBoundary />}
          />
          <Route path="/events" element={<EventsPage />} />
          <Route path="/updatepassword/:token" element={<UpdatePassword />} />
          <Route
            path="/view-tickets/:paymentIntentId"
            element={<ViewTickets />}
          />
          {/* Catch-all route */}

          <Route
            path="/*"
            element={<Navigate to="/login/organizer" />}
            errorElement={<ErrorBoundary />}
          />

          {/* Add other login/signup related routes if needed */}
        </>
      )}
    </>
  )
);

root.render(
  <React.StrictMode>
    <QueryClientProvider client={client}>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        transition={Bounce}
      />
      <RouterProvider router={router} />
    </QueryClientProvider>
  </React.StrictMode>
);
