export const formatCurrency = (
  amount,
  locale = navigator.language,
  currency = "USD"
) => {
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency,
  }).format(amount);
};
