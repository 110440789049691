import { useMutation } from "react-query";

const { axiosService } = require("@/api/axiosConfig");

const updateEvent = async (eventId, data) => {
  const response = await axiosService.patch(`events/${eventId}/`, data);
  return response.data;
};

export const useUpdateEvent = (eventId, options = {}) => {
  return useMutation({
    mutationKey: ["updateEvent", eventId],
    mutationFn: (data) => updateEvent(eventId, data),
    onError: (error) => {
      console.log(error);
    },
    ...options,
  });
};
