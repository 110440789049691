import { axiosService } from "@/api/axiosConfig";
import { useQuery } from "react-query";

const getAllTicketsForPaymentIntent = async (paymentIntentId) => {
  const response = await axiosService.post(
    "tickets/get-all-tickets-for-payment-intent/",
    {
      paymentIntentId: paymentIntentId,
    }
  );
  return response.data;
};

export const useGetAllTicketsForPaymentIntent = (
  paymentIntentId,
  enabled = true,
  options = {}
) => {
  return useQuery({
    queryKey: ["getAllTicketsForPaymentIntent", paymentIntentId],
    queryFn: () => getAllTicketsForPaymentIntent(paymentIntentId),
    onError: (error) => {
      console.log(error);
    },
    enabled: enabled,
    staleTime: Infinity,
    ...options,
  });
};
