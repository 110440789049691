import clsx from "clsx";
import styles from "./loader.module.css";

const LoaderStyles = {
  sm: styles.sm,
  md: styles.md,
  lg: styles.lg,
  xl: styles.xl,
};
export const Loader = ({ size = "sm", variant }) => {
  return (
    <div
      className={clsx(
        styles.loader,
        LoaderStyles[size],
        variant === "white" && styles.white
      )}
    ></div>
  );
};
