import SubMenus, { Silder_icon } from '../../components/SubMenus/SubMenus'
// import Link from 'next/link'
// import React from 'react'
import { CiCirclePlus } from 'react-icons/ci'
import { FaRegUser, FaSearch } from 'react-icons/fa'
import { FaGear, FaRegMessage } from 'react-icons/fa6'
import { IoSpeedometerOutline } from 'react-icons/io5'
import { RiMoneyDollarCircleLine } from 'react-icons/ri'
import { SlLocationPin } from 'react-icons/sl'
import { RootLayout } from "@/layouts/RootLayout/RootLayout";
import React, { useState, useEffect, useCallback } from 'react'
import RevenueRow from '../../components/RevenueRow/RevenueRow'
import { saveAs } from 'file-saver';
import { FinishOnboardingButton } from "@/components/FinishOnboardingButton/FinishOnboardingButton";

const Reports = () => {
  const [revenues, setRevenues] = useState([]);
  const [filteredRevenuesList, setFilteredRevenuesList] = useState([]);

  const [authtoken, setAuthtoken] = useState([]);
  const [events, setEvents] = useState([]);

  const [tickets, setTickets] = useState(0);
  const [amount, setAmount] = useState(0);
  const [tax, setTax] = useState(0);
  const [servicefee, setServicefee] = useState(0);

  const [csvData, setCsvData] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [start_date, setStart_date] = useState("");
  const [end_date, setEnd_date] = useState("");

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Function to handle input change
  const handleInputChange = (e) => {
    // Update the search input value state
    setSearchInput(e.target.value);
  };
  // Function to handle input change
  const handleStartDateChange = (e) => {
    // Update the search input value state
    setStart_date(e.target.value);
  };
  // Function to handle input change
  const handleEndDateChange = (e) => {
    // Update the search input value state
    setEnd_date(e.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const authToken = localStorage.getItem("authToken");
        setAuthtoken(authToken);

        if (!authToken) {
          console.error("Authentication token not found", authToken);
        }
        const authUserId = localStorage.getItem("authUserId");

        if (!authUserId) {
          console.error("Authentication user id   found");
        }
        const requestBody = JSON.stringify({
          user: parseInt(authUserId),
        });
        console.log("body is ", requestBody);
        // const token = 'e0d25a4a3fda989bf969bc5971a9e36878ece9f2';
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/revenuesofuser/`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Token ${authToken}`,
            },
            body: requestBody,
          }
        );
        if (!response.ok) {
          console.log("in error side, response is: ", response);
          console.error("Failed to fetch data");
        }
        const jsonData = await response.json();
        setRevenues(jsonData.revenues);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const calculateTotals = () => {
      let totalTickets = 0;
      let totalAmount = 0;
      let totalTax = 0;
      let totalServiceFee = 0;

      filteredRevenuesList.forEach((revenue) => {
        totalTickets += revenue.revenue_total_tickets;
        totalAmount += revenue.revenue_amount;
        totalTax += revenue.revenue_total_tax;
        totalServiceFee += revenue.revenue_total_service_fees;
      });

      setTickets(totalTickets);
      setAmount(parseFloat((totalAmount / 100).toFixed(2)));
      setTax(parseFloat(totalTax.toFixed(2)));
      setServicefee(parseFloat(totalServiceFee.toFixed(2))); // Limit to two decimal places
    };
    calculateTotals();
  }, [filteredRevenuesList]);

  useEffect(() => {
    // Function to filter venues based on search query
    const filterRevenues = (query) => {
      const filterRevenues = revenues.filter((revenue) => {
        // Perform case-insensitive search on venue name
        const lowerCaseQuery = query.toLowerCase();

        return (
          revenue.revenue_amount.toString().includes(query) ||
          revenue.revenue_total_tickets.toString().includes(query) ||
          revenue.revenue_amount.toString().includes(query) ||
          revenue.revenue_event.Event_Name.toLowerCase().includes(
            lowerCaseQuery
          )
        );
      });
      // Update the filteredVenuesList state with the filtered venues
      setFilteredRevenuesList(filterRevenues);
    };

    filterRevenues(searchInput);
  }, [searchInput, revenues]);

  useEffect(() => {
    // Function to filter venues based on search query
    const filterRevenues = (start_date, end_date, filtRev) => {
      console.log("start ", start_date);
      console.log("end_date ", end_date);
      const filtRevenues = filtRev.filter((revenue) => {
        const revenueDate = new Date(revenue.created)
          .toISOString()
          .split("T")[0];
        console.log("revenueDate ", revenueDate);
        return revenueDate >= start_date && revenueDate <= end_date;
      });
      // Update the filteredVenuesList state with the filtered venues
      setFilteredRevenuesList(filtRevenues);
    };

    if (start_date && end_date) {
      const start = new Date(start_date).toISOString().split("T")[0];
      const end = new Date(end_date).toISOString().split("T")[0];
      if (start < end || start === end) {
        filterRevenues(start, end, revenues);
      }
    }
  }, [start_date, end_date]);

  const convertToCsv = () => {
    const header =
      Array.from(document.querySelectorAll(".manage_filter_tables thead th"))
        .map((th) => th.innerText)
        .join(",") + "\n";
    const rows = Array.from(
      document.querySelectorAll(".manage_filter_tables tbody tr")
    )
      .map((tr) =>
        Array.from(tr.children)
          .map((td) => td.innerText)
          .join(",")
      )
      .join("\n");
    const csv = header + rows;
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8" });
    const csvData = URL.createObjectURL(blob);
    setCsvData(csvData);
    saveAs(blob, "table_data.csv");
  };
  return (
    <>
      <RootLayout>
        <div className="main_container">
          <div className="dashboard_main">
            <div className="dashboard_section">
              <div className="sidebar ">
                <ul className="nav-links">
                  <Silder_icon />
                  <FinishOnboardingButton />
                  <li>
                    <a href="/organizer/dashboard">
                      <IoSpeedometerOutline className=" menu_dash_i" />
                      <span className="link_name">Dashboard</span>
                    </a>
                  </li>
                  <li>
                    <div className="iocn-link">
                      <a href="/organizer/dashboard/create-an-event-1">
                        <CiCirclePlus className=" menu_dash_i" />

                        <span className="link_name ">CREATE AN EVENT</span>
                      </a>
                    </div>
                  </li>
                  <li>
                    <SubMenus />
                  </li>
                  <li>
                    <a href="/organizer/dashboard/venues">
                      <SlLocationPin className=" menu_dash_i" />
                      <span className="link_name">VENUES</span>
                    </a>
                  </li>
                  <li>
                    <a href="/organizer/dashboard/reports">
                      <RiMoneyDollarCircleLine className="yellow_m menu_dash_i" />
                      <span className="link_name yellow_m">REPORTS</span>
                    </a>
                  </li>
                  <li>
                    <a href="/organizer/dashboard/settings">
                      <FaGear className=" menu_dash_i" />
                      <span className="link_name">SETTINGS</span>
                    </a>
                  </li>
                  {/* <li>
                                <div className="iocn-link">
                                    <Link href="/SmsCampaigns">
                                        <FaRegMessage className=" menu_dash_i" />
                                        <span className="link_name">SMS CAMPAIGNS</span>
                                    </Link>
                                </div>
                            </li> */}
                  <li>
                    <div className="iocn-link">
                      <a href="/organizer/dashboard/account">
                        <FaRegUser className=" menu_dash_i" />
                        <span className="link_name">MY ACCOUNT</span>
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
              <section className="home-section">
                <div className="home-content">
                  <div className="manage_order_area">
                    <div className="manage_order_section">
                      <h1>Manage Orders: Event Title Show Here</h1>
                      <form action="" className="manage_order_form">
                        <div className="manage_filter_row">
                          <div className="manage_filter_in">
                            <input
                              type="search"
                              value={searchInput}
                              onChange={handleInputChange}
                              placeholder="Search by name, order #, or amount..."
                            />
                            {/* <i className='bx bx-search'></i> */}
                            <FaSearch style={{ color: "#000" }} />
                          </div>
                        </div>
                        <div className="manage_filter_d">
                          <div className="filterid">
                            <label htmlFor="">Filter by Date:</label>
                            <input
                              type="date"
                              className="white_txt"
                              value={start_date}
                              onChange={handleStartDateChange}
                            />
                          </div>
                          --
                          <div className="filterid">
                            <input
                              type="date"
                              className="white_txt"
                              value={end_date}
                              onChange={handleEndDateChange}
                            />
                          </div>
                        </div>
                        <button
                          type="button"
                          className="manage_filter_btn"
                          onClick={convertToCsv}
                        >
                          Export CSV
                        </button>
                      </form>
                    </div>
                    <div className="manage_filter_table">
                      <table className="manage_filter_tables">
                        <thead>
                          <tr>
                            <th>Event Title</th>
                            <th>Event Start Date</th>
                            <th># of Tickets Sold</th>
                            <th>Total Revenue</th>
                            <th>Total Service Fees</th>
                            <th>Taxes</th>
                          </tr>
                        </thead>
                        <tbody>
                          {filteredRevenuesList.map((revenue) => (
                            <RevenueRow
                              key={revenue.id}
                              revenue={revenue}
                              searchInput={searchInput}
                              setSearchInput={setSearchInput}
                            />
                          ))}
                          <tr className="yellow_tables">
                            <td></td>
                            <td>Totals</td>
                            <td>{tickets}</td>
                            <td>${amount.toFixed(2)}</td>
                            <td>${(servicefee / 100).toFixed(2)}</td>
                            <td>${(tax / 100).toFixed(2)}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </RootLayout>
    </>
  );
};

export default Reports