import React, { useState, useEffect } from 'react'


const RevenueRow = ({ revenue , searchInput, setSearchInput }) => {
    const [eventData, setEventData] = useState(null);
    // const [filteredEventData, setFilteredEventData] = useState(null);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        // Format the date as desired
        const formattedDate = date.toLocaleDateString(); // Format: MM/DD/YYYY
        return formattedDate;
    };
    return (
      <tr>
        <td>{revenue.revenue_event.Event_Name}</td>
        <td>{formatDate(revenue.revenue_event.created)}</td>
        <td>{revenue.revenue_total_tickets}</td>
        <td>${parseFloat((revenue.revenue_amount / 100).toFixed(2))}</td>
        <td>
          ${parseFloat((revenue.revenue_total_service_fees / 100).toFixed(2))}
        </td>
        <td>${parseFloat((revenue.revenue_total_tax / 100).toFixed(2))}</td>
      </tr>
    );
};

export default RevenueRow;