import { Link, useParams } from "react-router-dom";
import styles from "./scanTicket.module.css";
import { useGetEventFromId } from "@/api/hooks/events/useGetEventFromId";
import { SkeletonLoader } from "@/components/SkeletonLoader/SkeletonLoader";
import { ErrorNotification } from "@/components/ErrorNotification/ErrorNotification";
import { QRCodeScanner } from "@/components/QRCodeScanner/QRCodeScanner";
import { useCallback, useState } from "react";
import { useMarkTicketAsAdmitted } from "./hooks/useMarkTicketAsAdmitted";
// import clsx from "clsx";
import { useGetTicketDetails } from "./hooks/useGetTicketDetails";
import { formatDateString } from "@/utils/dateTimeHelpers";
import { toast } from "react-toastify";
import { RootLayout } from "@/layouts/RootLayout/RootLayout";
import { FaArrowLeft } from "react-icons/fa6";
import { useDebounce } from "@uidotdev/usehooks";
import clsx from "clsx";

export const ScanTicket = () => {
  const { eventId } = useParams();
  const [ticketId, setTicketId] = useState("");
  const markTicketAsAdmitted = useMarkTicketAsAdmitted(
    { ticketId: ticketId, eventId: eventId },
    ticketId.length > 5,
    {
      onSuccess: () => {
        toast("This person has been admitted successfully!", {
          type: "success",
        });
      },
      onError: (error) => {
        const message = error?.response?.data?.message;
        console.log(message);
        if (message === "MISMATCHED_TICKET") {
          toast("Invalid ticket. This ticket doesn't belong to this event!", {
            type: "error",
          });
        } else if (message === "ALREADY_ADMITTED") {
          toast("This person has already been admitted!", { type: "error" });
        }
      },
    }
  );

  const {
    data: eventData,
    isLoading: isGetEventDataLoading,
    isError: isGetEventDataError,
  } = useGetEventFromId(eventId, Boolean(eventId), {
    disableCaseConversion: false,
  });

  const {
    data: ticketDetails,
    isLoading: isGetTicketDetailsLoading,
    isError: isGetTicketDetailsError,
    isSuccess: isGetTicketDetailsSuccess,
  } = useGetTicketDetails(ticketId, ticketId.length > 0);

  const handleClear = () => {
    setTicketId("");
  };

  const handleScan = (scannedTicketId) => {
    console.log("Scanned ticket id is: ", scannedTicketId);
    setTicketId(scannedTicketId);
    markTicketAsAdmitted.mutate();
  };

  if (isGetEventDataLoading) {
    return (
      <div className={styles.page}>
        <div className={styles.cameraContainer}>
          <SkeletonLoader width="100%" height="100%" />
        </div>
      </div>
    );
  }

  if (isGetEventDataError) {
    return (
      <div className={styles.page}>
        <div className={styles.cameraContainer}>
          <ErrorNotification width="100%" height="100%" />
        </div>
      </div>
    );
  }

  return (
    <RootLayout>
      <div className={styles.page}>
        <div className={styles.container}>
          <div className={styles.mainContainer}>
            <Link
              to={`/organizer/dashboard/events/${eventData?.uniqueToken}`}
              className={styles.backButton}
            >
              <FaArrowLeft />
              <span>Return to event page</span>
            </Link>
            <div className={styles.header}>
              <p>Scan tickets: </p>
              <p className={styles.eventTitle}>{eventData?.eventName}</p>
            </div>
            <div className={styles.cameraContainer}>
              <p>Center the viewfinder on the printed QR code</p>
              <div className={styles.camera}>
                <QRCodeScanner onScan={handleScan} />
              </div>
            </div>
            {ticketId && (
              <div className={styles.actionButtonContainer}>
                {/* <button
                  className={clsx(styles.button, styles.admit)}
                  onClick={handleMarkAsAdmitted}
                >
                  Mark as Admitted
                </button> */}
                <button
                  className={clsx(styles.button, styles.clear)}
                  onClick={handleClear}
                >
                  Clear
                </button>
              </div>
            )}
          </div>
          <div className={styles.infoContainer}>
            <div className={styles.ticketIdContainer}>
              <span>Last scanned ticket ID</span>
              <p className={styles.ticketId}>{ticketId}</p>
            </div>
            {isGetTicketDetailsLoading && (
              <div className={styles.userDetailsContainer}>
                <SkeletonLoader width="100%" height="15rem" />
              </div>
            )}
            {isGetTicketDetailsError && (
              <div className={styles.userDetailsContainer}>
                <ErrorNotification width="100%" height="10rem" />
              </div>
            )}
            {isGetTicketDetailsSuccess && (
              <div className={styles.userDetailsContainer}>
                <div className={styles.userDetailItem}>
                  <span className={styles.userDetailLabel}>Seat category:</span>
                  <span className={styles.userDetailValue}>
                    {ticketDetails?.category || "N/A"}
                  </span>
                </div>
                <div className={styles.userDetailItem}>
                  <span className={styles.userDetailLabel}>
                    Seat subcategory:
                  </span>
                  <span className={styles.userDetailValue}>
                    {ticketDetails?.subcategory || "N/A"}
                  </span>
                </div>
                <div className={styles.userDetailItem}>
                  <span className={styles.userDetailLabel}>Full name:</span>
                  <span className={styles.userDetailValue}>
                    {ticketDetails?.firstName} {ticketDetails?.lastName}
                  </span>
                </div>
                <div className={styles.userDetailItem}>
                  <span className={styles.userDetailLabel}>Email:</span>
                  <span className={styles.userDetailValue}>
                    {ticketDetails?.email}
                  </span>
                </div>
                <div className={styles.userDetailItem}>
                  <span className={styles.userDetailLabel}>Phone number:</span>
                  <span className={styles.userDetailValue}>
                    {ticketDetails?.phone || "N/A"}
                  </span>
                </div>
                <div className={styles.userDetailItem}>
                  <span className={styles.userDetailLabel}>
                    Date purchased:
                  </span>
                  <span className={styles.userDetailValue}>
                    {formatDateString(ticketDetails?.created)}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </RootLayout>
  );
};
