import { getAuthenticationTokens } from "@/utils/tokenManager";
import styles from "./finishOnboardingButton.module.css";
import { useUserHasOnboarded } from "@/api/hooks/useUserHasOnboarded";
import { OrganizerSetupWizard } from "../OrganizerSetupWizard/OrganizerSetupWizard";
import { useState } from "react";

export const FinishOnboardingButton = () => {
  const { userId } = getAuthenticationTokens();
  const [showOnboardingWizard, setShowOnboardingWizard] = useState(false);

  const {
    data: userData,
    isError: isGetUserDataError,
    isSuccess: isGetUserDataSuccess,
  } = useUserHasOnboarded(userId);

  if (isGetUserDataSuccess && userData?.isOnBoard) {
    return <></>;
  }
  const handleCloseWizard = () => {
    setShowOnboardingWizard(false);
  };

  const handleShowOnboardingWizard = () => {
    setShowOnboardingWizard(true);
  };

  return (
    <>
      {showOnboardingWizard && (
        <OrganizerSetupWizard close={handleCloseWizard} />
      )}
      <button
        className={styles.onboardingLink}
        onClick={handleShowOnboardingWizard}
      >
        Finish onboarding!
      </button>
    </>
  );
};
