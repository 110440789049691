import { axiosService } from "@/api/axiosConfig";
import { useMutation } from "react-query";

const releaseSeat = async (data) => {
  const response = await axiosService.post("releaseeventseat/", data);
  return response.data;
};

export const useReleaseSeat = (enabled = true) => {
  return useMutation({
    mutationKey: "releaseSeat",
    mutationFn: (data) => releaseSeat(data),
    onError: (error) => {
      console.log(error);
    },
    enabled: enabled,
  });
};
