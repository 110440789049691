'use client'
import React, { useState } from 'react';
import './index.css'
// import Link from 'next/link';
import { RootLayout } from "@/layouts/RootLayout/RootLayout";

const Sign_Up_org = () => {

  const [first_name, setFirstName] = useState('');
  const [last_name, setLastName] = useState('');
  const [business_name, setBusinessName] = useState('');


  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [selectedButton, setSelectedButton] = useState('Organizer');
  
  const handleButtonClick = (buttonName) => {
    console.log("old name is :", selectedButton);
    setSelectedButton(buttonName);
    
    console.log("new name is :", selectedButton);
    
  };


  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
  }

  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
  }

  const handleBusinessNameChange = (event) => {
    setBusinessName(event.target.value);
  }

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  }

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  }

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  }

  
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!first_name || !last_name || !business_name || !email || !password || !confirmPassword) {
      setError('Please fill in all fields');
      return;
    }

    // Validate email format
    const emailRegex = /^\S+@\S+\.\S+$/;
    if (!emailRegex.test(email)) {
      setError('Please enter a valid email address');
      return;
    }
    // Validate password length
    if (password.length < 8) {
      setError('Password must be at least 8 characters long');
      return;
    }
    // Check if passwords match
    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }
    const role = selectedButton
    const requestedBody = JSON.stringify({
      first_name, last_name, business_name, email, password , role
    });
    console.log("request body is : ", requestedBody)
    // Call your REST API to submit the form
    try {
      // Make HTTP request to authenticate user
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/signup/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: requestedBody,
      });

      if (!response.ok) {
        setError('Authentication failed'); // Set error message
        console.error('Authentication failed');
        console.log('response failed', response);
        
      }
      const data = await response.json();
      const authToken = data.token;
      const authUserId = data.user.id;
      localStorage.setItem('authToken', authToken);
      localStorage.setItem('authUserId', authUserId);



      // Call your API here and handle success/failure
      alert('SignUp submitted successfully!');
      
      window.location.href = "/organizer/dashboard";
    } catch (error) {
      console.error('Error submitting form:', error);
      setError('An error occurred while submitting the form');
    }
  };


  return (
    <>
      <RootLayout>
    <div className='main_container'>
      <div className="login_container">
        <div className="login_section">

          <div className="coustomer_form ">
            <h2>ORGANIZERS</h2>

            {error && <div className="error_message">{error}</div>}
            <form  onSubmit={handleSubmit} className="login_cous">
              <h2>Sign Up</h2>
              <label htmlFor="">First Name:</label>
              <input type="text" value={first_name} onChange={handleFirstNameChange} /> 
              <label htmlFor="">Last Name:</label>
              <input type="text" value={last_name} onChange={handleLastNameChange} />
              <label htmlFor="" >Business Name:</label>
              <input type="text" value={business_name} onChange={handleBusinessNameChange}/>
              <label htmlFor="">Role:</label>
              <div className="sign_up_btns">
                <button
                  type="button"
                  onClick={() => handleButtonClick('Organizer')}
                  style={{
                    background: selectedButton === 'Organizer' ? 'linear-gradient(90deg, rgba(190,158,68,1) 0%, rgba(216,198,144,1) 100%)' : ''
                  }} 
                  >
                  Organizer
                </button>
                <button
                  type="button"
                  onClick={() => handleButtonClick('Venue')}
                  style={{
                     background: selectedButton === 'Venue' ? 'linear-gradient(90deg, rgba(190,158,68,1) 0%, rgba(216,198,144,1) 100%)' : ''
                    }}
                >
                  Venue
                </button>
                <button
                  type="button"
                  onClick={() => handleButtonClick('Other')}
                  style={{ 
                    background: selectedButton === 'Other' ? 'linear-gradient(90deg, rgba(190,158,68,1) 0%, rgba(216,198,144,1) 100%)' : ''
                   }}
                >
                  Other
                </button>
              </div>


              <label htmlFor="">Email</label>
              <input type="email" value={email} onChange={handleEmailChange} required />
              <label htmlFor="">Password</label>
              <input type="password" value={password} onChange={handlePasswordChange} required />
              <label htmlFor="">Confirm Password</label>
              <input type="password" value={confirmPassword} onChange={handleConfirmPasswordChange} required />

              <button type="submit">Sign Up</button>

            </form>
            <div className="sign_btnss">

              <p>Already have an account?</p>
              <a href="/login/organizer" className="cursor_pointer" >Login</a>
            </div>
            <a href="/Sign_Up">Or Sign up as <span className='yellow_cr'>Customer</span> </a>

          </div>
        </div>
      </div>
    </div>
    </RootLayout>
    </>
  )
}

export default Sign_Up_org
