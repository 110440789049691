import { useParams } from "react-router-dom";
import styles from "../ticketPurchaseWizard.module.css";
import { useGetEventFromToken } from "../../../api/hooks/events/useGetEventFromToken";
import {
  SEATS_IO_WORKSPACE_KEY,
  SEATS_IO_REGION,
} from "../../../constants/environment";
import { EventInfoHeader } from "../components/EventInfoHeader";
import { SeatsioSeatingChart } from "@seatsio/seatsio-react";
import { useGetEventPricingDataFromToken } from "../../../api/hooks/events/useGetEventPricingDataFromToken";
import { useGetSeatsIoHoldToken } from "../hooks/useGetSeatsIoHoldToken";
import { formatCurrency } from "../../../utils/formatCurrency";
import { useGetEventSeatCategoryData } from "../hooks/useGetEventSeatCategoryData";
import {
  formatPricingDataFromCategories,
  getCategoryIdsFromName,
} from "../helpers/seatHelpers";

export const SeatPicker = ({ onSelectSeat, onDeselectSeat, mode }) => {
  const { token: eventToken } = useParams();

  const {
    data: eventData,
    isLoading: isGetEventDataLoading,
    isError: isGetEventDataError,
    isSuccess: isGetEventDataSuccess,
  } = useGetEventFromToken(eventToken, Boolean(eventToken));

  const {
    data: holdTokenData,
    isLoading: isGetHoldTokenDataLoading,
    isError: isGetHoldTokenDataError,
    isSuccess: isGetHoldTokenDataSuccess,
  } = useGetSeatsIoHoldToken(eventToken, Boolean(eventToken));

  const {
    data: eventSeatCategoryData,
    isLoading: isGetEventSeatCategoryDataLoading,
    isError: isGetEventSeatCategoryDataError,
    isSuccess: isGetEventSeatCategoryDataSuccess,
  } = useGetEventSeatCategoryData(
    eventData?.event?.id,
    Boolean(eventData?.event?.id)
  );

  const {
    data: eventPricingData,
    isLoading: isGetEventPricingDataLoading,
    isError: isGetEventPricingDataError,
    isSuccess: isGetEventPricingDataSuccess,
  } = useGetEventPricingDataFromToken(eventToken, Boolean(eventToken));

  const categoryNameToIdMap = getCategoryIdsFromName(
    eventSeatCategoryData?.categories
  );
  const handleSelectSeat = (seatObject) => {
    console.log(seatObject);
    onSelectSeat({
      ...seatObject,
      computedCategoryId: categoryNameToIdMap[seatObject?.category?.label],
    });
  };

  const handleDeselectSeat = (seatObject) => {
    onDeselectSeat(seatObject);
  };

  const pricing = formatPricingDataFromCategories(
    eventSeatCategoryData?.categories
  );

  return (
    <div className={styles.modalMainContent}>
      <EventInfoHeader />
      <div className={styles.seatPickerContainer}>
        {isGetHoldTokenDataSuccess && isGetEventSeatCategoryDataSuccess && (
          <SeatsioSeatingChart
            workspaceKey={SEATS_IO_WORKSPACE_KEY}
            event={eventData?.event?.eventId}
            holdToken={holdTokenData?.token}
            pricing={eventPricingData?.pricing}
            priceFormatter={formatCurrency}
            // meaning that boxOfficeCategories are available for the organizer, but not for the customer
            unavailableCategories={
              mode === "CUSTOMER" ? eventPricingData?.boxOfficeCategories : []
            }
            showFullScreenButton
            showSectionPricingOverlay
            session="manual"
            inputDevice="auto"
            confirmSelectionOnMobile="auto"
            region={SEATS_IO_REGION}
            onObjectSelected={handleSelectSeat}
            onObjectDeselected={handleDeselectSeat}
          />
        )}
      </div>
    </div>
  );
};
