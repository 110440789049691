import { useState } from "react";
import styles from "./imageUpload.module.css";
import { FiUpload } from "react-icons/fi";

export const ImageUpload = ({ onChange = null, image = null }) => {
  const [uploadedImage, setUploadedImage] = useState(image || null);

  const handleFileUpload = (file) => {
    setUploadedImage(file);
    if (onChange) {
      onChange(file);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      // Do something with the uploaded image file
      handleFileUpload(file);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const file = e.dataTransfer.files[0];
      // Do something with the dropped image file
      handleFileUpload(file);
    }
  };

  return (
    <div
      className={styles.uploadContainer}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      <input
        type="file"
        id="fileInput"
        accept="image/*"
        style={{ display: "none" }}
        onChange={(e) => handleFileUpload(e.target.files[0])}
      />
      <label htmlFor="fileInput">
        {/* <img src={Upload_img} alt="Upload Icon"  />  */}
        {uploadedImage &&
        (uploadedImage instanceof Blob ||
          uploadedImage instanceof MediaSource) ? (
          <img
            src={URL.createObjectURL(uploadedImage)}
            alt="Uploaded icon"
            className={styles.uploadImage}
          />
        ) : (
          <div className={styles.uploadPlaceholder}>
            <FiUpload className={styles.uploadPlaceholderIcon} />
            <p className={styles.uploadPlaceholderText}>
              Drag and drop image here or click to upload
            </p>
          </div>
        )}
      </label>
    </div>
  );
};
