import { Scanner } from "@yudiel/react-qr-scanner";
import styles from "./qrCodeScanner.module.css";

export const QRCodeScanner = ({ onScan }) => {
  const handleScan = (data) => {
    onScan(data[0].rawValue);
  };
  return (
    <div className={styles.container}>
      <Scanner onScan={handleScan} allowMultiple />
    </div>
  );
};
