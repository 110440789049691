import { generatePath, Link, useNavigate, useParams } from "react-router-dom";
import { RootLayout } from "@/layouts/RootLayout/RootLayout";
import styles from "./viewTickets.module.css";
import { useGetAllTicketsForPaymentIntent } from "@/api/hooks/tickets/useGetAllTicketsForPaymentIntent";
import { SkeletonLoader } from "@/components/SkeletonLoader/SkeletonLoader";
import { Ticket } from "./components/Ticket";
import { FaDownload } from "react-icons/fa6";
import { useDownloadTicketsAsPDF } from "./hooks/useDownloadTicketsAsPDF";
import { Loader } from "@/components/Loader/Loader";
import { useGetEventFromId } from "@/api/hooks/events/useGetEventFromId";
import { ErrorNotification } from "@/components/ErrorNotification/ErrorNotification";
import { EventRoutes } from "@/routes/EventRoutes";

export const ViewTickets = () => {
  const { paymentIntentId } = useParams();
  const navigate = useNavigate();

  const {
    data: tickets,
    isLoading: isGetTicketsLoading,
    isSuccess: isGetTicketsSuccess,
    isError: isGetTicketsError,
  } = useGetAllTicketsForPaymentIntent(
    paymentIntentId,
    Boolean(paymentIntentId)
  );

  const { data: eventData } = useGetEventFromId(
    tickets?.[0]?.eventId,
    isGetTicketsSuccess && tickets?.length !== 0,
    {
      disableCaseConversion: false,
    }
  );

  console.log(eventData);

  const downloadTicketsAsPDF = useDownloadTicketsAsPDF();

  const handleDownloadTicketsAsPDF = () => {
    downloadTicketsAsPDF.mutate(paymentIntentId);
  };

  console.log(tickets);

  if (isGetTicketsLoading) {
    return (
      <RootLayout>
        <div className={styles.container}>
          <SkeletonLoader width="100%" height="100%" />
        </div>
      </RootLayout>
    );
  }

  if (isGetTicketsError || (isGetTicketsSuccess && tickets?.length == 0)) {
    return (
      <RootLayout>
        <div className={styles.container}>
          <ErrorNotification width="100%" height="100%" />
        </div>
      </RootLayout>
    );
  }

  console.log(eventData);

  const eventLink = generatePath(EventRoutes.VIEW_EVENT, {
    token: eventData?.uniqueToken || "",
  });

  return (
    <RootLayout>
      <div className={styles.container}>
        <div className={styles.header}>
          <h1 className={styles.title}>
            Your {tickets?.length > 1 ? "tickets" : "ticket"} to{" "}
            <Link className={styles.eventTitle} to={eventLink} target="_blank">
              {eventData?.eventName}
            </Link>
          </h1>

          <button
            className={styles.downloadButton}
            onClick={handleDownloadTicketsAsPDF}
          >
            {downloadTicketsAsPDF.isLoading ? (
              <Loader />
            ) : (
              <>
                <FaDownload />
                <span>Download as PDF</span>
              </>
            )}
          </button>
        </div>
        <div className={styles.ticketContainer}>
          {tickets?.map((ticket) => (
            <Ticket data={ticket} key={ticket?.id} />
          ))}
        </div>
      </div>
    </RootLayout>
  );
};
