import { useQuery } from "react-query"
import { axiosService } from "../../../api/axiosConfig"
import { REACT_APP_BASE_URL } from "../../../constants/environment";

const generateOnboardingLink = async (userId) => {
    const response = await axiosService.post(
      "stripe/get-connect-account-link/",
      {
        userId: userId,
        refreshUrl: window.location.href,
        returnUrl: `${window.location.href}?${new URLSearchParams({
          success: true,
        }).toString()}`,
      }
    );

    return response.data;
}

export const useGenerateOnboardingLink = (userId, enabled = true) => {
  return useQuery({
    queryKey: ["generateOnboardingLink", userId],
    queryFn: () => generateOnboardingLink(userId),
    onError: (error) => console.log(error),
    enabled: enabled,
  });
};