import { One_img, Rectangle } from '../../public';
// import Image from 'next/image'; // Uncomment this line if you want to use next/image
import { NavLink } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';
import { HiOutlineDotsHorizontal } from 'react-icons/hi';
import styles from "./eventsCard.module.css";
import { convertTo12HourFormat, formatDate } from "../../utils/dateTimeHelpers";

const EventsCard = ({
  showMenuButton = true,
  eventTitle = "",
  eventDateTime = "",
  venueName = "",
  TicketType = "",
  TicketHref = "",
  imge,
  eventId = 0,
  unique_token,
}) => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null); // Reference to the menu container

  const [selectedEvent, setSelectedEvent] = useState(eventId);

  const [pauseStatus, setPauseStatus] = useState(false);
  const [publishStatus, setPublishStatus] = useState(false);
  const [archiveStatus, setArchiveStatus] = useState(false);
  const [cancelStatus, setCancelStatus] = useState(false);
  const [endStatus, setEndStatus] = useState(false);
  const [eventImageUrl, setEventImageUrl] = useState("");
  let base_api_url = `${process.env.REACT_APP_BASE_URL}`;

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // const authToken = localStorage.getItem('authToken');
        // if (!authToken) {
        //     console.error('Authentication token not found');
        // }

        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/events/${eventId}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          console.error("Failed to fetch event data");
        }

        const eventData = await response.json();
        setSelectedEvent(eventData);

        // Set all status values based on eventData
        setPauseStatus(eventData.is_paused);
        setPublishStatus(eventData.is_published);
        setArchiveStatus(eventData.is_archived);
        setCancelStatus(eventData.is_cancelled);
        setEndStatus(eventData.is_ended);
        setEventImageUrl(eventData.Event_image || imge);
        let baseUrl = `${base_api_url}`;

        console.log("1 : baseUrl: ", baseUrl);
        if (
          !baseUrl.includes("http://127.0.0.1") &&
          !baseUrl.includes("localhost")
        ) {
          // Check if the base_api_url_images already contains a port number

          console.log("2 : inside if: ");
          const urlObject = new URL(baseUrl);
          console.log("2 A : afteurlObjectr  if: ", urlObject);
          // if (!urlObject.port) {
          //     console.log("3 : inside 8000 if: ");
          //     // Append ':8000' to base_api_url_images
          //     baseUrl = `${base_api_url}:8000`;
          //     console.log("4 : baseUrl 8000 if: ", baseUrl);
          // }
        }
        // var imageURL = imge;

        // if (imageURL.startsWith('/images')) {
        //     imageURL = `${baseUrl}${imageURL}`;
        //     setEventImageUrl(imageURL);
        // }
        // else{
        //     let imageEventUrl = new URL((imageURL));
        //     // if (!imageEventUrl.port && !imageEventUrl.hostname.includes('localhost')) {
        //     //     imageEventUrl.port = '8000';
        //     // }
        //     setEventImageUrl(imageEventUrl);

        // }

        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchData();
  }, [eventId]);

  // Handle status updates in a reusable function
  const handleStatusUpdate = async (statusValue, setStatusFunction) => {
    try {
      const authToken = localStorage.getItem("authToken");
      if (!authToken) {
        console.error("Authentication token not found");
      }

      const body = JSON.stringify({
        [statusValue]: !selectedEvent[statusValue], // Toggle the status value
      });

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/events/${selectedEvent.id}/`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${authToken}`,
          },
          body: body,
        }
      );

      if (!response.ok) {
        console.error("Failed to update event information");
      }

      setStatusFunction((prevStatus) => !prevStatus); // Toggle the local status value
    } catch (error) {
      console.error("Error updating event information:", error);
    }
  };

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setMenuOpen(false);
    }
  };

  // Add click event listener to handle clicks outside the menu
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  function ensurePort(url) {
    try {
      const urlObject = new URL(url);
      // if (!urlObject.port && !urlObject.hostname.includes('localhost')) {
      //     urlObject.port = '8000';
      // }
      // console.log()
      return urlObject.toString();
    } catch (error) {
      console.error("Invalid URL provided:", url);
      return url; // Return the original URL if parsing fails
    }
  }

  return (
    <div className={styles.eventCard}>
      <img src={eventImageUrl} alt="" className={styles.eventCardImg} />
      <div className="card_info">
        <p>{eventTitle || selectedEvent?.Event_Name}</p>
        <p>
          {eventDateTime ||
            formatDate(selectedEvent?.start_date) *
              convertTo12HourFormat(selectedEvent?.start_time)}{" "}
        </p>
        <p>{venueName}</p>
        <div className="card_menu">
          <a href={TicketHref} className="btn sm">
            {TicketType}
          </a>
          {showMenuButton && (
            <div className="menu_container">
              <HiOutlineDotsHorizontal
                className="card_btn"
                onClick={toggleMenu}
              />
              {isMenuOpen && (
                <div className="sub_card_menu">
                  {/* Add your menu items here */}
                  <button
                    className="btn_black_bg"
                    onClick={() =>
                      handleStatusUpdate("is_paused", setPauseStatus)
                    }
                  >
                    {pauseStatus ? "Resume Ticket Sales" : "Pause Ticket Sales"}
                  </button>
                  <button
                    className="btn_black_bg"
                    onClick={() => handleStatusUpdate("is_ended", setEndStatus)}
                  >
                    {endStatus ? "Start Ticket Sales" : "End Ticket Sales"}
                  </button>
                  <button
                    className="btn_black_bg"
                    onClick={() =>
                      handleStatusUpdate("is_cancelled", setCancelStatus)
                    }
                  >
                    {cancelStatus ? "Allow Event" : "Cancel Event"}
                  </button>
                  <button
                    className="btn_black_bg"
                    onClick={() =>
                      handleStatusUpdate("is_published", setPublishStatus)
                    }
                  >
                    {publishStatus ? "Publish Event" : "Un-publish Event"}
                  </button>
                  <button
                    className="btn_black_bg"
                    onClick={() =>
                      handleStatusUpdate("is_archived", setArchiveStatus)
                    }
                  >
                    {archiveStatus ? "Un-Archive Event" : "Archive Event"}
                  </button>
                  {/* <a href="/ticketprices">End Ticket Sales</a> */}
                  {/* <a href="/eventdetails">Cancel Event</a> */}
                  {/* <a href="/eventdetails">Un-publish Event</a>
                                    <a href="/eventdetails">Archive Event</a> */}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EventsCard
