import { axiosService } from "../../axiosConfig";
import { useQuery } from "react-query";

const getEventPricingDataFromToken = async (eventToken) => {
  const response = await axiosService.post("pricingofevent/", {
    token: eventToken,
  });

  return response.data;
};

export const useGetEventPricingDataFromToken = (eventToken, enabled = true) => {
  return useQuery({
    queryKey: ["getEventPricingDataFromToken", eventToken],
    queryFn: () => getEventPricingDataFromToken(eventToken),
    onError: (error) => {
      console.log(error);
    },
    enabled: enabled || !!eventToken,
  });
};
