import { Link } from "react-router-dom";
import styles from "../organizerSetupWizard.module.css";
import { getAuthenticationTokens } from "../../../utils/tokenManager";
import { useGenerateOnboardingLink } from "../hooks/useGenerateOnboardingLink";
import { FaCircleCheck } from "react-icons/fa6";
import { useIsStripeAccountConnected } from "../hooks/useIsStripeAccountConnected";

export const Page3 = ({ isStripeConnected = false }) => {
  const { userId } = getAuthenticationTokens();
  const { data: isStripeConnectedData, isSuccess: isStripeConnectedSuccess } =
    useIsStripeAccountConnected();

  const {
    data: generateLinkData,
    isLoading: isGenerateLinkLoading,
    isSuccess: isGenerateLinkSuccess,
    isError: isGenerateLinkError,
  } = useGenerateOnboardingLink(
    userId,
    isStripeConnectedSuccess && !isStripeConnectedData?.connected
  );

  return (
    <form className={styles.wizardForm}>
      <h3>Connect with Stripe</h3>
      {isStripeConnected ? (
        <>
          <p className={styles.wizardFormParagraph}>
            Your Stripe account is already connected to Event Visionary. Click
            the Finish button to close this wizard.
          </p>
          <div className={styles.textConnected}>
            <FaCircleCheck />
            <span>Connected</span>
          </div>
        </>
      ) : (
        <>
          {" "}
          <p className={styles.wizardFormParagraph}>
            Use the link below to connect your Event Visionary account with
            Stripe. This will be used to send payouts when customers purchase
            tickets to your events.
          </p>
          {isGenerateLinkLoading && (
            <span className={styles.loadingLink}>Generating URL...</span>
          )}
          {isGenerateLinkSuccess && (
            <Link
              to={generateLinkData?.url || "#"}
              className={styles.wizardLink}
            >
              Connect my Stripe account{" "}
            </Link>
          )}
          {isGenerateLinkError && (
            <span className={styles.errorLink}>
              Unable to generate Stripe account URL
            </span>
          )}
        </>
      )}
    </form>
  );
};
