import styles from "./ageRestrictionBadge.module.css";
import { clsx } from "clsx";

export const AgeRestrictionBadge = ({ restriction = "ALL_AGES" }) => {
  return (
    <div
      className={clsx(
        styles.badge,
        restriction === "18" && styles.eighteen,
        restriction === "21" && styles.twentyOne
      )}
    >
      <span className={styles.badgeText}>
        {restriction === "ALL_AGES" && "None"}
        {restriction === "18" && "18+"}
        {restriction === "21" && "21+"}
      </span>
    </div>
  );
};
