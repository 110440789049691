import { useQuery } from "react-query";
import { axiosService } from "@/api/axiosConfig";

const confirmPayment = async (
  paymentIntentId,
  confirmationToken,
  purchaseType
) => {
  const response = await axiosService.post("stripe/confirm-payment-intent/", {
    paymentIntentId: paymentIntentId,
    confirmationToken: confirmationToken,
    purchaseType: purchaseType,
  });

  return response.data;
};

export const useConfirmPayment = (data, enabled = false, options = {}) => {
  return useQuery({
    queryKey: ["confirmPayment", JSON.stringify(data)],
    queryFn: () =>
      confirmPayment(
        data?.paymentIntentId,
        data?.confirmationToken,
        data?.purchaseType
      ),
    onError: (error) => {
      console.log(error);
    },
    enabled: enabled,
    ...options,
  });
};
