import { useState } from "react";
import styles from "../ticketPurchaseWizard.module.css";
import { clsx } from "clsx";

export const TicketPurchaseWidget = ({
  onUpdateQuantity = (newQuantity) => {},
  maxQuantity = Infinity,
}) => {
  const [quantity, setQuantity] = useState(0);
  const computedMaxQuantity = maxQuantity === -1 ? Infinity : maxQuantity;

  const handleAddTicket = () => {
    if (quantity < computedMaxQuantity) {
      const newQuantity = quantity + 1;
      onUpdateQuantity(newQuantity);
      setQuantity(newQuantity);
    }
  };

  const handleSubtractTicket = () => {
    if (quantity > 0) {
      const newQuantity = quantity - 1;
      onUpdateQuantity(newQuantity);
      setQuantity(newQuantity);
    }
  };

  return (
    <div
      className={clsx(
        styles.ticketPurchaseWidget,
        maxQuantity === 0 && styles.disabledTicketPurchaseWidget
      )}
    >
      <button
        className={styles.ticketPurchaseWidgetButton}
        onClick={handleSubtractTicket}
      >
        <span>-</span>
      </button>
      <div className={styles.ticketPurchaseWidgetQuantity}>
        <span>{quantity}</span>
      </div>
      <button
        className={styles.ticketPurchaseWidgetButton}
        onClick={handleAddTicket}
      >
        <span>+</span>
      </button>
    </div>
  );
};
