import { useRouteError } from "react-router-dom";
import { ErrorNotification } from "../ErrorNotification/ErrorNotification";
import { RootLayout } from "@/layouts/RootLayout/RootLayout";
import styles from "./errorBoundary.module.css";

const ERROR_MESSAGES = [
  {
    errorCode: 404,
    heading: "Page not found",
    text: "The link you've followed doesn't exist. Please verify that it's correct, or head to our Home page",
  },
];

export const ErrorBoundary = () => {
  const error = useRouteError();

  return (
    <RootLayout>
      <ErrorNotification width="100%" height="90vh">
        <h2 className={styles.heading}>Something went wrong</h2>
        <p className={styles.text}>
          EventVisionary is still very much a work-in-progress. Please check
          back soon to see updates!
        </p>
      </ErrorNotification>
    </RootLayout>
  );
};
