import { ModalLayout } from "@/layouts/ModalLayout/ModalLayout";
import { TicketPurchaseWizard } from "./TicketPurchaseWizard";
import styles from "./ticketPurchaseWizard.module.css";
import { useNavigate, useParams } from "react-router-dom";

export const TicketPurchaseWizardWrapper = ({ mode = "CUSTOMER", onClose }) => {
  const { token: eventToken } = useParams();
  const navigate = useNavigate();

  if (mode === "CUSTOMER") {
    return (
      <ModalLayout>
        <TicketPurchaseWizard mode="CUSTOMER" onClose={onClose} />
      </ModalLayout>
    );
  } else {
    return (
      <div className={styles.container}>
        <TicketPurchaseWizard
          mode="ORGANIZER"
          onClose={() => {
            navigate(`/organizer/dashboard/events/${eventToken}`);
          }}
        />
      </div>
    );
  }
};
