import { axiosService } from "@/api/axiosConfig";
import { useQuery } from "react-query";

const getTicketDetails = async (ticketId) => {
  const response = await axiosService.get(
    `tickets/get-ticket-details/${ticketId}/`
  );
  return response.data;
};

export const useGetTicketDetails = (ticketId, enabled = true) => {
  return useQuery({
    queryKey: ["getTicketDetails", ticketId],
    queryFn: () => getTicketDetails(ticketId),
    onError: (error) => {
      console.log(error);
    },
    enabled: enabled,
  });
};
