'use client'
import SubMenus, { Silder_icon } from '../../components/SubMenus/SubMenus'
import { Ellis_3, Publish_img } from '../../public'
// import Image from 'next/image'
// import Link from 'next/link'
import React,{useState, useEffect, useRef} from 'react'
import { CiCirclePlus } from 'react-icons/ci'
import { FaFacebookF, FaLinkedin, FaRegUser, FaTwitter } from 'react-icons/fa'
import { FaGear, FaRegMessage } from 'react-icons/fa6'
import { IoCopyOutline, IoSpeedometerOutline } from 'react-icons/io5'
import { RiMoneyDollarCircleLine } from 'react-icons/ri'
import { SlLocationPin } from 'react-icons/sl'
import { MdExitToApp } from "react-icons/md";
import { RootLayout } from "@/layouts/RootLayout/RootLayout";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import "./index.css";
import { FinishOnboardingButton } from "@/components/FinishOnboardingButton/FinishOnboardingButton";

const ShareEvent = () => {
  const navigate = useNavigate();
  const { eventId } = useParams();
  const inputRef = useRef(null);
  const [uniqureUrl, setUniqureUrl] = useState([]);
  const [organizers, setOrganizers] = useState({});

  const handleCopyToClipboard = () => {
    navigator.clipboard
      .writeText(uniqureUrl)
      .then(() => {
        console.log("Text copied to clipboard:", uniqureUrl);
        // Optionally, you can show a success message to the user
      })
      .catch((error) => {
        console.error("Failed to copy text to clipboard:", error);
        // Optionally, you can show an error message to the user
      });
  };

  const getOrganizer = async (userId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/users/${userId}/`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        console.error("Failed to fetch user data");
        return "";
      }

      const userData = await response.json();
      let orgName = userData.business_name || "";
      orgName = orgName.toLowerCase().replace(/ /g, "-");
      console.log("new name is ", orgName);
      return orgName;
    } catch (error) {
      console.error("Error fetching organizer data:", error);
      return "";
    }
  };
  const createSlug = (name) => {
    return name.toLowerCase().replace(/ /g, "-");
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const authToken = localStorage.getItem("authToken");

        if (!authToken) {
          console.error("Authentication token not found");
        }
        const authUserId = localStorage.getItem("authUserId");

        if (!authUserId) {
          console.error("Authentication user id   found");
        }

        // const token = 'e0d25a4a3fda989bf969bc5971a9e36878ece9f2';

        // Fetch user data
        const eventResponse = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/events/${eventId}`,
          {
            headers: {
              Authorization: `Token ${authToken}`,
            },
          }
        );
        if (!eventResponse.ok) {
          console.error("Failed to fetch event data");
        }
        const eventData = await eventResponse.json();
        const unique_token = eventData.unique_token;
        const baseUrl = window.location.origin; // Get the base URL of the current page
        const orgName = await getOrganizer(eventData.user);
        const newUrl = `${baseUrl}/events/${orgName || ""}/${createSlug(
          eventData.Event_Name
        )}/${eventData.start_date}/${eventData.unique_token}`;
        setUniqureUrl(newUrl);
      } catch (error) {
        console.error("Failed to fetch event data", error);
      }
    };

    // Call the fetchData function
    fetchData();
  }, []); // Include eventId in the dependency array to trigger the effect when it changes

  return (
    <>
      <RootLayout>
        <div className="main_container">
          <div className="dashboard_main">
            <div className="dashboard_section">
              <div className="sidebar ">
                <ul className="nav-links">
                  <Silder_icon />
                  <FinishOnboardingButton />
                  <li>
                    <a href="/organizer/dashboard">
                      <IoSpeedometerOutline className=" menu_dash_i" />
                      <span className="link_name">Dashboard</span>
                    </a>
                  </li>
                  <li>
                    <div className="iocn-link">
                      <a href="/organizer/dashboard/create-an-event-1">
                        <CiCirclePlus className="yellow_m menu_dash_i" />

                        <span className="link_name yellow_m">
                          CREATE AN EVENT
                        </span>
                      </a>
                    </div>
                  </li>
                  <li>
                    <SubMenus />
                  </li>
                  <li>
                    <a href="/organizer/dashboard/venues">
                      <SlLocationPin className=" menu_dash_i" />
                      <span className="link_name">VENUES</span>
                    </a>
                  </li>
                  <li>
                    <a href="/organizer/dashboard/reports">
                      <RiMoneyDollarCircleLine className=" menu_dash_i" />
                      <span className="link_name">REPORTS</span>
                    </a>
                  </li>
                  <li>
                    <a href="/organizer/dashboard/settings">
                      <FaGear className=" menu_dash_i" />
                      <span className="link_name">SETTINGS</span>
                    </a>
                  </li>
                  {/* <li>
                                <div className="iocn-link">
                                    <Link href="/SmsCampaigns">
                                        <FaRegMessage className=" menu_dash_i" />
                                        <span className="link_name">SMS CAMPAIGNS</span>
                                    </Link>
                                </div>
                            </li> */}
                  <li>
                    <div className="iocn-link">
                      <a href="/organizer/dashboard/account">
                        <FaRegUser className=" menu_dash_i" />
                        <span className="link_name">MY ACCOUNT</span>
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
              <section className="home-section">
                <div className="home-content">
                  <div className="tabs_container">
                    <div className="w3-bar w3-black tabs_section">
                      <a
                        className="   w3-red tab_btn  team-member"
                        href="/organizer/dashboard/create-an-event-1"
                      >
                        1. Enter Event Details
                      </a>
                      <a className="   tab_btn  team-member" href="#">
                        2. Define Ticket Prices
                      </a>
                      <a
                        className="   tab_btn  team-member team-member-small"
                        href="#"
                      >
                        3. Publish Event
                      </a>
                      <a
                        className="   tab_btn  team-member team-member-small"
                        href="#"
                      >
                        4. share Event
                        <img
                          src={Ellis_3}
                          alt=""
                          className="team-member-info one_active"
                        />
                      </a>
                    </div>
                    <div id="share" className="w3-container w3-border city">
                      <div className="share_event">
                        <p className="share_event_title">
                          Your event has been published successfully
                        </p>
                        <div className="share_portion">
                          <div className="exit_event">
                            <p>You can view your event here</p>
                            {/* <img src="../imgs/enter 1.png" alt=""/> */}
                            <Link to={uniqureUrl} target="_blank">
                              <MdExitToApp className="share_link_i" />
                            </Link>
                          </div>
                          <h3>Share Options</h3>
                          <div className="share_link">
                            <p>Shareable Link:</p>
                            <Link
                              to={uniqureUrl}
                              className="shareable_link"
                              target="_blank"
                            >
                              {uniqureUrl}
                            </Link>
                            {/* <img src="../imgs/copy 1.png" alt=""/>/ */}
                            <IoCopyOutline
                              className="share_link_i"
                              onClick={handleCopyToClipboard}
                            />
                          </div>
                          <div className="directly">
                            <p>Share Directly:</p>
                            <div className="share_icon">
                              <FaFacebookF className="share_icon_i" />
                              <FaLinkedin className="share_icon_i" />
                              <FaTwitter className="share_icon_i" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="share_btns">
                        <a
                          className="w3-bar-item w3-button tablink w3-red tab_btn new_envet_btn"
                          href="/organizer/dashboard/create-an-event-1"
                        >
                          Create a New Event
                        </a>
                        <a href="/events" className="tab_btnn">
                          Done
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </RootLayout>
    </>
  );
};

export default ShareEvent
