import { useMutation } from "react-query";
import {
  axiosService,
  DISABLE_CASE_CONVERSION,
} from "../../../api/axiosConfig";

const createNewVenue = async (venueData) => {
  const response = await axiosService.post("venues/", venueData, {
    headers: {
      [DISABLE_CASE_CONVERSION]: true,
    },
  });

  return response.data;
};

export const useCreateNewVenue = (enabled = true, options = {}) => {
  return useMutation({
    mutationKey: "createNewVenue",
    mutationFn: (venueData) => createNewVenue(venueData),
    onError: (error) => {
      console.log(error);
    },
    enabled: enabled,
    ...options,
  });
};
