import styles from "./eventCard.module.css";
import { useGetEventFromId } from "../../api/hooks/events/useGetEventFromId";
import { SkeletonLoader } from "../SkeletonLoader/SkeletonLoader";
import { FaCalendar, FaClock } from "react-icons/fa6";
import {
  formatDateOnlyString,
  formatTimeOnlyString,
} from "@/utils/dateTimeHelpers";
import { useGetVenueFromId } from "@/api/hooks/useGetVenueFromId";
import { FaMapMarkerAlt } from "react-icons/fa";
import { generatePath, Link } from "react-router-dom";
import { EventRoutes } from "@/routes/EventRoutes";
import { Button } from "@/components/Button/Button";
import { EventActionsButton } from "./components/EventActionsButton/EventActionsButton";
import { DashboardRoutes } from "@/routes/DashboardRoutes";

const TEST_IMAGE =
  "https://images.pexels.com/photos/1758144/pexels-photo-1758144.jpeg?auto=compress&cs=tinysrgb&w=800";

// MODE = CUSTOMER_VIEW is the default. This is how the card looks when a customer is browsing events to purchase them
const MODES = ["CUSTOMER_VIEW", "ORGANIZER"];

export const EventCard = ({ eventId = 312, mode = "CUSTOMER_VIEW" }) => {
  const {
    data: eventData,
    isLoading: isGetEventDataLoading,
    isSuccess: isGetEventDataSuccess,
  } = useGetEventFromId(eventId, eventId !== undefined);

  const { data: venueData, isLoading: isGetVenueDataLoading } =
    useGetVenueFromId(eventData?.venueName, isGetEventDataSuccess);

  if (isGetEventDataLoading || isGetVenueDataLoading) {
    return <SkeletonLoader width="18rem" height="28rem" />;
  }

  const viewEventLink = generatePath(EventRoutes.VIEW_EVENT, {
    token: eventData?.uniqueToken,
  });

  const sellTicketsLink = generatePath(DashboardRoutes.SELL_TICKETS, {
    token: eventData?.uniqueToken,
  });

  return (
    <div className={styles.eventCard}>
      {mode === "ORGANIZER" && <EventActionsButton eventId={eventId} />}
      <img className={styles.eventImage} src={eventData?.eventImage} />
      <div className={styles.eventCardContentContainer}>
        <h3 className={styles.eventName}>{eventData?.eventName}</h3>
        <div className={styles.eventDetailItem}>
          <FaCalendar className={styles.eventDetailItemIcon} />
          <span>{formatDateOnlyString(eventData?.startDate)}</span>
        </div>
        <div className={styles.eventDetailItem}>
          <FaClock className={styles.eventDetailItemIcon} />
          <span>{formatTimeOnlyString(eventData?.startTime)}</span>
        </div>
        <div className={styles.eventDetailItem}>
          <FaMapMarkerAlt className={styles.eventDetailItemIcon} />
          <span>{venueData?.venueName}</span>
        </div>
        {mode === "CUSTOMER_VIEW" && (
          <Button
            className={styles.eventCardButton}
            size="md"
            as={Link}
            href={viewEventLink}
          >
            Get tickets
          </Button>
        )}

        {mode === "ORGANIZER" && (
          <Button
            className={styles.eventCardButton}
            size="md"
            as={Link}
            href={sellTicketsLink}
          >
            Sell tickets
          </Button>
        )}
      </div>
    </div>
  );
};
