export function convertTo12HourFormat(time24) {
    if (!time24) {
      return "";
    }
    // Split the input time string into hours, minutes, and seconds
    let [hours, minutes, seconds] = time24.split(':').map(Number);

    // Determine the period (AM/PM) and adjust hours for 12-hour format
    let period = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12; // Convert 0 to 12 for 12-hour format

    // Format the hours and minutes to ensure two-digit representation
    let formattedHours = String(hours).padStart(2, '0');
    let formattedMinutes = String(minutes).padStart(2, '0');

    // Return the formatted time string
    return `${formattedHours}:${formattedMinutes} ${period}`;
}

export const formatDateOnlyString = (dateString = "") => {
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const date = new Date(dateString);
  return date.toLocaleString("en-US", options);
};

export const formatTimeOnlyString = (timeString = "") => {
  const options = {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };
  const [hours, minutes, seconds] = timeString.split(":").map(Number);
  const date = new Date();
  date.setHours(hours, minutes, seconds);
  return date.toLocaleString("en-US", options);
};

export const formatDateString = (dateString = "") => {
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true,
  };
  const date = new Date(dateString);
  return date.toLocaleString("en-US", options);
};

export function formatDate(dateString) {
    if (!dateString) {
      return ""; // or handle the undefined case appropriately
    }
    const [year, month, day] = dateString.split('-').map(Number);
    const date = new Date(year, month - 1, day); // month is zero-based
  
    if (isNaN(date.getTime())) {
      throw new Error("Invalid date format");
    }
  
    // Define options for the desired format
    const options = {
      weekday: 'short', 
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    };
  
    // Format the date
    return new Intl.DateTimeFormat('en-US', options).format(date);
  }