// import Home from "./Home.js";
import Home from "./(root)/Home.js";
import "./App.css";

const App = () => {
  return (
    <>
      <Home />
    </>
  );
};

export default App;
