import { useQuery } from "react-query";
import { axiosService } from "@/api/axiosConfig";

const createPaymentIntent = async (data) => {
  const response = await axiosService.post(
    "stripe/create-payment-intent/",
    data
  );
  return response.data;
};

export const useCreatePaymentIntent = (data, enabled = true, options = {}) => {
  return useQuery({
    queryKey: ["createPaymentIntent", JSON.stringify(data?.itemsToPurchase)],
    queryFn: () => createPaymentIntent(data),
    onError: (error) => {
      console.log(error);
    },
    staleTime: Infinity,
    refetchInterval: false,
    cacheTime: Infinity,
    enabled: true,
    ...options,
  });
};
