const SITE_PREFIX = "EVENT_VISIONARY";

export const getAuthenticationTokens = () => {
  const access = localStorage.getItem("authToken");
  const userId = localStorage.getItem("authUserId");

  return {
    userId: Number(userId || NaN),
    access: access,
  };
};

export const setAuthenticationTokens = (tokens) => {
  if (tokens.user) {
    localStorage.setItem(`${SITE_PREFIX}_USER`, JSON.stringify(tokens.user));
  }
  if (tokens.access) {
    localStorage.setItem(`${SITE_PREFIX}_ACCESS_TOKEN`, tokens.access);
  }
  if (tokens.refresh) {
    localStorage.setItem(`${SITE_PREFIX}_REFRESH_TOKEN`, tokens.refresh);
  }
};

export const clearAuthenticationTokens = () => {
  localStorage.removeItem("authUserId");
  localStorage.removeItem("authToken");
  localStorage.removeItem(`${SITE_PREFIX}_USER`);
  localStorage.removeItem(`${SITE_PREFIX}_ACCESS_TOKEN`);
  localStorage.removeItem(`${SITE_PREFIX}_REFRESH_TOKEN`);
};
