import { useQuery } from "react-query";
import { axiosService } from "../axiosConfig";

const getVenueFromId = async (venueId) => {
  const response = await axiosService.get(`venues/${venueId}/`);
  return response.data;
};

export const useGetVenueFromId = (venueId, enabled = true) => {
  return useQuery({
    queryKey: ["getVenueFromId", venueId],
    queryFn: () => getVenueFromId(venueId),
    onError: (error) => {
      console.log(error);
    },
    enabled: enabled,
  });
};
