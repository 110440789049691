import { useParams } from "react-router-dom";
import { useGetEventFromToken } from "@/api/hooks/events/useGetEventFromToken";
import styles from "../ticketPurchaseWizard.module.css";
import {
  convertTo12HourFormat,
  formatDate,
} from "../../../utils/dateTimeHelpers";

export const EventInfoHeader = () => {
  const { token: eventToken } = useParams();

  const {
    data: eventData,
    isLoading: isGetEventDataLoading,
    isError: isGetEventDataError,
    isSuccess: isGetEventDataSuccess,
  } = useGetEventFromToken(eventToken, Boolean(eventToken));

  return (
    <div className={styles.eventInfoContainer}>
      <h1 className={styles.eventTitle}>{eventData?.event?.eventName}</h1>
      <div className={styles.eventDateAndTime}>
        <span>{formatDate(eventData?.event?.startDate)} </span>
        <span>at</span>
        <span>{convertTo12HourFormat(eventData?.event?.startTime)}</span>
      </div>
    </div>
  );
};
