import {
  FaAngleDown,
  FaBars,
  FaCalendarPlus,
  FaGear,
  FaHouseChimney,
  FaPowerOff,
  FaUser,
} from "react-icons/fa6";
import styles from "../header.module.css";
import { Link, useNavigate } from "react-router-dom";
import { FaCalendarAlt } from "react-icons/fa";
import { DashboardRoutes } from "@/routes/DashboardRoutes";
import { SiteRoutes } from "@/routes/SiteRoutes";
import clsx from "clsx";
import {
  clearAuthenticationTokens,
  getAuthenticationTokens,
} from "@/utils/tokenManager";
import { useGetUserFromId } from "@/api/hooks/useGetUserFromId";
import { useHover } from "@uidotdev/usehooks";
import { useIsLoggedIn } from "@/Hooks/useIsLoggedIn";
import { AuthenticationRoutes } from "@/routes/AuthenticationRoutes";
import { useQueryClient } from "react-query";

export const DesktopDropdown = ({ onItemClick }) => {
  const [buttonRef, isHoveringOnButton] = useHover();
  const [dropdownRef, isHoveringOnDropdown] = useHover();
  const isLoggedIn = useIsLoggedIn();
  const { userId } = getAuthenticationTokens();
  const { data: userData, isSuccess: isGetUserDataSuccess } = useGetUserFromId(
    userId,
    !Number.isNaN(userId)
  );
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const handleLogin = () => {
    navigate(AuthenticationRoutes.LOGIN);
  };

  const handleLogout = () => {
    queryClient.invalidateQueries(["getUserFromId", userId]);
    clearAuthenticationTokens();
    console.log("New user Id is: ", userId);
    // navigate(SiteRoutes.ROOT);
  };

  const DROPDOWN_LINKS = [
    {
      icon: <FaHouseChimney />,
      text: "Dashboard",
      href: DashboardRoutes.DASHBOARD,
      condition: isLoggedIn,
    },
    {
      icon: <FaCalendarPlus />,
      text: "Create an event",
      href: DashboardRoutes.CREATE_EVENT,
      condition: isLoggedIn,
    },
    {
      icon: <FaCalendarAlt />,
      text: "My events",
      href: DashboardRoutes.ORGANIZER_EVENTS,
      condition: isLoggedIn,
    },
    {
      icon: <FaUser />,
      text: "My account",
      href: DashboardRoutes.ACCOUNT,
      condition: isLoggedIn,
    },
    {
      icon: <FaGear />,
      text: "Settings",
      href: DashboardRoutes.SETTINGS,
      condition: isLoggedIn,
    },
    {
      icon: <FaPowerOff />,
      text: "Log out",
      condition: isLoggedIn,
      // href: SiteRoutes.ROOT,
      onClick: () => handleLogout(),
    },
  ];

  return (
    <div className={clsx(styles.dropdownContainer)}>
      {isLoggedIn ? (
        <button
          className={styles.dropdownText}
          ref={buttonRef}
          onClick={isLoggedIn ? handleLogin : () => {}}
        >
          <span>{userData?.firstName}</span>
          <FaAngleDown />
        </button>
      ) : (
        <Link className={styles.loginLink} to={AuthenticationRoutes.LOGIN}>
          Login
        </Link>
      )}

      {(isHoveringOnButton || isHoveringOnDropdown) && (
        <div className={styles.dropdownItemContainer} ref={dropdownRef}>
          {DROPDOWN_LINKS.filter(
            (link) => !link.hasOwnProperty("condition") || link?.condition
          ).map((link) => {
            const Component = link?.onClick ? "button" : Link;

            return (
              <Component
                className={styles.dropdownLink}
                key={link.text}
                to={link.href}
                onClick={() => {
                  link?.onClick();
                }}
              >
                {link?.icon}
                <span>{link.text}</span>
              </Component>
            );
          })}
        </div>
      )}
    </div>
  );
};
