import { GetPlaceCommand } from "@aws-sdk/client-location";
import { useQuery } from "react-query";
import { camelizeKeys } from "humps";
import { useAWSLocationClient } from "@/Hooks/useAWSLocationClient";
import {
  AWS_LOCATION_API_KEY,
  AWS_PLACE_INDEX_NAME,
} from "@/constants/environment";

const getAddressDetails = async (placeId, client) => {
  const input = {
    IndexName: AWS_PLACE_INDEX_NAME,
    PlaceId: placeId,
    Language: "en",
    Key: AWS_LOCATION_API_KEY,
  };
  const command = new GetPlaceCommand(input);
  const response = camelizeKeys(await client.send(command));
  return response;
};

export const useGetAddressDetails = (
  placeId,
  enabled = false,
  options = {}
) => {
  const client = useAWSLocationClient();
  return useQuery({
    queryKey: ["getAddressDetails", placeId],
    queryFn: () => getAddressDetails(placeId, client),
    onError: (error) => {
      console.log(error);
    },
    enabled: enabled,
    ...options,
  });
};
