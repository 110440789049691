'use client'
import MainMenusEx from '../../components/MainMenusEx/MainMenusEx'
import SubMenus, { Silder_icon } from '../../components/SubMenus/SubMenus'
import { Events_imgs } from '../../public'
import React, { useState , useEffect} from 'react'
import { CiCirclePlus } from 'react-icons/ci'
import { FaChevronDown, FaRegCalendarAlt, FaRegUser } from 'react-icons/fa'
import { IoSpeedometerOutline } from 'react-icons/io5'
import { RootLayout } from "@/layouts/RootLayout/RootLayout";
import { useNavigate, useParams } from 'react-router-dom';
import { FinishOnboardingButton } from "@/components/FinishOnboardingButton/FinishOnboardingButton";

const EventDash = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [event, setEvent] = useState(true);
  // const [selectedEvent, setSelectedEvent] = useState(eventId);
  const [isMainOpen, setIsMainOpen] = useState(true);
  const [isEventOpen, setIsEventOpen] = useState(true);
  const [eventImageUrl, setEventImageUrl] = useState("");
  let base_api_url = `${process.env.REACT_APP_BASE_URL}`;

  const [pauseStatus, setPauseStatus] = useState(false);
  const [cancelStatus, setCancelStatus] = useState(false);
  const [endStatus, setEndStatus] = useState(false);

  const toggleMain = () => {
    setIsMainOpen(!isMainOpen);
  };

  const toggleEvent = () => {
    setIsEventOpen(!isEventOpen);
  };
  const [isPaused, setIsPaused] = useState(true);

  const toggleTicketSales = () => {
    setIsPaused((prevState) => !prevState);
  };
  // Handle status updates in a reusable function
  const handleStatusUpdate = async (statusValue, setStatusFunction) => {
    try {
      const authToken = localStorage.getItem("authToken");
      if (!authToken) {
        console.error("Authentication token not found");
      }

      const body = JSON.stringify({
        [statusValue]: !event[statusValue], // Toggle the status value
      });

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/events/${event.id}/`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${authToken}`,
          },
          body: body,
        }
      );

      if (!response.ok) {
        console.error("Failed to update event information");
      }

      setStatusFunction((prevStatus) => !prevStatus); // Toggle the local status value
    } catch (error) {
      console.error("Error updating event information:", error);
    }
  };

  useEffect(() => {
    const fetchEventData = async () => {
      const authToken = localStorage.getItem("authToken");
      if (!authToken) {
        console.error("Authentication token not found");
      }
      const authUserId = localStorage.getItem("authUserId");

      if (!authUserId) {
        console.error("Authentication authUserId not found");
      }
      const requestBody = JSON.stringify({
        unique_token: token,
      });

      const eventResponse = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/eventofuniquetoken/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: requestBody,
        }
      );
      // Check if the request was successful (status code 2xx)
      if (eventResponse.ok) {
        const eventResponseData = await eventResponse.json(); // Parse the response JSON
        setEvent(eventResponseData.event);
        console.log("event: ", eventResponseData.event);
        console.log(
          "match result is ",
          !(eventResponseData.event.user === authUserId)
        );
        console.log("match authUserId is ", authUserId);
        if (!(eventResponseData.event.user == authUserId)) {
          navigate(`/organizer/dashboard/create-an-event-1/`);
        }

        const baseURL = `${base_api_url}`;
        let imageURL = eventResponseData.event.Event_image;
        console.log("1 : imageURL: ", imageURL);
        if (imageURL.startsWith("/images")) {
          console.log("2 ");

          imageURL = `${baseURL}${imageURL}`;
          console.log("3 updated imageURL : ", imageURL);
          setEventImageUrl(imageURL);
        } else {
          console.log("in else");
          setEventImageUrl(eventResponseData.event.Event_image);
        }

        // Optionally, you can return the response data or perform other actions here
      } else {
        console.log("event retrieving error ", eventResponse.status);
      }
    };

    fetchEventData();
  }, [token]);

  return (
    <>
      <RootLayout>
        <div className="main_container">
          <div className="dashboard_main">
            <div className="dashboard_section">
              <div className="sidebar ">
                <ul className="nav-links">
                  <Silder_icon />
                  <FinishOnboardingButton />
                  <li>
                    <a href="/organizer/dashboard">
                      <IoSpeedometerOutline className=" menu_dash_i" />
                      <span className="link_name ">Dashboard</span>
                    </a>
                  </li>
                  <li>
                    <div className="iocn-link">
                      <a href="/organizer/dashboard/create-an-event-1">
                        <CiCirclePlus className=" menu_dash_i" />

                        <span className="link_name ">CREATE AN EVENT</span>
                      </a>
                    </div>
                  </li>
                  <li>
                    <SubMenus />
                  </li>
                  <MainMenusEx />
                </ul>
              </div>
              <section className="home-section">
                <div className="home-content">
                  <div className="event_dash">
                    <h1>{event.Event_Name}</h1>
                    <div className="event_dash_content">
                      <div className="event_dash_link">
                        <div className="event_dash_bt">
                          <h2>What do you want to do?</h2>
                          <div className="event_warp_btns">
                            <div className="event_dash_btns">
                              <a
                                href={`/organizer/dashboard/events/${event.unique_token}/sell-tickets`}
                              >
                                Sell Tickets
                              </a>
                              {event.is_seating_reserved === true && (
                                <a href={`/managetwo/${event.event_id}`}>
                                  Hold Seats
                                </a>
                              )}
                              <a href={`/scan-ticket/${event.id}`}>
                                Scan Tickets
                              </a>
                              <a href={`/ManageOrder/${event.id}`}>
                                Manage Orders
                              </a>
                            </div>
                            <div className="event_dash_btns">
                              <a
                                href={`/organizer/dashboard/events/manage-attendees/${event.id}`}
                              >
                                Manage Attendees
                              </a>
                              <a href={`/eventdetails/${event.id}`}>
                                Edit Event Details
                              </a>
                              <a href={`/ticketprices/${event.id}`}>
                                Manage Ticket Prices
                              </a>
                              {event.is_seating_reserved === true && (
                                <a href={`/settingChart/${event.id}`}>
                                  Edit Seating
                                </a>
                              )}
                            </div>
                          </div>
                        </div>
                        {/* <div className='event_imgs'> */}
                        <img
                          src={eventImageUrl}
                          alt=""
                          className="event_imgs"
                        />
                        {/* </div> */}
                      </div>
                      <div className="event_dash_action">
                        <h2>Quick Actions</h2>
                        <div className="event_dash_info">
                          <div className="event_dash_ctr">
                            <button
                              className="remove_bg_remove_border white_txt cursor_pointer"
                              onClick={() =>
                                handleStatusUpdate("is_paused", setPauseStatus)
                              }
                            >
                              {pauseStatus
                                ? "Resume Ticket Sales"
                                : "Pause Ticket Sales"}
                            </button>

                            <button
                              className="remove_bg_remove_border white_txt cursor_pointer"
                              onClick={() =>
                                handleStatusUpdate("is_ended", setEndStatus)
                              }
                            >
                              {endStatus
                                ? "Start Ticket Sales"
                                : "End Ticket Sales"}
                            </button>

                            <button
                              className="remove_bg_remove_border white_txt cursor_pointer"
                              onClick={() =>
                                handleStatusUpdate(
                                  "is_cancelled",
                                  setCancelStatus
                                )
                              }
                            >
                              {cancelStatus ? "Allow Event" : "Cancel Event"}
                            </button>
                          </div>
                          <div className="event_dash_text">
                            <p>
                              Pausing ticket sales will temporarily prevent
                              customers and you from being able to sell tickets
                              online through Event Visionary. You may resume
                              ticket sales by clicking “Resume Sales” at any
                              time before the scheduled end of sales date
                              defined in the event details section.
                            </p>
                            <p>
                              Ending Ticket sales will prevent you and your
                              customers from being able to purchase tickets for
                              this event. This cannot be undone.
                            </p>
                            <p>
                              Note: It is your responsibility to let your
                              clients and customers know that this event has
                              been canceled.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </RootLayout>
    </>
  );
};

export default EventDash
